import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import App from './App';
import './index.css'; // Import your CSS styles

// Find the root container element
const container = document.getElementById('root');
const root = createRoot(container);

// Wrap App with HelmetProvider to manage metadata
root.render(
  <HelmetProvider>
    <App tab="home" />
  </HelmetProvider>
);
