import React, { useState, useContext } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import Web3 from 'web3';
import { useNavigate } from 'react-router';
import { AuthContext } from './authProvider';
import { AdminContext } from './adminProvider';
import { SubscribedContext } from './subscribedProvider';
import { login_url } from './urlConfig';
import { polygonMainUrl, chainId } from './abiConfig';

const WalletModal = ({ show, onClose }) => {
  const [selectedWallet, setSelectedWallet] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { adminLogin } = useContext(AdminContext);
  const { subscribed, unsubscribe } = useContext(SubscribedContext);

  const walletInfo = {
    chainId: '0x89',
    chainName: 'Polygon Main Network',
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
  };

  const handleConnect = async () => {
    try {
      if (!selectedWallet) {
        // No wallet selected, handle accordingly
        return;
      }

      if (!window.ethereum || !window.ethereum.request) {
        // Handle case where Metamask is not available
        return;
      }

      let walletAddress;
      let web3;

      let provider;

      if (selectedWallet === 'coinbase') {
        try{
          provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
        } catch(e){
          provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
        }
      }

      if (selectedWallet === 'metamask') {
        try{
          provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
        } catch(e){
          provider = window.ethereum.isMetaMask ? window.ethereum : null;
        }
      }

      if (selectedWallet === 'walletConnect') {

        try{
          provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
        } catch(e){
          provider = window.ethereum.isWalletConnect ? window.ethereum : null;
        }
      }

      let promptUserToInstall = false;

      const chainId = await provider.request({ method: 'eth_chainId' });

      if (parseInt(chainId, 16) !== parseInt(walletInfo.chainId, 16)) {
        try {
          // Attempt to switch to the desired chain
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: walletInfo.chainId }],
          });
        } catch (error) {
          // If switching fails, set the flag to prompt the user
          promptUserToInstall = true;
        }
      }

        // Prompt the user outside the catch block based on the flag
      if (promptUserToInstall) {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [walletInfo],
        });
      }

      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      walletAddress = accounts[0];

      //Web3 Connection
      web3 = new Web3(polygonMainUrl);

      onClose();

      // Send the wallet address as a POST request to the API
      const response = await fetch(login_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress}),
      });

      if (response.ok) {
        // Handle successful response from the API
        // Implement your own logic here
        const data = await response.json();

        localStorage.setItem('token', data.token);
        localStorage.setItem('walletAddress', walletAddress);
        localStorage.setItem('provider', selectedWallet);

        login(); // Call the login function from the AuthContext

        if (data.subscription === 'True'){
          subscribed();
        }
        if (data.subscription !== 'True'){
          unsubscribe();
        }
        if (data.admin === 'True'){
          adminLogin();
        }
        navigate('/user');

      } else if (response.status === 404) {
        // Redirect to User Registration URL
        navigate('/')
      }
    } catch (error) {
      //Redirect to main due to error
      navigate('/');
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title>Connect Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              <Button onClick={() => setSelectedWallet('metamask')}
              className={selectedWallet === 'metamask' ? 'selected-button' : 'unselected-button'}
              >
              Metamask</Button>
            </p>
          </Col>
          <Col>
            <p>
              <Button onClick={() => setSelectedWallet('coinbase')}
              className={selectedWallet === 'coinbase' ? 'selected-button' : 'unselected-button'}
              >
              Coinbase Wallet</Button>
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConnect}>
          Connect
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WalletModal;
