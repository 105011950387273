import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, { useState} from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link , useNavigate} from 'react-router-dom'; // Import Link from react-router-dom for navigation
import { FaArrowLeft, FaHome } from 'react-icons/fa'; // Import the desired icons from react-icons
import Web3 from 'web3';

const DeleteExtension = () => {
  const navigate = useNavigate();
  const [ ins, setIns ] = useState('keep');
  const [step, setStep] = useState(1);
  const [returnText, setReturnText] = useState('')
  const [deleteType, setDeleteType] = useState('both')

  const handleConfirmation = async () =>{

    if (ins === 'keep'){
      setReturnText("Your request has been processed. Thank you.")
    }
    else if (ins === "delete"){
      setStep(2)
    }
  };

  const handleDeleteConfirmation = async () => {

    setReturnText("Acquiring wallet address...")

    const provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
    const accounts = await provider.request({ method: 'eth_requestAccounts' });
    const walletAddress = accounts[0];

    let ip;
    let payload;
    let jsonPayload;

    try {

      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      ip = data.ip;
    } catch (error) {
      setReturnText("There was an error with your request. Please contact us for assistance.")
    }

    payload = {
      walletAddress: walletAddress,
      ipAddress: ip,
      deleteType: deleteType
    }

    jsonPayload = JSON.stringify(payload)

    const url = "https://test-server.evow.io/deleteData";

    try{
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonPayload,
      });

      if (response.ok){
        const data = await response.json();
        setReturnText("Request Completed. Your data has been deleted from eVow servers. Thank you!")
      }

    }
    catch(error){
      setReturnText("There was an error with your request. Please contact us for assistance.")
    }

  };

  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };

  return (
    <div className="pl-5 pr-5">
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <Link to="/">
            <button className="profile-button">
              <FaHome style={{ fontSize: '24px'}}/> Home
            </button>
          </Link>
        </div>
      </Col>
    </Container>
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <div className="about-container">
            <section className="intro-section">
              <h2>eVow Extension Deletion</h2>
              <p className="lead">
                Thank you for your participation in the extension.
              </p>
              <p>
                While we are saddened to see you go, we are very thankful for your partitipation. We hope to see you back soon!
              </p>

              <p>
                Please fill out the form to complete the deletion process.
              </p>

              {step === 1 && (

                <>

                <Form>
                  <p>
                  Would you like to preserve or remove your data with eVow.io?
                  </p>
                  <Form.Check
                    type="radio"
                    label="Preserve"
                    name="keep"
                    id="keep"
                    value="keep"
                    checked={ins === "keep"}
                    onChange={(e) => setIns(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Remove"
                    name="delete"
                    id="delete"
                    value="delete"
                    checked={ins === "delete"}
                    onChange={(e) => setIns(e.target.value)}
                  />
                </Form>
                <br/>
                <Button variant="primary" onClick={handleConfirmation}>Confirm</Button>
                </>
                )
              }

              {step === 2 && (

                <>
                <Form>
                  <p>
                  Would you like to delete all data associated with your current address or your address & IP combination?
                  </p>
                  <Form.Check
                    type="radio"
                    label="Address"
                    name="address"
                    id="address"
                    value="address"
                    checked={deleteType === "address"}
                    onChange={(e) => setDeleteType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Address & IP"
                    name="both"
                    id="both"
                    value="both"
                    checked={deleteType === "both"}
                    onChange={(e) => setDeleteType(e.target.value)}
                  />
                </Form>
                <br/>
                <Button variant="primary" onClick={handleDeleteConfirmation}>Confirm</Button>
                </>
                )
              }

              <br/>
              <p>
                {returnText}
              </p>

            </section>
         </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default DeleteExtension;
