import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider, AuthContext } from './authProvider';
import { AdminProvider, AdminContext} from './adminProvider';
import { SubscribedProvider, SubscribedContext } from './subscribedProvider';
import { AgeProvider, AgeContext} from './ageProvider';
import { Helmet } from 'react-helmet-async';
import NavbarScroll from './navigation';
import Header from './header';
import Footer from './footer';
import Home from './home';
import Login from './login';
import Friends from './friends';
import User from './user';
import About from './about';
import Contact from './contact';
import Subscribed from './subscribed';
import Tokenomics from './tokenomics';
import Terms from './terms';
import Privacy from './privacy';
import UsersOnly from './usersOnlyError';
import SearchUser from './search';
import DeleteExtension from './deleteExtension';
import Extension from './extension';
import AdminPanel from './adminPanel';
import Whitepaper from './whitepaper';
import KeyPair from './keyPair'
import './App.css';
import HowTo from './howTo';
import NewsList from './news';
import Article from './article';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useContext(AdminContext);

  return isAdmin ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/user" replace />
  );
};

const SubscribedRoute = ({ component: Component, ...rest }) => {
  const { isSubscribed } = useContext(SubscribedContext);

  return isSubscribed ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/user" replace />
  );
};

const App = () => {
  return (
    <AdminProvider>
    <AuthProvider>
      <SubscribedProvider>
        <AgeProvider>
          <Router>
            <HelmetProvider>
            <NavbarScroll />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/news" element={<NewsList />} />
              <Route path="/article/:id" element={<Article />} />
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/whitepaper" element={<Whitepaper />} />
              <Route path="/how" element={<HowTo />} />
              <Route path="/login" element={<Login />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/userOnly" element={<UsersOnly />} />
              <Route path='/search' element={<SearchUser />} />
              <Route path='/deleteExtension' element={<DeleteExtension />} />
              <Route path='/extension' element={<Extension />} />
              <Route path="/user" element={<PrivateRoute component={User} />} />
              <Route path="/friends" element={<PrivateRoute component={Friends} />} />
              <Route path="/keys" element={<PrivateRoute component={KeyPair} />} />
              <Route path="/subscribed" element={<PrivateRoute component={Subscribed}  />} />
              <Route path="/adminPanel" element={<AdminRoute component={AdminPanel} />} />
            </Routes>
            <Footer />
            </HelmetProvider>
          </Router>
        </AgeProvider>
      </SubscribedProvider>
    </AuthProvider>
    </AdminProvider>
  );
};

export default App;
