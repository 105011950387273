import React, { createContext, useState } from 'react';

export const SubscribedContext = createContext();

export const SubscribedProvider = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribed = () => {
    // Perform the login process and set isLoggedIn to true if successful
    setIsSubscribed(true);
  };

  const unsubscribe = () => {
    // Perform the logout process and set isLoggedIn to false
    setIsSubscribed(false);
  };

  return (
    <SubscribedContext.Provider value={{ isSubscribed, subscribed, unsubscribe }}>
      {children}
    </SubscribedContext.Provider>
  );
};
