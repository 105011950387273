import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link , useNavigate} from 'react-router-dom'; // Import Link from react-router-dom for navigation
import { FaArrowLeft, FaHome } from 'react-icons/fa'; // Import the desired icons from react-icons
import { ImFolderDownload } from "react-icons/im";

const DownloadZipButton = ({ fileName, fileUrl }) => {
  return (
    <a
      href={fileUrl}
      download={fileName}
      className="flex items-center bg-blue-500 px-4 py-2 rounded-lg"
    >
      <ImFolderDownload style={{ fontSize: '72px' }} />
    </a>
  );
};

const Extension = () => {
  const navigate = useNavigate();
  const fileName = 'Data-Tracking-Sentinel.zip';
  const fileUrl = 'http://localhost:3000/assets/Data-Tracking-Sentinel.zip'; // Replace with your actual file URL

  const handleGoBack = () => {
    navigate(-1);
  };
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  return (
    <div className="pl-5 pr-5">
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
          <Link to="/">
            <button className="profile-button">
              <FaHome style={{ fontSize: '24px'}}/> Home
            </button>
          </Link>
        </div>
      </Col>
    </Container>
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <div>
            <section>
              <h2>Discover the eVow Data Sentinel Extension</h2>
              <p className="lead">
                Monitor your url history!
              </p>
              <p className="lead">
                Welcome to the world of eVow, where innovation, security, and financial freedom converge. With the eVow Data Sentinel Extension, you have the power to monitor and control your URL and cookie history securely and efficiently.
              </p>
              <p className="lead">
                Our extension provides a comprehensive solution for tracking your online activity while ensuring your data remains private and secure. By leveraging cutting-edge blockchain technology and smart contracts, we offer a unique and transparent way to manage your digital footprint.
              </p>
            </section>

            <section>
              <h2>Beta Phase</h2>
              <p className="lead">
                We've only just released the extension for download. We are in a beta test phase. During this phase, we are looking to grow the userbase of the extension. Lower end bulk sales of profiles seek a minimum amount of profiles for sale.
              </p>
              <p className="lead">
                While the eVow.io Data Tracking Sentinel offers groundbreaking opportunities for users to monetize their browsing data, it’s important to address compliance with Google platform policies. Specifically, Google’s policies for the Chrome Web Store strictly disapprove of extensions that sell data to third parties. This presents a significant challenge for the eVow.io Data Tracking Sentinel, as its core functionality involves the collection and sale of browsing data.
              </p>
              <p className="lead">
                Google's policies are designed to protect user privacy and maintain trust in the Chrome Web Store ecosystem. Extensions listed on the Web Store are prohibited from selling data to third parties, as this practice can lead to privacy violations and misuse of personal information. Google's stance ensures that users can download extensions with the confidence that their data will not be sold without their explicit consent and understanding.
              </p>
              <p className="lead">
                Due to Google's disapproval of data sales, the eVow.io Data Tracking Sentinel cannot be officially listed on the Chrome Web Store. Instead, it must remain an unofficial extension. This means users will need to manually install the extension from the eVow.io website rather than through the Chrome Web Store.
              </p>
            </section>

            <section>
              <h2>After Beta</h2>
              <p className="lead">
                We will remain focused on a few areas. One area will be building the algorithms associated with user payment. And once the extension userbase has grown, we will be focused on reaching out to data consumers and marketing firms to begin selling your data.
              </p>
            </section>
         </div>
        </Col>
      </Row>
    </Container>

    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <div>
            <section>
            <h2>Download Beta Version 0.0.1</h2>
              <DownloadZipButton fileName={fileName} fileUrl={fileUrl} />
            </section>
         </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Extension;
