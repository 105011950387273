import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { SubscribedContext } from './subscribedProvider';
import {
  empUsc3ABI,
  empUsc3Bytecode,
  empEvow3ABI,
  empEvow3Bytecode,
  empSwth3ABI,
  empSwth3Bytecode,
  empWbtc3ABI,
  empWbtc3Bytecode} from './abiConfig'

import { carbon_main_url, carbon_test_url, ipfsFileURL, contractsPut } from './urlConfig'
import Web3 from 'web3';

const SafeRemoteSaleModal = ({ show, onClose }) => {
  const { isSubscribed } = useContext(SubscribedContext);
  const [ token, setToken] = useState('EVOW');
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [informedText, setInformedText] = useState('Proceed');
  const [ipfsHash, setIPFSHash] = useState('');
  const web3 = new Web3(carbon_test_url);
  const walletAddress = localStorage.getItem('walletAddress');
  const localToken = localStorage.getItem('token');
  const navigate = useNavigate();
  const depositAmt = totalAmount * 2
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const formData = new FormData();

  const deployContract = async () => {

    let employmentContract;
    let deploymentData;
    let contractType;

    console.log('FormData keys 2:', [...formData.keys()]);

    setInformedText('IPFS Server Post...');

    if (token === 'EVOW'){
      console.log("SHIT");
    }
    else if (token === 'WBTC'){
      console.log("SHIT");
    }
    else if (token === 'USD'){
      console.log("SHIT");
    }
    else if (token === 'SWTH'){
      console.log("SHIT");
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleFileNextStep = async () => {
    formData.append('file', file);
    //Server call to store contract, notify participants and add to interactions
    const response = await fetch(ipfsFileURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
      body: formData,
    });

    if (response.ok){
      const jsonResponse = await response.json();
      setIPFSHash(jsonResponse.ipfs_hash);
      console.log("IPFS HASH: ", ipfsHash);
      console.log("IPFS HASH: ", jsonResponse.ipfs_hash)
    }
    setStep(step + 1);
  };

  const handleProceed = async() => {
    // Perform actions on "Proceed" button click
    await deployContract();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

return (
  <Modal show={show} onHide={onClose} centered>
    <Modal.Header closeButton style={{color: 'black'}}>
      <Modal.Title>Secure Remote Sale</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className = 'custom-modal-body'>
      {step === 1  && isSubscribed === true &&(
        <>
          <p>As a subscribed member, you have the option of choosing between four different tokens, including the native Carbon Protocol token (SWTH) and our token EVOW! Please choose an option: </p>

          <Form>
            <Form.Check
              type="radio"
              label="EVOW"
              name="tokenOption"
              id="EVOW"
              value="EVOW"
              checked={token === "EVOW"}
              onChange={(e) => setToken(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="SWTH"
              name="tokenOption"
              id="SWTH"
              value="SWTH"
              checked={token === "SWTH"}
              onChange={(e) => setToken(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="WBTC"
              name="tokenOption"
              id="WBTC"
              value="WBTC"
              checked={token === "WBTC"}
              onChange={(e) => setToken(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="USD"
              name="tokenOption"
              id="USD"
              value="USD"
              checked={token === "USD"}
              onChange={(e) => setToken(e.target.value)}
            />
          </Form>
          <Button variant="primary" onClick={handleNextStep}>Next</Button>
        </>
      )
    }

      {step === 1  && isSubscribed === false &&(
          <>
            <p>We'll be deploying our contract on the Carbon Protocol (SWTH). The primary deposit/withdraw token is EVOW. You'll need to possess both tokens to deploy and interact with the contract.</p>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )
      }

      {step === 2 && (
        <>
          <div style={{ marginBottom: '16px' }}>
            <p>Enter the sale title and description</p>
            <label htmlFor="titleInput">Title:</label>
            <input
              type="text"
              id="titleInput"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label htmlFor="messageInput">Description:</label>
            <textarea
              id="messageInput"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              style={{ width: '100%', height: '150px' }} // Adjust the height and width as needed
              required
            />
          </div>
          <Button variant="primary" onClick={handleNextStep}>
            Next
          </Button>
        </>
      )}


      {step === 3 && (
        <>
          <div>
            <p>Upload the photo of your item. (Note: File will be immediately uploaded to IPFS; which may take a few seconds to complete)</p>
            <label htmlFor="fileInput">Upload a picture:</label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleFileInputChange}
              required
            />

            {file && (
              <div>
                <p>Selected file: {fileName}</p>
              </div>
            )}
          </div>
          <br />
          <Button variant="primary" onClick={handleFileNextStep}>
            Next
          </Button>
        </>
      )}


      {step === 4 && (
        <>
          <p>Enter the total payment amount. (Note: You will be required to deposit 2x the total before the posting will be active)</p>
          <Form.Control type="number" value={totalAmount} onChange={(e) => setTotalAmount(parseFloat(e.target.value))} />
          <Button variant="primary" onClick={handleNextStep}>Next</Button>
        </>
      )}

      {step === 5 && (
        <>
          <p>Title: {title}</p>
          <p>Description: {description}</p>
          <p>Total Amount : {totalAmount}</p>
          <p>Selected file: {fileName}</p>
          <p>Are you sure you'd like to proceed? Upon clicking "Proceed", a message should appear on your screen from your Metamsk wallet. Please review and confirm for deployment. It may take 5-10 seconds to complete. You'll be interacting with the Carbon Blockchain!</p>
          <Button variant="primary" onClick={deployContract}>{informedText}</Button>
        </>
      )}
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default SafeRemoteSaleModal;
