import React, { useState, useContext } from 'react';
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import Web3 from 'web3';
import { useNavigate } from 'react-router';
import { SubscribedContext } from './subscribedProvider';
import { login_url, avalancheMainRpcUrl, polygonMainUrl } from './urlConfig';
import { AuthContext } from './authProvider';
import { chainPrompt } from './chainPrompt';
import {
        standardERC20ABI,
        siteABI,
        siteToken,
        siteTokenMATIC,
        chainId,
        masterControllerABI,
        masterControllerAddress } from './abiConfig'

const ConversionModal = ( { show, onClose } ) => {
  const [showModal, setShowModal] = useState(true);
  const [selectedWallet, setSelectedWallet] = useState('');
  const [chain, setChain] = useState('Avalanche');
  const [step, setStep] = useState(1);
  const [quantity, setQuantity] = useState(100);
  const [successText, setSuccessText] = useState('');
  const navigate = useNavigate();
  const { subscribed, unsubscribe } = useContext(SubscribedContext);
  const { login } = useContext(AuthContext);
  const walletAddress = localStorage.getItem("walletAddress");
  const providerType = localStorage.getItem('provider')
  const buttonText = step === 1 ? 'Cancel' : 'Close';

  const handleClose = () => {
    // Reset the step back to 1 when the modal is closed
    setStep(1);
    onClose(); // Call the onClose callback
  };

  const handleAmountChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleArrowKeys = (event) => {
    event.preventDefault(); // Prevent the default behavior of arrow keys

    if (event.key === 'ArrowUp') {
      setQuantity((prevQuantity) => (parseInt(prevQuantity) + 1).toString());
    } else if (event.key === 'ArrowDown') {
      setQuantity((prevQuantity) => (parseInt(prevQuantity) - 1).toString());
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  // Split the text into lines and create an array of React elements
  const textLines = successText.split('<br>').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  const handleConvert = async () => {

    let tokenContract;
    let contractInstance;
    let transactionObject;
    let web3;
    let switchChain;
    let amountWei;
    let provider;

    if (providerType === 'coinbase') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      } catch(e){
        provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
      }
    }

    if (providerType === 'metamask') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      } catch(e){
        provider = window.ethereum.isMetaMask ? window.ethereum : null;
      }
    }

    if (providerType === 'walletConnect') {

      try{
        provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
      } catch(e){
        provider = window.ethereum.isWalletConnect ? window.ethereum : null;
      }
    }

    try {

      setSuccessText(`Please don't leave the page. We are building the transaction and acquiring the gas cost. You will see a pop up from your wallet provider in a brief moment.`);
      setStep(2)

      if (chain === "Avalanche"){
        switchChain = await chainPrompt("Polygon", providerType);
        web3 = new Web3(polygonMainUrl);

        contractInstance = new web3.eth.Contract(siteABI, siteTokenMATIC);
        amountWei = web3.utils.toWei(quantity.toString());

        // Create a transaction object
        transactionObject = {
          from: walletAddress,
          to: siteTokenMATIC,
          data: contractInstance.methods.bridgeBurn(amountWei, chain).encodeABI()
        };
      }

      else if (chain === "Polygon"){
        switchChain = await chainPrompt("Avalanche", providerType);
        web3 = new Web3(avalancheMainRpcUrl);
        contractInstance = new web3.eth.Contract(siteABI, siteToken);
        amountWei = web3.utils.toWei(quantity.toString());

        // Create a transaction object
        transactionObject = {
          from: walletAddress,
          to: siteToken,
          data: contractInstance.methods.bridgeBurn(amountWei, chain).encodeABI(),
        };
      }

      const gasEstimate = await web3.eth.estimateGas(transactionObject);
      const gasPrice = await web3.eth.getGasPrice();
      const gasFee = gasEstimate * 3

      const newTransactionObj = {
        ...transactionObject,
        gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
        gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
      };

      //Send to user via eth_sendTransaction request with parameters
      const tx = await provider.request({
        method: 'eth_sendTransaction',
        params: [newTransactionObj]
      });

      if (chain === 'Avalanche'){
        setSuccessText(`TX: ${tx}<br><br>Congratulations!<br><br>You can use your tokens to subscribe or interact with contracts on our network. You will have immediate access to your tokens via your Metamask wallet. If you have not already imported the EVOW token, you can do so with the token address. <br><br>Here is the EVOW token address:<br>${siteToken} <br><br>On rare occasions, the bridge is down between the blockchains. In this case, please reach out to a staff member via Telegram for support.`);
      }
      else if (chain === 'Polygon'){
        setSuccessText(`TX: ${tx}<br><br>Congratulations!<br><br>You can use your tokens to interact with contracts on our network! You will have immediate access to your tokens via your Metamask wallet. If you have not already imported the EVOW token for Polygon, you can do so with the token address. <br><br>Here is the Polygon EVOW token address:<br>${siteTokenMATIC} <br><br>On rare occasions, the bridge is down between the blockchains. In this case, please reach out to a staff member via Telegram for support.`) ;
      }

  } catch (error) {
      // Handle error occurred during wallet connection or API request
      // Implement your own error handling logic here
      setSuccessText(`Error: ${error}`)
      setStep(2)
  }
};

  return(
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Convert EVOW Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
      {step === 1  &&(
        <Row>
          <Col>
            <p>You are preparing to convert the EVOW token from Avalanche/Polygon to the opposite chain. Upon success, a secure relay will mint your tokens on the designated chain. You will be provided with a hash for your successful burn. Please document it for any issues.</p>

            <p style={{ fontWeight: 'bold', color: 'black' }}>Convert To:</p>
            <Form>
              <Form.Check
                type="radio"
                label="Avalanche"
                name="chainOption"
                id="Avalanche"
                value="Avalanche"
                checked={chain === "Avalanche"}
                onChange={(e) => setChain(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Polygon"
                name="chainOption"
                id="Polygon"
                value="Polygon"
                checked={chain === "Polygon"}
                onChange={(e) => setChain(e.target.value)}
              />
            </Form>
            <br/>

            Quantity to Convert:
              <input
                type="number"
                name="amountText"
                value={quantity}
                onChange={handleAmountChange}
                step = '1'
                className="black-text" // Apply the CSS class
              />
            <br />
          </Col>
        </Row>
      )}

      {step === 2 &&(
        <Row>
          <Col>
            <p style={{ wordWrap: 'break-word', maxWidth: '100%'}}>
              {textLines}
            </p>
          </Col>
        </Row>
      )}

      </div>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        <div dangerouslySetInnerHTML={{ __html: buttonText }}></div>
      </Button>
        {step === 1 && (
        <Button variant="primary" onClick={handleConvert}>
          Convert
        </Button>
      )}
      </Modal.Footer>
    </Modal>
);

};

export default ConversionModal;
