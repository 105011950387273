import React, { useContext, useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Logo from './images/logo192.png';
import eVowLogo from './images/evowLogoBlack.png';
import { AuthContext } from './authProvider';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

const NavbarScroll = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const walletAddress = localStorage.getItem('walletAddress'); // Get wallet address from local storage

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1000);
  };

  const toggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/" style={{ color: 'black' }}>
          <img src={eVowLogo} width={50} height={50} alt="Logo" />
          <span style={{ paddingLeft: '10px' }}>eVow</span>
        </a>
        {isSmallScreen ? (
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
          >
            <span className="navbar-toggler-icon" />
          </button>
        ) : (
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/news" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                  News
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/search" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                  Search
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                {isLoggedIn ? (
                  // Display wallet address as a link to a different URL
                  <Link to="/user" className="nav-link nav-link-shrink" style={{ color: 'black' }}>
                    {walletAddress.length > 10
                      ? `${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}`
                      : walletAddress}
                  </Link>
                ) : (
                  // Display the "Login" link
                  <Link to="/login" className="nav-link nav-link-shrink" style={{ color: 'black' }} onClick={closeNav}>
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        )}
        {isSmallScreen && isNavOpen && (
          <div className="navbar-collapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/news" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                  News
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/search" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                  Search
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                {isLoggedIn ? (
                  // Display wallet address as a link to a different URL
                  <Link to="/user" className="nav-link" style={{ color: 'black' }}>
                    {walletAddress}
                  </Link>
                ) : (
                  // Display the "Login" link
                  <Link to="/login" className="nav-link" style={{ color: 'black' }} onClick={closeNav}>
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>

  );
};

export default NavbarScroll;
