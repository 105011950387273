
//Client Side URLS
const login_url = 'https://test-server.evow.io/login';
const user_url = 'https://test-server.evow.io/user';
const searchURL = 'https://test-server.evow.io/searchUser';
const searchRSA = 'https://test-server.evow.io/searchRSA';
const userFriendsURL = 'https://test-server.evow.io/friendsList';
const updateKeysURL = 'https://test-server.evow.io/updateKeys';
const userKeysURL = 'https://test-server.evow.io/userKeys';
const updateFriendsURL = 'https://test-server.evow.io/updateFriendsList';
const userModalURL= 'https://test-server.evow.io/userModal';
const userUpdateURL= 'https://test-server.evow.io/userUpdate';
const contractsGet = 'https://test-server.evow.io/contractsGet';
const contractsPut = 'https://test-server.evow.io/contractsPut';
const platformSubscribe = 'https://test-server.evow.io/platformSubscribe';
const deleteContract = 'https://test-server.evow.io/deleteContract';
const getMessages = 'https://test-server.evow.io/getMessages';
const countURL = 'https://test-server.evow.io/contractCount';
const ipfsFileURL = 'https://test-server.evow.io/ipfsFile';

//Chain Url's
const carbon_test_url = 'https://test-evm-api.carbon.network/';
const carbon_main_url = "https://evm-api.carbon.network/";
const polygonMainUrl = 'https://polygon-rpc.com/';
const polygonTestUrl = 'https://polygon-mumbai-bor.publicnode.com';
const avalancheMainRpcUrl = 'https://api.avax.network/ext/bc/C/rpc';
const avalancheTestRpcUrl = 'https://api.avax-test.network/ext/bc/C/rpc';

module.exports = {
  login_url,
  user_url,
  getMessages,
  contractsGet,
  contractsPut,
  searchRSA,
  carbon_test_url,
  userModalURL,
  userUpdateURL,
  carbon_main_url,
  userFriendsURL,
  userKeysURL,
  updateKeysURL,
  updateFriendsURL,
  searchURL,
  platformSubscribe,
  deleteContract,
  countURL,
  ipfsFileURL,
  polygonMainUrl,
  polygonTestUrl,
  avalancheTestRpcUrl,
  avalancheMainRpcUrl,
};
