import React, { useState} from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import { useNavigate } from 'react-router';
import { updateFriendsURL} from './urlConfig';

const DeleteFriendModal = ( { show, onClose } ) => {
  const navigate = useNavigate();
  const [newFriend, setNewFriend] = useState('');

  const submitData = async () => {

    const localToken = localStorage.getItem('token');

    const response = await fetch(updateFriendsURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
                            newFriend: newFriend,
                            type: "delete"
       }),
    });

    if (response.ok) {
      navigate('/user');
    } else if (response.status === 404) {
      // Redirect to User Registration URL
      navigate('/userOnly');
    }
    else{
      navigate('/userOnly');
    }
  };


  const handleSubmit = async () => {
    try {
      submitData();
    } catch (error) {
      // Handle error occurred during wallet connection or API request
      // Implement your own error handling logic here
      console.log(error);
    }
  };

  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Add Friend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        <Row>
          <Col>
          <p>Please enter your new friend address (Note: Unsubscribed users can only possess 6 friends. If you already possess the quantity, the user will fail to be added to your list.)</p>
          <Form.Control type="text" value={newFriend} onChange={(e) => setNewFriend(e.target.value)} />
          </Col>
        </Row>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
);

};

export default DeleteFriendModal;
