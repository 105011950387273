import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { SubscribedContext } from './subscribedProvider';
import {
  pp4EvowABI,
  pp4EvowBytecode,
  pp4UscABI,
  pp4UscBytecode,
  pp4AvaxABI,
  pp4AvaxBytecode,
  pp4SwthABI,
  pp4SwthBytecode,
  pp4WbtcABI,
  pp4WbtcBytecode,
  pp4EvowABI_Matic,
  pp4EvowBytecode_Matic,
  pp4MaticABI,
  pp4MaticBytecode,
  pp4UsdcABI,
  pp4UsdcBytecode,
  pp4WbtcABI_Matic,
  pp4WbtcBytecode_Matic } from './abiConfig';

import { avalancheMainRpcUrl, polygonMainUrl, contractsPut  } from './urlConfig';
import { chainPrompt } from './chainPrompt';
import Web3 from 'web3';

const FourToFourModal = ({ show, onClose }) => {
  const { isSubscribed } = useContext(SubscribedContext);
  const [step, setStep] = useState(1);
  const [chain, setChain] = useState('Polygon');
  const [token, setToken] = useState('EVOW');
  const [groupOneAddresses, setGroupOneAddresses] = useState(new Array(4).fill(''));
  const [groupTwoAddresses, setGroupTwoAddresses] = useState(new Array(4).fill(''));
  const [intermediary, setIntermediary] = useState('');
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);
  const [contractAddress, setContractAddress] = useState('');
  const [informedText, setInformedText] = useState('Proceed');
  const walletAddress = localStorage.getItem('walletAddress');
  const providerType = localStorage.getItem('provider');
  const localToken = localStorage.getItem('token');
  const navigate = useNavigate();

  const deployContract = async () => {
    let peerContract;
    let deploymentData;
    let contractType;

    let web3;

    const switchChain = await chainPrompt(chain, providerType);

    if (chain === "Avalanche"){
      web3 = new Web3(avalancheMainRpcUrl);
    }
    else if (chain === "Polygon"){
      web3 = new Web3(polygonMainUrl);
    }

    let provider;

    if (providerType === 'coinbase') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      } catch(e){
        provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
      }
    }

    if (providerType === 'metamask') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      } catch(e){
        provider = window.ethereum.isMetaMask ? window.ethereum : null;
      }
    }

    if (providerType === 'walletConnect') {

      try{
        provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
      } catch(e){
        provider = window.ethereum.isWalletConnect ? window.ethereum : null;
      }
    }

    setInformedText('Building Tx');

    if (token === 'EVOW' && chain === 'Avalanche'){
      peerContract = new web3.eth.Contract(pp4EvowABI);
      contractType = "pp4EvowABI"
      deploymentData = peerContract.deploy({
        data: pp4EvowBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString())
        ]
      }).encodeABI();
    }
    else if (token === 'WBTC' && chain === 'Avalanche'){
      const submissionAmount = BigInt(Math.floor(totalDepositAmount * (10 ** 8))).toString();
      peerContract = new web3.eth.Contract(pp4WbtcABI);
      contractType = "pp4WbtcABI"
      deploymentData = peerContract.deploy({
        data: pp4WbtcBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          submissionAmount
        ]
      }).encodeABI();
    }
    else if (token === 'USDC' && chain === 'Avalanche'){
      peerContract = new web3.eth.Contract(pp4UscABI);
      contractType = "pp4UscABI"
      deploymentData = peerContract.deploy({
        data: pp4UscBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString(), 'mwei')
        ]
      }).encodeABI();
    }
    else if (token === 'AVAX' && chain === 'Avalanche'){
      peerContract = new web3.eth.Contract(pp4AvaxABI);
      contractType = "pp4AvaxABI"
      deploymentData = peerContract.deploy({
        data: pp4AvaxBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString())
        ]
      }).encodeABI();
    }
    else if (token === 'MATIC' && chain === 'Polygon'){
      peerContract = new web3.eth.Contract(pp4MaticABI);
      contractType = "pp4MaticABI"
      deploymentData = peerContract.deploy({
        data: pp4MaticBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString())
        ]
      }).encodeABI();
    }
    else if (token === 'EVOW' && chain === 'Polygon'){
      peerContract = new web3.eth.Contract(pp4EvowABI_Matic);
      contractType = "pp4EvowABI_Matic"
      deploymentData = peerContract.deploy({
        data: pp4EvowBytecode_Matic,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString())
        ]
      }).encodeABI();
    }
    else if (token === 'USDC' && chain === 'Polygon'){
      peerContract = new web3.eth.Contract(pp4UsdcABI);
      contractType = "pp4UsdcABI"
      deploymentData = peerContract.deploy({
        data: pp4UsdcBytecode,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          web3.utils.toWei(totalDepositAmount.toString(), 'mwei')
        ]
      }).encodeABI();
    }
    else if (token === 'WBTC' && chain === 'Polygon'){
      const submissionAmount = BigInt(Math.floor(totalDepositAmount * (10 ** 8))).toString();
      peerContract = new web3.eth.Contract(pp4WbtcABI_Matic);
      contractType = "pp4WbtcABI_Matic"
      deploymentData = peerContract.deploy({
        data: pp4WbtcBytecode_Matic,
        arguments: [
          groupOneAddresses,
          groupTwoAddresses,
          intermediary,
          submissionAmount
        ]
      }).encodeABI();
    }

    try {

      // Prepare transaction parameters
      const transactionParameters = {
        from: walletAddress,
        data: deploymentData,
      };

      let gasEstimate = await web3.eth.estimateGas(transactionParameters);
      let gasPrice = await web3.eth.getGasPrice();

      let gasFee = gasEstimate * 2

      // Now, you can construct and send your transaction with the calculated gas values
      const newTxParameters = {
        ...transactionParameters,
        gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
        gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
      };

      setInformedText('Waiting on Chain');

      //Send to user via eth_sendTransaction request with parameters
      const transactionHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [newTxParameters]
      });

      setInformedText('Waiting on Receipt');

      // Poll every 2 seconds for the transaction receipt
      const checkInterval = 3000; // 2 seconds
      let receipt = null;
      while (receipt === null) {
        await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
          // Check for the receipt
        receipt = await web3.eth.getTransactionReceipt(transactionHash);
      }

      setInformedText('Server...');

      const groups = [...groupOneAddresses, ...groupTwoAddresses];

      //Server call to store contract, notify participants and add to interactions
      const response = await fetch(contractsPut, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "chain": chain, "contractAddress": receipt.contractAddress, "contractType": contractType, "med": intermediary, "groups": groups }),
      });

      setInformedText('Response...');

      if (response.ok) {
        // Handle successful response from the API
        // Implement your own logic here
        //const res = await response.json
        onClose();
        setStep(1);
        setInformedText('');
        navigate('/user');
      } else if (response.status === 404) {
        // Redirect to User Registration URL
        onClose();
        setStep(1);
        setInformedText('');
        navigate('/user');
      }
      else{
        console.log("ERROR ELSE");
      }

    } catch (error) {
      console.error("Error deploying contract:", error);
    }
  };


  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleProceed = async() => {
    // Perform actions on "Proceed" button click
    console.log('Proceed clicked');
    await deployContract();
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title>Four To Four</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        {step === 1  && isSubscribed === true &&(
          <>
            <p>As a subscribed member, you have the option of choosing between deployment on Polygon or Avalanche blockchains. Please choose an option: </p>

            <Form>
              <Form.Check
                type="radio"
                label="Avalanche"
                name="chainOption"
                id="Avalanche"
                value="Avalanche"
                checked={chain === "Avalanche"}
                onChange={(e) => setChain(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Polygon"
                name="chainOption"
                id="Polygon"
                value="Polygon"
                checked={chain === "Polygon"}
                onChange={(e) => setChain(e.target.value)}
              />
            </Form>
            <br/>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 1  && isSubscribed === false &&(
          <>
            <p>As a unsubscribed member, you are allowed to deploy on the Polygon blockchain (Avalanche available to subscribed members). Please make sure you have switched to the correct wallet. </p>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 2  && isSubscribed === true && chain === 'Avalanche' &&(
          <>
            <p>As a subscribed member, you have the option of choosing between four different tokens, including the native Avalanche token (AVAX) and our token EVOW! Please choose an option: </p>

            <Form>
              <Form.Check
                type="radio"
                label="EVOW"
                name="tokenOption"
                id="EVOW"
                value="EVOW"
                checked={token === "EVOW"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="AVAX"
                name="tokenOption"
                id="AVAX"
                value="AVAX"
                checked={token === "AVAX"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="WBTC"
                name="tokenOption"
                id="WBTC"
                value="WBTC"
                checked={token === "WBTC"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="USDC"
                name="tokenOption"
                id="USDC"
                value="USDC"
                checked={token === "USDC"}
                onChange={(e) => setToken(e.target.value)}
              />
            </Form>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 2  && isSubscribed === true && chain === 'Polygon' &&(
          <>
            <p>As a subscribed member, you have the option of choosing between four different tokens, including the native Polygon token (MATIC) and our token EVOW (on the Polygon blockchain)! Please choose an option: </p>

            <Form>
              <Form.Check
                type="radio"
                label="EVOW"
                name="tokenOption"
                id="EVOW"
                value="EVOW"
                checked={token === "EVOW"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="MATIC"
                name="tokenOption"
                id="MATIC"
                value="MATIC"
                checked={token === "MATIC"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="WBTC"
                name="tokenOption"
                id="WBTC"
                value="WBTC"
                checked={token === "WBTC"}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="USDC"
                name="tokenOption"
                id="USDC"
                value="USDC"
                checked={token === "USDC"}
                onChange={(e) => setToken(e.target.value)}
              />
            </Form>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 2  && isSubscribed === false &&(
          <>
            <p>We'll be deploying our contract on the Polygon blockchain. The primary deposit/withdraw token is EVOW. You'll need to possess both tokens to deploy and interact with the contract.</p>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 3 && (
          <>
            <p style={{ marginBottom: '10px' }}>Enter the addresses for peer group one. The first address is considered the group leader. The leader can issue a dispute on the contract.</p>
            {groupOneAddresses.map((address, index) => (
              <div key={index} style={{ marginBottom: '5px' }}>
                <Form.Control type="text" value={address} onChange={(e) => {
                  const newAddresses = [...groupOneAddresses];
                  newAddresses[index] = e.target.value;
                  setGroupOneAddresses(newAddresses);
                }} />
              </div>
            ))}
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 4 && (
          <>
            <p style={{ marginBottom: '10px' }}>Enter the addresses for peer group two. The first address is considered the group leader. The leader can issue a dispute on the contract.</p>
            {groupTwoAddresses.map((address, index) => (
              <div key={index} style={{ marginBottom: '5px' }}>
                <Form.Control type="text" value={address} onChange={(e) => {
                  const newAddresses = [...groupTwoAddresses];
                  newAddresses[index] = e.target.value;
                  setGroupTwoAddresses(newAddresses);
                }} />
              </div>
            ))}
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 5 && (
          <>
            <p>Enter the intermediary address</p>
            <Form.Control type="text" value={intermediary} onChange={(e) => setIntermediary(e.target.value)} />
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 6 && (
          <>
            <p>Enter the total deposit amount for each group.</p>
            <Form.Control type="number" value={totalDepositAmount} onChange={(e) => setTotalDepositAmount(parseFloat(e.target.value))} />
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 7 && (
          <>
            <p>Chain: {chain}</p>
            <p>Token: {token}</p>
            <p style={{ wordWrap: 'break-word', maxWidth: '100%' }}>Peer Group One: {groupOneAddresses}</p>
            <p style={{ wordWrap: 'break-word', maxWidth: '100%' }}>Peer Group Two: {groupTwoAddresses}</p>
            <p>Anticipated Deposit (Whole Group): {totalDepositAmount}</p>
            <p>Are you sure you'd like to proceed? Upon clicking "Proceed", a message should appear on your screen from your Metamsk wallet. Please review and confirm for deployment. It may take 5-10 seconds to complete.</p>
            <Button variant="primary" onClick={handleProceed}>{informedText}</Button>
          </>
        )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FourToFourModal;
