import React, { useState, useContext, useEffect } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import Web3 from 'web3';
import forge from 'node-forge';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUser } from 'react-icons/fa';
import { BiSolidLogOutCircle } from 'react-icons/bi';
import './App.css'; // Import CSS file for custom styles
import { SubscribedContext } from './subscribedProvider';
import { AuthContext } from './authProvider';
import AddFriendModal from './userFriendAddModal';
import DeleteFriendModal from './userFriendDeleteModal';
import GenerateRSAModal from './modalGenerateRSA';
import PostMessageModal from './postMessage';
import {userKeysURL, polygonMainUrl, polygonTestUrl, getMessages} from './urlConfig';
import { messagingAddress, messagingABI, messagingBytecode } from './abiConfig';
import { useNavigate } from 'react-router';

const KeyPair = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const outlinedColumnStyle = {
    border: '2px solid #a60f85', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#fbd7fc',
  };
  const { subscribed, unsubscribe } = useContext(SubscribedContext);
  const { isSubscribed } = useContext(SubscribedContext);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const walletAddress = localStorage.getItem("walletAddress");
  const [searchingText, setSearchingText] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [pubKeys, setPubKeys] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showModals, setShowModals] = useState({
   AddFriendModal: false,
   DeleteFriendModal: false,
   GenerateRSAModal: false,
   // Add more modal flags here if needed
 });

 const handleLogOff = () => {
   logout();
   unsubscribe();
   navigate('/');
 }

 const handleModalClick = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: true,
   });
 };

 const handleCloseModal = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: false,
   });
 };

 const handlePostMessage = (modalName) => {
   setShowModals((prevShowModals) => ({
     ...prevShowModals,
     PostMessageModal: true,
   }));
 };

 const handleGenerateRSA = (modalName) => {
   setShowModals((prevShowModals) => ({
     ...prevShowModals,
     GenerateRSAModal: true,
   }));
 };

 const handleDropdownChange = (event) => {
  setSelectedItem(event.target.value);
};

const handleTxClick = async(tx_hash) => {
  console.log("TX: ", tx_hash);
};

 const modalComponents = [
   {
     name: 'AddFriendModal',
     component: AddFriendModal,
   },
   {
     name: 'PostMessageModal',
     component: PostMessageModal,
   },
   {
     name: 'GenerateRSAModal',
     component: GenerateRSAModal,
   },
   // Add more modal components here
 ];

  const gatherData = async () => {
    const localToken = localStorage.getItem('token');

    let response = await fetch(userKeysURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localToken }),
    });

    if (response.ok) {

      //Handle success
      const res = await response.json();

      //Set user from server response
      setPubKeys(res['keys']);

    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
    }


    response = await fetch(getMessages, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localToken }),
    });

    if (response.ok) {

      //Handle success
      const res = await response.json();

      //Set user from server response
      setMessages(res['messages']);

    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
    }

    setLoading(false);

  };

  useEffect(() => {

    // Update the state with the extracted data
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      // Token exists, set it in the state
      setToken(storedToken);
      gatherData();
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Show loading message or spinner
  }

  return (
    <div>
    {modalComponents.map((modal) => (
      <modal.component
        key={modal.name}
        show={showModals[modal.name]}
        onClose={() => handleCloseModal(modal.name)}
      />
    ))}
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <Link to="/user">
            <button className="back-button" style={{ marginRight: '10px' }}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
          </Link>
          <Link to="/user">
            <button className="profile-button" style={{ marginRight: '10px' }} >
              <FaUser style={{ fontSize: '24px'}}/> Profile
            </button>
          </Link>
          <button className="back-button" style={{ marginRight: '10px' }} onClick={handleLogOff}>
            <BiSolidLogOutCircle  style={{ fontSize: '24px'}} /> Logout
          </button>
        </div>
      </Col>
    </Container>

    <Container>
      <Col style={outlinedColumnStyle}>
        <h3>Public Keys</h3>
          <label htmlFor="dropdown">Select Public Key:</label>
          <select id="dropdown" onChange={handleDropdownChange}>
            <option value="" disabled selected>Select an item</option>
            {pubKeys && pubKeys.map((pubKey, index) => (
              <option key={index} value={pubKey}>
                {`Key ${index + 1}`}
              </option>
            ))}
          </select>

          {selectedItem && (
            <div className="selected-item-details">
              <h3>Public Key Details:</h3>
              {selectedItem.split(',').map((item, index) => (
                <React.Fragment key={index}>
                  {index === 0 ? <p>{item.replace(/[';]/g, '').trim()}</p> : <p>{item.trim()}</p>}
                </React.Fragment>
              ))}
            </div>
          )}
      </Col>
    </Container>

    <Container>
      <Col style={outlinedColumnStyle}>
        <h3>Messages</h3>
          <Row>
            <Col>
              <p>From/Tx Hash</p>
            </Col>
            <Col>
              <p></p>
            </Col>
            <Col>
              <p></p>
            </Col>
            <Col>
              <p></p>
            </Col>
          </Row>

          {messages.map((item, index) => (
            <Row>
              <Col>
                <p style={{ wordWrap: 'break-word' }}>{item[0]}</p>
              </Col>
              <Col>
                <p style={{ wordWrap: 'break-word' }}>{item[1]}</p>
              </Col>
              <Col>
                <p><button onClick={() => handleTxClick(item[1])}>Read</button></p>
              </Col>
              <Col>
                <p><button onClick={() => handleTxClick(item[1])}>Delete</button></p>
              </Col>
            </Row>
          ))}
      </Col>
    </Container>

    <Container>
      <Col style={outlinedColumnStyle}>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button onClick={handleGenerateRSA}  style={{ marginRight: '10px' }}>
            Generate New Key Pair
          </button>
          <button onClick={handlePostMessage} style={{ marginRight: '10px' }}>
            Delete Key Pair
          </button>
          <button onClick={handlePostMessage}>
            Send Encrypted Message
          </button>
        </div>
      </Col>
    </Container>
    </div>
  );
};

export default KeyPair;
