import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Container, Table, Col, Row, Button } from 'react-bootstrap';
import { FaTwitter, FaTelegram, FaPlaystation, FaXbox, FaCheck, FaTimes } from 'react-icons/fa';
import { BiSolidLogOutCircle } from 'react-icons/bi';
import { SubscribedContext } from './subscribedProvider';
import { AuthContext } from './authProvider';
import { AdminContext } from './adminProvider';
import SubscriptionModal from './subscribe';
import PurchaseModal from './purchase';
import ConversionModal from './convert';
import ContractsComponent from './contracts';
import UserUpdateModal from './userUpdate';
import { user_url } from './urlConfig';

import { SiHiveBlockchain } from "react-icons/si";
import {ReactComponent as BuyCrypto} from './images/wallet.svg';
import { ReactComponent as SmContractIcon } from './images/smCtIcon.svg';
import { ReactComponent as SubscribeIcon } from './images/subButton.svg';
import { ReactComponent as ProfileIcon } from './images/profile.svg';
import { ReactComponent as ConversionIcon } from './images/conversion.svg';
import { ReactComponent as EncryptionIcon } from './images/encryption.svg';

const User = () => {
  const { subscribed, unsubscribe } = useContext(SubscribedContext);
  const { isSubscribed } = useContext(SubscribedContext);
  const { isAdmin } = useContext(AdminContext);
  const { adminLogin, adminLogout } = useContext(AdminContext);
  const { login, logout } = useContext(AuthContext);
  const { isLoggedIn } = useContext(AuthContext);
  const [user, setUser] = useState({address: "", twitter: "", telegram: "", playstation: "", xbox: "", active_contracts: [], subscription: '', friends: []});
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [showModals, setShowModals] = useState({
   SubscriptionModal: false,
   PurchaseModal: false,
   UserUpdateModal: false,
 });

 const outlinedColumnStyle = {
   border: '2px solid #000000', // Adjust border style as needed
   padding: '20px', // Add padding for spacing
   borderRadius: '10px', // Add border radius for rounded corners
   margin: '6px', // Add margin on all sides
   backgroundColor: '#FEAE67',
   overflowWrap: 'break-word'
 };

 const handleLogOff = () => {
   logout();
   unsubscribe();
   navigate('/');
 }

  const handleSeeContracts = () =>{
    navigate('/subscribed');
  }

  const handleModalClick = (modalName) => {
    setShowModals({
      ...showModals,
      [modalName]: true,
    });
  };

  const handleCloseModal = (modalName) => {
    setShowModals({
      ...showModals,
      [modalName]: false,
    });
  };

  const modalComponents = [
    {
      name: 'SubscriptionModal',
      component: SubscriptionModal,
    },
    {
      name: 'PurchaseModal',
      component: PurchaseModal,
    },
    {
      name: 'UserUpdateModal',
      component: UserUpdateModal,
    },
    {
      name: 'ConversionModal',
      component: ConversionModal,
    },
    // Add more modal components here
  ];

  const handleSubscribeClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      SubscriptionModal: true,
    }));
  };

  const handlePurchaseClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      PurchaseModal: true,
    }));
  };

  const handleUserUpdateClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      UserUpdateModal: true,
    }));
  };

  const handleConvertClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ConversionModal: true,
    }));
  };

  const handleSeeFriendsList = () => {
    navigate('/friends')
  };

  const handleAdminClick = () => {
    navigate('/adminPanel')
  };

  const handleKeysClick = () => {
    navigate('/keys')
  };

  const gatherData = async () => {
    const localToken = localStorage.getItem('token');

    const response = await fetch(user_url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localToken }),
    });

    if (response.ok) {
      // Handle successful response from the API
      // Implement your own logic here
      const res = await response.json();

      //Set user from server response
      setUser(res);

      login();

      //Set loading to false
      setLoading(false);
    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
      console.log("Response: ", response);
    }
  };

  useEffect(() => {

    // Update the state with the extracted data
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      // Token exists, set it in the state
      setToken(storedToken);
      gatherData();
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Show loading message or spinner
  }

  return (
    <div>
      <Container>
        <Col style={outlinedColumnStyle}>
          <div className="navigation-container">
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleLogOff}>
              <BiSolidLogOutCircle  style={{ fontSize: '24px'}} /> Logout
            </button>
          </div>
        </Col>
      </Container>
      <Container>
          <Row>
            <Col style={outlinedColumnStyle}>
              <h3>Profile</h3>
              <Table bordered variant="outline-primary" className="user-profile-table">
                <tbody>
                  <tr>
                    <td>Address:</td>
                    <td style={{ overflowWrap: 'break-word' }} >{user.address}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaTelegram /> Telegram:
                    </td>
                    <td style={{ overflowWrap: 'break-word' }} >{user.telegram}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaTwitter /> Twitter:
                    </td>
                    <td style={{ overflowWrap: 'break-word' }} >{user.twitter}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaPlaystation /> PlayStation:
                    </td>
                    <td style={{ overflowWrap: 'break-word' }} >{user.playstation}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaXbox /> Xbox:
                    </td>
                    <td style={{ overflowWrap: 'break-word' }} >{user.xbox}</td>
                  </tr>
                  <tr>
                    <td>
                      Subscription
                    </td>
                    <td>
                      {isSubscribed ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Subscription Exp.
                    </td>
                    <td>
                      {isSubscribed ? user.subExp : '-' }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <Container>
            <Row>
              <Col style={outlinedColumnStyle}>
                <h3>Friends</h3>
                <Table bordered variant="outline-primary" className="user-profile-table">
                  <tbody>
                    <tr>
                      <td colSpan="2" style={{ overflowWrap: 'break-word' }} >
                        {user.friends && user.friends.slice(0, 5).map((friend, index) => (
                          <div key={index}>{friend}</div>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <button onClick={handleSeeFriendsList}>
                    Learn About Friends
                  </button>
                </div>
              </Col>
            </Row>
          </Container>

        <Container>
        <div>
          <Row>
            <Col style={outlinedColumnStyle}>
             <h3>Active Contracts</h3>
                <ContractsComponent contracts={user.contracts} />
            </Col>
          </Row>
        </div>
        </Container>
        {modalComponents.map((modal) => (
          <modal.component
            key={modal.name}
            show={showModals[modal.name]}
            onClose={() => handleCloseModal(modal.name)}
          />
        ))}
        <div>
          <Container>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Row>
                <Col>
                    {user.subscription === 'True' ? (
                      <>
                        <Button className="update-profile-button" variant="primary" onClick={handleSeeContracts} style={{ width: '400px', marginBottom: '10px' }}>Deploy a Smart Contract<SmContractIcon style={{ width: '150px', height: '150px' }}/></Button>
                      </>
                    ) : (
                      <Button  className="update-profile-button" variant="primary" onClick={handleSeeContracts} style={{ width: '400px', marginBottom: '10px'  }}>Deploy a Smart Contract<SmContractIcon style={{ width: '150px', height: '150px' }}/></Button>
                    )}
                </Col>

                <Col>
                    {user.subscription === 'True' ? (
                      <>
                        <Button className="update-profile-button" variant="primary" onClick={handleSubscribeClick} style={{ width: '400px', marginBottom: '10px' }}>________<SubscribeIcon style={{ width: '150px', height: '150px' }}/></Button>
                        </>
                    ) : (
                      <Button className="update-profile-button" variant="primary" onClick={handleSubscribeClick} style={{ width: '400px', marginBottom: '10px' }}>__________<SubscribeIcon style={{ width: '150px', height: '150px' }}/></Button>
                    )}
                </Col>

              </Row>
            </div>
          </Container>
          <Container>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Row>

                <Col>
                  <Button className="update-profile-button" variant="primary" onClick={handlePurchaseClick} style={{ width: '400px', marginBottom: '10px' }}>Purchase EVOW Token<BuyCrypto style={{ width: '150px', height: '150px' }}/></Button>
                </Col>

                <Col>
                  <Button className="update-profile-button" variant="primary" onClick={handleUserUpdateClick} style={{ width: '400px', marginBottom: '10px' }}>Update User Profile<ProfileIcon style={{ width: '150px', height: '150px' }}/></Button>
                </Col>

              </Row>
            </div>
          </Container>


          {isSubscribed ? (
            <Container>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Row>
                  <Col>
                    <Button className="update-profile-button" variant="primary" onClick={handleConvertClick} style={{ width: '400px', marginBottom: '10px' }}>Blockchain Conversion<ConversionIcon style={{ width: '150px', height: '150px' }}/></Button>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (<p></p>)}

          {isAdmin ? (
            <Container>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Row>
                  <Col>
                    <h3>Administrators</h3>
                    <Button className="update-profile-button" variant="primary" onClick={handleAdminClick} style={{ width: '400px', marginBottom: '10px' }}>Admin Panel</Button>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (<p></p>)}
        </div>
    </div>
  );
}

export default User;
