import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { learnSavings,
         learnPeer2Peer,
         learnEmployment,
         learnUsersStorage,
         learnROSCA,
         learnROSCAContract,
         learnFees,
         learnMediatorFees,
         learnDelete,
         learnCCIP} from './learnConfig';

const HowTo = () => {
  // Use white-space: pre-line; to preserve line breaks
 const textWithLineBreaks = { whiteSpace: 'pre-line' };

  return (
    <>
    <Helmet>
      <title>How To Use eVow.io | Smart Contracts & Financial Tools</title>
      <meta name="description" content="Explore our comprehensive guide on using eVow's smart contracts, financial agreements, and other blockchain tools. Learn about ROSCA, savings contracts, and more." />
      <meta name="keywords" content="eVow How To, Smart Contracts Guide, ROSCA, Financial Agreements, Blockchain Tools, User Guide" />
      <meta property="og:title" content="How To Use eVow - Your Guide to Smart Contracts" />
      <meta property="og:description" content="Discover how to use eVow's smart contracts and financial tools effectively. Get answers to common questions about ROSCA, savings contracts, and more." />
      <meta property="og:image" content="https://evow.io/logo1280.png" />
      <meta property="og:url" content="https://evow.io/how" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="How To Use eVow - Your Guide to Smart Contracts" />
      <meta name="twitter:description" content="Explore our guide on eVow's smart contracts and financial tools. Get answers to common questions about ROSCA, savings contracts, and more." />
      <meta name="twitter:image" content="https://evow.io/logo1280.png" />
      <link rel="canonical" href="https://evow.io/how" />
    </Helmet>
    <Container>
      <h1>Questions and Answers</h1>

      {/* ROSCA Explanation */}
      <section>
        <h2>What is a ROSCA?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnROSCA}</p>
          </Col>
        </Row>
      </section>

      {/* ROSCA Contract Explained */}
      <section>
        <h2>How does the ROSCA smart contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnROSCAContract}</p>
          </Col>
        </Row>
      </section>

      {/* Savings */}
      <section>
        <h2>How does the savings contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnSavings}</p>
          </Col>
        </Row>
      </section>

      {/* Peer to Peer */}
      <section>
        <h2>How does a peer to peer financial agreement work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnPeer2Peer}</p>
          </Col>
        </Row>
      </section>

      {/* CCIP */}
      <section>
        <h2>What is the burn and mint CCIP?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnCCIP}</p>
          </Col>
        </Row>
      </section>

      {/* Employment */}
      <section>
        <h2>How are the employment contracts structured?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnEmployment}</p>
          </Col>
        </Row>
      </section>

      {/* Users Storage */}
      <section>
        <h2>How does the the users database contract work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnUsersStorage}</p>
          </Col>
        </Row>
      </section>

      {/* Mediators */}
      <section>
        <h2>How do mediator fees work?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnMediatorFees}</p>
          </Col>
        </Row>
      </section>

      {/* Charging? */}
      <section>
        <h2>Does eVow.io charge fees for their services?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnFees}</p>
          </Col>
        </Row>
      </section>

      {/* Contract Deletion */}
      <section>
        <h2>Can I delete a smart contract?</h2>
        <Row>
          <Col>
            <p style={textWithLineBreaks}>{learnDelete}</p>
          </Col>
        </Row>
      </section>
    </Container>
  </>
  );
};

export default HowTo;
