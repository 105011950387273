import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';

const Tokenomics = () => {
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  return (
    <>
    <Helmet>
      <title>Tokenomics of EVOW</title>
      <meta name="description" content="Explore the tokenomics of the EVOW token, including supply, allocation, and utility within the eVow.io ecosystem. Learn how the token supports smart contracts and community engagement." />
      <meta name="keywords" content="EVOW Tokenomics, eVow.io, Smart Contracts, Token Allocation, Blockchain, Cryptocurrency" />
      <meta property="og:title" content="Tokenomics Report - EVOW Token | eVow.io" />
      <meta property="og:description" content="Discover the details of the EVOW tokenomics, including initial supply, distribution, and its role in enhancing financial agreements and smart contracts." />
      <meta property="og:image" content="https://evow.io/logo1280.png" />
      <meta property="og:url" content="https://evow.io/tokenomics" />
      <meta property="og:type" content="article" />
      <link rel="canonical" href="https://evow.io/tokenomics" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Tokenomics of EVOW | eVow.io" />
      <meta name="twitter:description" content="Read about the tokenomics of the EVOW token, including its allocation and utility in the eVow.io ecosystem." />
      <meta name="twitter:image" content="https://evow.io/logo1280.png" />
    </Helmet>

    <div className="pl-5 pr-5">
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <h1 style={{textAlign: 'center'}}>Tokenomics Report</h1>
          <h1 style={{textAlign: 'center'}}>EVOW Token - eVow.io | Smart Contracts</h1>
          <p className="lead">Initial Supply: 25 Million Tokens</p>
          <p className="lead">Main Blockchain: Polygon PoS</p>
          <p className="lead">CCIP Blockchains: Avalanche</p>

          <p className="lead">Smart Contract Development and Marketing Fund: 48% (12 Million Tokens)</p>
          <p className="lead">These tokens are allocated for funding future enhancements, developments, token pools and marketing initiatives. This fund will support the growth, innovation, and promotion of the project.</p>

          <p className="lead">Core Developers and Initial Funding Participants: 30% (7.5 Million Tokens)</p>
          <p className="lead">This portion of tokens is reserved for rewarding the core development team and initial participants who supported the project's inception and growth. These tokens act as an incentive for their contributions and dedication to the project.</p>

          <p className="lead">Presale: 15% (3.75 Million Tokens)</p>
          <p className="lead">A portion of tokens will be available for presale to the public. This will help fund the project and provide an opportunity for users to acquire tokens before the full launch.</p>

          <p className="lead">Airdrop and Presale Bonus (Over Phases): 7% (1.75 Million Tokens)</p>
          <p className="lead">Tokens will be distributed as airdrops and bonuses during different phases of the project to engage and incentivize the community's participation and early adopters.</p>

          <p className="lead">Utilization/Purpose:</p>

          <p className="lead">The EVOW token will serve as the primary utility within the eVow.io ecosystem. Users can access various smart contracts on the platform using their Metamask or Coinbase Wallet via Polygon blockchain. Signing up on the platform involves interacting with a Polygon EVM-based smart contract, ensuring security and transparency.</p>

          <p className="lead">The token provides access to a range of smart contracts, with subscribed users enjoying the added benefit of access to Polygon Circle USDC and WBTC contracts. These contracts offer stability and security against market volatility. The platform currently includes sections such as Banking, Employment, Peer to Peer, Record Storage and more.</p>

          <p className="lead">Subscription Distribution:</p>

          <p className="lead">When users subscribe to the platform, a portion of their subscription fees are distributed as follows:</p>

          <p className="lead">Administrators: 30%</p>
          <p className="lead">This portion is allocated to platform administrators, rewarding their role in maintaining and enhancing the ecosystem.</p>

          <p className="lead">Development Fund: 40%</p>
          <p className="lead">These funds contribute to the ongoing development, innovation, and growth of the smart contracts and the platform as a whole.</p>

          <p className="lead">Burn: 30%</p>
          <p className="lead">A portion of the subscription fees is burnt, reducing the overall token supply over time. This deflationary mechanism can enhance scarcity and potentially contribute to the token's value appreciation.</p>

          <p className="lead">Note: The above tokenomics design aims to strike a balance between incentivizing participation, rewarding contributors, promoting platform growth, and maintaining a deflationary mechanism. It also addresses different phases of the project, ranging from development to community engagement and adoption.</p>
        </Col>
      </Row>
    </Container>
    </div>
    </>
  );
};

export default Tokenomics;
