import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { SubscribedContext } from './subscribedProvider';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressModal = ({ show, onClose }) => {
  const { isSubscribed } = useContext(SubscribedContext);
  const localToken = localStorage.getItem('token');

return (
  <Modal show={show} onHide={onClose} centered>
    <Modal.Header closeButton style={{color: 'black'}}>
      <Modal.Title>In Development</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className = 'custom-modal-body'>
        <p>Feature development is on-going.</p>
        <div style={{ width: '100px', margin: 'auto' }}>
          <CircularProgressbar
            value='65'
            text={`65%`}
            styles={buildStyles({
              textColor: '#fff',
              pathColor: `rgba(246, 123, 187, ${65 / 100})`,
              trailColor: '#d6d6d6',
            })}
          />
          <p>65%</p>
        </div>
        <Button variant="primary" onClick={onClose}>Close</Button>
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default ProgressModal;
