import React, { useState, useEffect, useContext } from 'react'
import Web3 from 'web3';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import {
        savingsMultiABI,
        savingsMultiBytecode,
        savingsEvowABI,
        savingsEvowBytecode,
        savingsMultiABIMatic,
        savingsMultiBytecodeMatic,
        savingsEvowABIMatic,
        savingsEvowBytecodeMatic } from './abiConfig';

import { SubscribedContext } from './subscribedProvider';
import { avalancheMainRpcUrl, polygonMainUrl, contractsPut } from './urlConfig';
import { chainPrompt } from './chainPrompt';

const SavingsModal = ({ show, onClose }) => {
  const [step, setStep] = useState(1);
  const [chain, setChain] = useState('Polygon');
  const [customerAddress, setCustomerAddress] = useState('');
  const [lockoutBlocks, setLockoutBlocks] = useState(0);
  const [informedText, setInformedText] = useState('Proceed');
  const { isSubscribed } = useContext(SubscribedContext);
  const localToken = localStorage.getItem('token');
  const walletAddress = localStorage.getItem('walletAddress');
  const navigate = useNavigate();

  const deployContract = async () => {
    let accounts;
    let savingsContract;
    let deploymentData;
    let contractType;

    let web3;

    const switchChain = await chainPrompt(chain);

    if (chain === "Avalanche"){
      web3 = new Web3(avalancheMainRpcUrl);
    }
    else if (chain === "Polygon"){
      web3 = new Web3(polygonMainUrl);
    }

    setInformedText('Building Tx');

    try{

      if (isSubscribed === true && chain === 'Avalanche'){
        contractType = "savingsMultiABI";
        // Initiate Contract
        savingsContract = new web3.eth.Contract(savingsMultiABI);

        // Convert the number to wei using web3.utils.toWei()
        //const totalBetAmountInWei = web3.utils.toWei(totalBetAmount.toString(), "ether");

        deploymentData = savingsContract.deploy({
          data: savingsMultiBytecode,
          arguments: [
            customerAddress,
            lockoutBlocks
          ]
        }).encodeABI();
      }

      else if (isSubscribed === true && chain === 'Polygon'){
        contractType = "savingsMultiABIMatic";
        // Initiate Contract
        savingsContract = new web3.eth.Contract(savingsMultiABIMatic);

        // Convert the number to wei using web3.utils.toWei()
        //const totalBetAmountInWei = web3.utils.toWei(totalBetAmount.toString(), "ether");

        deploymentData = savingsContract.deploy({
          data: savingsMultiBytecodeMatic,
          arguments: [
            customerAddress,
            lockoutBlocks
          ]
        }).encodeABI();
      }

      else if (isSubscribed === false){
        contractType = "savingsEvowABI";

        // Initiate Contract
        savingsContract = new web3.eth.Contract(savingsEvowABI);

        // Convert the number to wei using web3.utils.toWei()
        //const totalBetAmountInWei = web3.utils.toWei(totalBetAmount.toString(), "ether");

        deploymentData = savingsContract.deploy({
          data: savingsEvowBytecode,
          arguments: [
            customerAddress,
            lockoutBlocks
          ]
        }).encodeABI();
      }

      // Prepare transaction parameters
      const transactionParameters = {
        from: walletAddress,
        data: deploymentData,
      };

      let gasEstimate = await web3.eth.estimateGas(transactionParameters);
      let gasPrice = await web3.eth.getGasPrice();

      const newTransactionObj = {
        ...transactionParameters,
        gas: web3.utils.toHex(web3.utils.toBN(gasEstimate)), // Use the estimated gas cost
        gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
      };

      setInformedText('Waiting on chain');

      //Send to user via eth_sendTransaction request with parameters
      const tx = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [newTransactionObj]
      });

      setInformedText('Waiting on receipt');

      // Poll every 2 seconds for the transaction receipt
      const checkInterval = 3000; // 2 seconds
      let receipt = null;
      while (receipt === null) {
        await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
          // Check for the receipt
        receipt = await web3.eth.getTransactionReceipt(tx);
      }

      setInformedText('Server...');

      //Server call to store contract, notify participants and add to interactions
      const response = await fetch(contractsPut, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "chain": chain, "contractAddress": receipt.contractAddress, "contractType": contractType, "customers": [walletAddress, customerAddress], "lockoutBlocks": lockoutBlocks}),
      });

      setInformedText('Response...');

      if (response.ok) {
        // Handle successful response from the API
        // Implement your own logic here
        //const res = await response.json();
        onClose();
        navigate('/user');
      } else if (response.status === 404) {
        // Redirect to User Registration URL
        //console.log('ERROR 404');
        onClose();
        navigate('/user');
      }
      else{
        console.log("ERROR ELSE");
      }
    } catch (error) {
      console.error("Error deploying contract:", error);
    }
  };

  function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleProceed = async() => {
    // Perform actions on "Proceed" button click
    console.log('Proceed clicked');
    await deployContract();
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title>Savings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        {step === 1  && isSubscribed === true &&(
          <>
            <p>As a subscribed member, you have the option of choosing between deployment on Polygon or Avalanche blockchains. Please choose an option: </p>

            <Form>
              <Form.Check
                type="radio"
                label="Avalanche"
                name="chainOption"
                id="Avalanche"
                value="Avalanche"
                checked={chain === "Avalanche"}
                onChange={(e) => setChain(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Polygon"
                name="chainOption"
                id="Polygon"
                value="Polygon"
                checked={chain === "Polygon"}
                onChange={(e) => setChain(e.target.value)}
              />
            </Form>
            <br/>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 1  && isSubscribed === false &&(
          <>
            <p>As a unsubscribed member, you are allowed to deploy on the Polygon blockchain (Avalanche available to subscribed members). You have access to the EVOW token for saving. Please make sure you have switched to the correct wallet. </p>
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
          )
        }

        {step === 2 && (
          <>
            <p style={{ marginBottom: '10px' }}>We're building a savings contract to help ensure tokens remain locked up as per your own designations. There are no incentives associated with a savings contract other than ensure you do not sell the tokens prior to the lockout period. Subscribed members have access to Carbon USD, Polygon USDC, WBTC, SWTH and MATIC deposits, too!</p>
            <Button variant="primary" onClick={handleNextStep}>Get Started</Button>
          </>
        )}

        {step === 3 && (
          <>
            <p style={{ marginBottom: '10px' }}>Enter an additional customer address (2 Allowed and you are one of them).</p>
            <Form.Control style={{ marginBottom: '10px' }} type="text" value={customerAddress} onChange={(e) => setCustomerAddress(e.target.value)} />
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 4 && (
          <>
            <p style={{ marginBottom: '10px' }}>Enter the amount of blocks until funds are available from the contract.</p>
            <p style={{ marginBottom: '10px' }}>One Block = Approximately 2 seconds</p>
            <Form.Control style={{ marginBottom: '10px' }} type="number" value={lockoutBlocks} onChange={(e) => setLockoutBlocks(parseFloat(e.target.value))} />
            <Button variant="primary" onClick={handleNextStep}>Next</Button>
          </>
        )}

        {step === 5 && (
          <>
            <p style={{ marginBottom: '10px' }}>Are you sure you'd like to proceed? Upon clicking "Proceed", a message will appear after a brief 5-15 second pause to build the transaction.  The message will be from your wallet provider. Please review and confirm for deployment. It may take a few minutes to complete.</p>
            <Button variant="primary" onClick={handleProceed}>{informedText}</Button>
          </>
        )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SavingsModal;
