import React, { useEffect } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'; // For rendering Markdown content

const Article = () => {
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  const { id } = useParams();

  const articleData = {
    1: {
      id: 1,
      title: 'eVow.io: Personal Smart Contracts Made Easy on Polygon and Avalanche',
      datePublished: '2024-10-17',
      content: `
      <h1>A Game-Changer in Blockchain is Here</h1>
      <img
        src="https://eVow.io/articleOne.png"
        alt="eVow.io Web3 Highway Matrix"
        style="max-width: 100%; height: auto; margin: 20px 0;"
      />
      eVow.io is revolutionizing the way we think about personal finance, peer-to-peer agreements, employment contracts, and sales transactions by making smart contracts accessible to everyone — from individual users to businesses. With intuitive tools and seamless deployment on the Polygon and Avalanche blockchains, eVow.io promises to bridge the gap between sophisticated blockchain technology and everyday users.

      <h3>Take Control of Your Financial Future — No Middleman Required</h3>
      Tired of complicated financial paperwork? Say goodbye to traditional bureaucracy and hello to custom smart contracts you can deploy yourself with the click of a button. Whether you need a peer-to-peer loan, a sales contract, or even an employment agreement, eVow.io ensures you own and control the entire process.

      <strong>Through eVow.io, Users Can:</strong>
      <ol style="margin-bottom: 0;">
        <li>Create peer-to-peer financial agreements effortlessly with just a few clicks.</li>
        <li>Hire contractors remotely using secure, blockchain-backed contracts.</li>
        <li>Deploy ROSCA (Rotating Savings and Credit Association) smart contracts fueling the financial future for your friends and families.</li>
        <li>Seamlessly exchange EVOW token across Polygon and Avalanche blockchains.</li>
        <li>Handle everything through your MetaMask wallet, eliminating the need for intermediaries.</li>
      </ol>

      <h3>EVOW Token: Powering the Next Generation of Smart Contracts</h3>
      At the heart of eVow.io’s ecosystem is the <strong>EVOW token</strong>. This utility token unlocks premium features, allowing users to deploy sophisticated contracts and gain access to advanced tools. Plus, with cross-chain capability, EVOW tokens can move seamlessly between Polygon and Avalanche using a burn-and-mint mechanism that ensures precise token management.

      <strong>Total Supply: 25 million EVOW tokens</strong>
      <ul>
        <li>48% (12M Tokens): Smart contract development and marketing fund.</li>
        <li>30% (7.5M Tokens): Rewards for core developers and early supporters.</li>
        <li>15% (3.75M Tokens): Presale opportunity: Claim your tokens early and shape the future of eVow.</li>
        <li>7% (1.75M Tokens): Community-first bonuses and airdrops—our way of giving back to early adopters.</li>
      </ul>

      <h3>What’s in it for You? Exclusive Features for Subscribed Members</h3>
      Signing up is free and easy, but becoming a subscribed user unlocks premium perks. From secure address storage to exclusive access to specialized contracts, eVow.io makes complex financial tools accessible to everyone.

      Subscribed users gain access to:
      <ul>
        <li>Contracts with support for EVOW, WBTC, USDC, POL and AVAX to avoid market volatility.</li>
        <li>Cross-chain smart contracts on Avalanche and Polygon networks.</li>
        <li>Special subscription-driven token burns, enhancing the scarcity and value of EVOW over time.</li>
      </ul>

      <h3>A Sustainable Future: How eVow.io Distributes Subscription Fees</h3>
      Your subscription is more than just a service fee; it powers the platform’s future while creating a deflationary token model that benefits everyone:

      <ul>
        <li><strong>30%</strong> supports platform administrators to maintain operations and security.</li>
        <li><strong>40%</strong> fuels continuous development and innovation in new contract tools.</li>
        <li><strong>30%</strong> is burned, reducing the token supply and enhancing the long-term value of EVOW.</li>
      </ul>

      <h3>Ready to Join the Blockchain Revolution?</h3>
      There’s no better time to dive into the world of blockchain-powered smart contracts. With eVow.io, you can secure financial freedom today and redefine finance for tomorrow. Whether you’re an entrepreneur, freelancer, or business owner, the future of secure, transparent agreements is just a few clicks away.

      ---

      <h3>About eVow.io</h3>
      eVow.io is on a mission to make blockchain technology accessible to both retail users and corporate entities. Focused on security, usability, and innovation, eVow.io provides a one-stop platform for deploying and managing smart contracts on the Polygon and Avalanche blockchains. With eVow, you control your contracts, your assets, and your future.

      ---

      <strong>Don’t Get Left Behind — Experience the Future of Finance Today!</strong>
      <a href="https://evow.io/login" target="_blank" rel="noopener noreferrer">
        Sign up now!
      </a>
      <a href="https://t.me/OfficialEvowDiscussion" target="_blank" rel="noopener noreferrer">
        Join eVow on Telegram
      </a>
      <a href="https://x.com/eVowIO" target="_blank" rel="noopener noreferrer">
        Follow eVow on Twitter
      </a>
      <a style={{ color: "blue", textDecoration: "underline" }} href="mailto:info@eVow.io" target="_blank" rel="noopener noreferrer">
      Email Us
      </a>
      <a href="https://evow.io/news" target="_blank" rel="noopener noreferrer">
      Back to eVow.io News
      </a>
    `,
      author: 'eVow.io Communications Team',
      description: 'eVow.io revolutionizes finance with smart contracts on Polygon and Avalanche. Control your financial future with secure, accessible tools.',
      keywords: 'blockchain, smart contracts, eVow, EVOW, financial agreements, peer-to-peer, Polygon, Avalanche, DeFi, ROSCA, altcoin, employment smart contract, p2p, pvp, token, new token, AVAX altcoin, POL altcoin',
      image: `https://eVow.io/articleOne.png`,
    },
  };

  const article = articleData[id];
  const articleUrl = `https://eVow.io/article/${article.id}`

  // Inject structured data into the HTML <head> for Google
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      headline: article.title,
      datePublished: article.datePublished,
      author: {
        "@type": "Person",
        name: article.author,
      },
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [article]);

  return (
    <>
    <Helmet>
      <title>{article.title}</title>
      <meta name="description" content={article.description} />
      <meta name="keywords" content= {article.keywords} />
      <meta property="og:title" content={article.title} />
      <meta property="og:description" content={article.description} />
      <meta property="og:image" content={article.image} />
      <meta property="article:published_time" content={article.datePublished} />
      <link rel="canonical" href={articleUrl} />

     <meta property="og:image" content={article.image} />
     <meta property="og:url" content={articleUrl} />
     <meta property="og:type" content="article" />

     {/* Twitter Card Meta Tags */}
     <meta name="twitter:card" content="summary_large_image" />
     <meta name="twitter:title" content={article.title} />
     <meta name="twitter:description" content={article.description} />
     <meta name="twitter:image" content={article.image} />

    </Helmet>
    <Container className="mt-5">
      <Col style={outlinedColumnStyle}>
       <h1>{article.title}</h1>
       <p>
         <strong>Date Published:</strong> {article.datePublished}
       </p>
       <p>
         <strong>Author:</strong> {article.author}
       </p>
       <div
         style={{ whiteSpace: 'pre-line' }}
         dangerouslySetInnerHTML={{ __html: article.content }}
       />
       </Col>
     </Container>
     </>
  );
};

export default Article;
