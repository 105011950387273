import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { countURL } from './urlConfig'
import './App.css'; // Import your CSS file for custom styling

const CountComponent = () => {
  // Define a state variable to store the count
  const [count, setCount] = useState(0);

  useEffect(() => {
     // Send a POST request to the specified URL
     fetch( countURL, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({ /* Your POST data here */ }),
     })
       .then((response) => response.json())
       .then((data) => {
         if (data && data.count) {
           setCount(data.count);
         }
       })
       .catch((error) => {
         console.error('Error fetching data:', error);
       });
   }, []);

  return (
    <div className="my-component">
      <div className="water-cosmos-bg">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center">Assisting users to build {count} contracts on Polygon and Avalanche blockchains</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CountComponent;
