import React, { useState, useContext, useEffect } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUser } from 'react-icons/fa';
import { BiSolidLogOutCircle } from 'react-icons/bi';
import './App.css'; // Import CSS file for custom styles
import { SubscribedContext } from './subscribedProvider';
import { AuthContext } from './authProvider';
import AddFriendModal from './userFriendAddModal';
import DeleteFriendModal from './userFriendDeleteModal';
import {userFriendsURL} from './urlConfig';
import { useNavigate } from 'react-router';

const Friends = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  const { subscribed, unsubscribe } = useContext(SubscribedContext);
  const { isSubscribed } = useContext(SubscribedContext);
  const [friends, setFriends] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [showModals, setShowModals] = useState({
   AddFriendModal: false,
   DeleteFriendModal: false,
   // Add more modal flags here if needed
 });

 const handleLogOff = () => {
   logout();
   unsubscribe();
   navigate('/');
 }

 const handleModalClick = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: true,
   });
 };

 const handleCloseModal = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: false,
   });
 };

 const handleDeleteFriend = (modalName) => {
   setShowModals((prevShowModals) => ({
     ...prevShowModals,
     DeleteFriendModal: true,
   }));
 };

 const handleAddFriend = (modalName) => {
   setShowModals((prevShowModals) => ({
     ...prevShowModals,
     AddFriendModal: true,
   }));
 };

 const modalComponents = [
   {
     name: 'AddFriendModal',
     component: AddFriendModal,
   },
   {
     name: 'DeleteFriendModal',
     component: DeleteFriendModal,
   },
   // Add more modal components here
 ];

  const gatherData = async () => {
    const localToken = localStorage.getItem('token');

    const response = await fetch(userFriendsURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localToken }),
    });

    if (response.ok) {
      // Handle successful response from the API
      // Implement your own logic here
      const res = await response.json();

      console.log(res);

      //Set user from server response
      setFriends(res);

      //Set loading to false
      setLoading(false);
    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
    }
  };

  useEffect(() => {

    // Update the state with the extracted data
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      // Token exists, set it in the state
      setToken(storedToken);
      gatherData();
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Show loading message or spinner
  }

  return (
    <div>
    {modalComponents.map((modal) => (
      <modal.component
        key={modal.name}
        show={showModals[modal.name]}
        onClose={() => handleCloseModal(modal.name)}
      />
    ))}
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <Link to="/user">
            <button className="back-button" style={{ marginRight: '10px' }}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
          </Link>
          <Link to="/user">
            <button className="profile-button" style={{ marginRight: '10px' }} >
              <FaUser style={{ fontSize: '24px'}}/> Profile
            </button>
          </Link>
          <button className="back-button" style={{ marginRight: '10px' }} onClick={handleLogOff}>
            <BiSolidLogOutCircle  style={{ fontSize: '24px'}} /> Logout
          </button>
        </div>
      </Col>
    </Container>
    <Container>
      <Col style={outlinedColumnStyle}>
      <h3>Friends</h3>
        <Table bordered variant="outline-primary" className="user-profile-table">
          <tbody>
            <tr>
              <td colSpan="2">
                {friends && friends.slice().map((friend, index) => (
                  <div key={index}>{friend}</div>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button onClick={handleAddFriend} style={{ marginRight: '10px' }}>
            Add Friend
          </button>
          <button onClick={handleDeleteFriend}>
            Delete Friend
          </button>
        </div>
      </Col>
    </Container>
    <Container>
      <Col style={outlinedColumnStyle}>
        <h3>Why are Friends Important?</h3>
        <p>
          User safety is our highest priority. Our users need to be positive the contracts for which they are interacting are coming from friends and family. To help our users ensure they are only receiving contracts from friends, another user will not be able to send a contract to you via our platform unless they are on your friends list and visa versa. In order for another user to recceive your contract, you must be on their friends list. This helps us to combat fraud against our users.
        </p>
        <p>
          To help provide more value to our platform and token holders, we limit free users to a maximum of 6 friend addresses. Subscribed members can possess an unlimited amount of friend addresses.
        </p>
      </Col>
    </Container>
    </div>
  );
};

export default Friends;
