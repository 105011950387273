import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Background from './images/backgroune.png';

const Header = () => {
  return (
      <header className="header" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100%',
                backgroundSize: 'cover',
                color: '#FFFFFF',
                margin: '16px 0 0',
                display: 'flex', // Use flexbox
                flexDirection: 'column',
                justifyContent: 'center', // Center vertically
                alignItems: 'center', // Center horizontally
                textShadow: '2px 2px 4px #3a0275',
                fontSize: '24px',
              }}>
        <div className="header-overlay"></div>
        <div className="header-content" style={{ color: '#FFFFFF', paddingLeft: '15px', paddingRight: '15px' }}>
          <h1 style={{ color: '#FFFFFF', paddingLeft: '15px', paddingRight: '15px' }} ></h1>
          <h1 style={{ color: '#FFFFFF', paddingLeft: '15px', paddingRight: '15px' }} >Deploying personal smart contracts on Polygon & Avalanche</h1>
        </div>
      </header>
    );
};

export default Header;
