import React, { useState} from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import { useNavigate } from 'react-router';
import Web3 from 'web3';
import forge from 'node-forge';
import { messagingAddress, messagingABI, messagingBytecode } from './abiConfig';
import { carbon_main_url, carbon_test_url, polygonMainUrl, polygonTestUrl, searchRSA, updateKeysURL  } from './urlConfig';

const GenerateRSAModal = ( { show, onClose } ) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1);
  const [isRsa, setIsRsa] = useState('False');
  const [informedText, setInformedText] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [rsa, setRsa] = useState('');
  const [rsaCount, setRsaCount] = useState(0);
  const [confirmation, setConfirmation] = useState('');
  const [sendAddress, setSendAddress] = useState('');
  const walletAddress = localStorage.getItem("walletAddress");
  const localToken = localStorage.getItem('token');
  const [privateKey, setPrivateKey] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [success, setSuccess] = useState('False');

  // Generate an RSA key pair
  const generateRSAKeyPair = () => {
    return new Promise((resolve, reject) => {
      forge.pki.rsa.generateKeyPair({ bits: 2048 }, (err, keypair) => {
        if (err) {
          reject(err);
        } else {
          const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);
          const publicKeyPem = forge.pki.publicKeyToPem(keypair.publicKey);
          setPrivateKey(privateKeyPem);
          setPublicKey(publicKeyPem);
          resolve();
        }
      });
    });
  };

  const requestKeys = async() => {

    //Server call to store contract, notify participants and add to interactions
    const response = await fetch(searchRSA, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "searchAddress": sendAddress}),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.keys == null) {
        setRsaCount(0)
      }
      else if (data.keys.length == 0) {
        setRsaCount(0)
      }
      else if (data.keys.length == 1){
        setRsaCount(1)
      }
      else if (data.keys.length == 2){
        setRsaCount(2)
      }
      else if (data.keys.length > 2) {
        setRsaCount(3)
      }
    };

  }

  const handleProceedStep = async() => {
    if (rsaCount <= 1){
      await generateRSAKeyPair();
      await addKey();
      setStep(2)
    }
    else{
      setDeleteText("You must delete a key to proceed. Please go back and delete a key.")
    }
  };

  const addKey = async() => {
    //Server call to store contract, notify participants and add to interactions
    const response = await fetch(updateKeysURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "updateType": 'add', 'pubKey': publicKey, 'listItem': "RSA"}),
    });

    if (response.ok) {
      setSuccess("True")
  }

}

  requestKeys()

  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Generate RSA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        {step === 1  && (

          <Row>
            <Col>
              <p>You are allowed to possess two RSA public keys. </p>
              <p>Current Quantity: {rsaCount}</p>
              <p>By clicking on proceed, we will generate a public and private RSA keyset. You will be provided with both keys. It is your responsibility to document the private key. eVow will NOT store your private key. Please keep it for your records. It will be used to decrypt any messages sent to your on chain.</p>
                <br/>
                <Button variant="primary" onClick={handleProceedStep}>Proceed</Button>
            </Col>
          </Row>
        )}

        {step === 2  && (

          <Row>
            <Col>
              <p>Your public key will be stored on our server and used to encrypt messages. Please document the private key. If you lose it, it will be lost. WE DO NOT STORE PRIVATE KEYS! Please copy all test for each key.</p>
              <p style={{ wordWrap: 'break-word' }}>{privateKey}</p>
              <p style={{ wordWrap: 'break-word' }}>{publicKey}</p>
              <br/>
              <p>Your profile has beeen updated with the public key. You may need to log back in for the changes to take effect.</p>
            </Col>
          </Row>
        )}

      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
);

};

export default GenerateRSAModal;
