import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';

const Whitepaper = () => {
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  return (
    <>
    <Helmet>
      <title>Whitepaper for EVOW Token</title>
      <meta name="description" content="Discover how eVow.io is transforming financial agreements with blockchain technology. Explore ROSCAs, peer-to-peer agreements, and personal savings contracts." />
      <meta name="keywords" content="eVow Whitepaper, Financial Agreements, ROSCA, Blockchain, Peer-to-Peer Finance, Personal Savings Contracts" />
      <meta property="og:title" content="Whitepaper - Revolutionizing Financial Agreements with eVow.io" />
      <meta property="og:description" content="Read the eVow.io whitepaper to understand how blockchain is redefining financial agreements through innovative features and secure transactions." />
      <meta property="og:image" content="https://evow.io/logo1280.png" />
      <meta property="og:url" content="https://evow.io/whitepaper" />
      <meta property="og:type" content="article" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Whitepaper - Revolutionizing Financial Agreements with eVow.io" />
      <meta name="twitter:description" content="Explore the transformative approach of eVow.io in financial agreements through blockchain technology. Read our whitepaper now!" />
      <meta name="twitter:image" content="https://evow.io/logo1280.png" />
      <link rel="canonical" href="https://evow.io/whitepaper" />
    </Helmet>
    <div className="pl-5 pr-5">
    <Container>
      <Row>
        <Col style={outlinedColumnStyle}>
          <h1 style={{textAlign: 'center'}}>Whitepaper: Revolutionizing Financial Agreements with eVow.io</h1>

          <p className="lead">Abstract:</p>
          <p className="lead">eVow.io is a pioneering platform that harnesses the power of blockchain technology to redefine the landscape of financial agreements. By facilitating Rotating Savings and Credit Associations (ROSCAs), peer-to-peer financial agreements, and personal savings contracts, eVow.io empowers users to take control of their financial destinies in a secure, transparent, and efficient manner. This whitepaper explores the core functionalities of eVow.io and highlights its innovative approach to revolutionizing finance.</p>

          <p className="lead">Introduction:</p>
          <p className="lead">In today's rapidly evolving financial landscape, traditional models of savings, credit, and financial agreements are being reimagined through blockchain technology. eVow.io stands at the forefront of this revolution, offering users a platform where they can seamlessly engage in various financial activities with trust and confidence. Through the utilization of smart contracts, eVow.io facilitates ROSCAs, peer-to-peer financial agreements, and personal savings contracts, catering to the diverse needs of individuals and groups.</p>

          <p className="lead">ROSCAs on eVow.io:</p>
          <p className="lead">Rotating Savings and Credit Associations (ROSCAs) have long been a popular method for individuals to pool their resources and achieve financial goals collectively. eVow.io digitizes this age-old concept by enabling users to participate in ROSCAs through smart contracts. Users contribute tokens to the ROSCA contract at regular intervals, and the pot of contributions is distributed to members in rotation. This process ensures fairness and transparency, allowing participants to benefit from shared savings and credit opportunities.</p>

          <p className="lead">Peer-to-Peer Financial Agreements:</p>
          <p className="lead">eVow.io facilitates peer-to-peer financial agreements where two groups of peers can deposit funds into a contract and decide on the allocation based on a predetermined score. This feature fosters trust and collaboration among peers, enabling them to engage in financial transactions with confidence. An intervention mechanism is in place to resolve disputes, ensuring fair outcomes for all parties involved.</p>

          <p className="lead">Personal Savings Contracts:</p>
          <p className="lead">For users who struggle with saving tokens, eVow.io offers personal savings contracts where tokens can be locked up for a specified period. This feature promotes financial discipline and encourages users to save for the future. With the option to set lockup periods and designate deposit addresses, users have full control over their savings contracts, ensuring security and flexibility.</p>

          <p className="lead">Data Storage via IPFS and Encryption:</p>
          <p className="lead">In addition to its core functionalities, eVow.io prioritizes data security and privacy. Data storage via the InterPlanetary File System (IPFS) ensures decentralized and immutable storage of sensitive information, while encryption techniques safeguard user data from unauthorized access. This commitment to security and privacy underscores eVow.io's dedication to providing users with a safe and trustworthy platform for financial transactions.</p>

          <p className="lead">Conclusion:</p>
          <p className="lead">eVow.io represents a paradigm shift in the world of finance, offering users a decentralized, transparent, and efficient platform for managing financial agreements. Through ROSCAs, peer-to-peer financial agreements, and personal savings contracts, eVow.io empowers individuals to take control of their financial futures, fostering collaboration, trust, and financial stability among peers. With data storage via IPFS and encryption, eVow.io ensures the security and privacy of user data, laying the foundation for a future where finance is accessible to all.</p>

          <p className="lead">Join eVow.io today and experience the future of finance, where innovation meets integrity, and collaboration leads to prosperity.</p>

        </Col>
      </Row>
    </Container>
    </div>
  </>
  );
};

export default Whitepaper;
