import React, { createContext, useState } from 'react';

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const adminLogin = () => {
    // Perform the login process and set isLoggedIn to true if successful
    setIsAdmin(true);
  };

  const adminLogout = () => {
    // Perform the logout process and set isLoggedIn to false
    setIsAdmin(false);
  };

  return (
    <AdminContext.Provider value={{ isAdmin, adminLogin, adminLogout }}>
      {children}
    </AdminContext.Provider>
  );
};
