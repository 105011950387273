import React from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom'; // Assume React Router for navigation

const articles = [
  { id: 1, title: 'Unlock the Future of Finance with eVow.io: Personal Smart Contracts Made Easy on Polygon and Avalanche', date: '2024-10-17' },
];
const outlinedColumnStyle = {
  border: '2px solid #000000', // Adjust border style as needed
  padding: '20px', // Add padding for spacing
  borderRadius: '10px', // Add border radius for rounded corners
  margin: '6px', // Add margin on all sides
  backgroundColor: '#FEAE67',
};

const NewsList = () => (
  <>
  <Helmet>
    <title>eVow.io | Blockchain, Web3 & ML/AI News</title>
    <meta name="description" content="Stay updated with the latest news on blockchain, Web3, and ML/AI technologies through eVow.io. Explore articles, insights, and developments in the digital finance world." />
    <meta name="keywords" content="eVow, Blockchain, Web3, AI, ML, News, Articles, Polygon, Avalanche, Altcoin, L2, token" />
    <meta property="og:title" content="eVow.io - Your Source for Blockchain and Web3 News" />
    <meta property="og:description" content="Discover insightful articles on blockchain, Web3, and ML/AI news. Join eVow.io for the latest updates and expert opinions." />
    <meta property="og:image" content="https://evow.io/logo1280.png" />
    <meta property="og:url" content="https://evow.io/news" />
    <meta property="og:type" content="website" />
    <link rel="canonical" href="https://evow.io/news" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="eVow.io - Your Source for Blockchain and Web3 News" />
    <meta name="twitter:description" content="Discover insightful articles on blockchain, Web3, and ML/AI news. Follow @eVowIO for the latest updates and expert opinions." />
    <meta name="twitter:image" content="https://evow.io/evowBlue.png" />
  </Helmet>
  <Container className="mt-5">
    <Col style={outlinedColumnStyle}>
    <h1 className="text-center mb-4">eVow.io | Blockchain, Web3, ML & AI News</h1>
    <Table striped bordered hover className="styled-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        {articles.map((article) => (
          <tr key={article.id}>
            <td>{article.date}</td>
            <td>
              <Link to={`/article/${article.id}`}>{article.title}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </Col>
  </Container>
  </>
);

export default NewsList;
