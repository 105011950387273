import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import { FaArrowLeft, FaUser } from 'react-icons/fa'; // Import the desired icons from react-icons
import './App.css'; // Import CSS file for custom styles
import { SubscribedContext } from './subscribedProvider';
import OneToOneModal from './modal1T1';
import TwoToTwoModal from './modal2T2'
import ThreeToThreeModal from './modal3T3'
import FourToFourModal from './modal4T4'
import FiveToFiveModal from './modal5T5';
import SavingsModal from './modalSavings';
import CreditSixModal from './modalCredit6';
import CreditTwelveModal from './modalCredit12';
import EmploymentTier1Modal from './modalEMPT1';
import EmploymentTier2Modal from './modalEMPT2';
import EmploymentTier3Modal from './modalEMPT3';
import ThreeLastManModal from './modal3LMS';
import FourLastManModal from './modal4LMS';
import FiveLastManModal from './modal5LMS';
import SixLastManModal from './modal6LMS';
import SevenLastManModal from './modal7LMS';
import SafeRemoteSaleModal from './modalSRP';
import ProgressModal from './modalProgress';

const Subscribed = () => {
  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };
  const [activeSection, setActiveSection] = useState(null);
  const localToken = localStorage.getItem('token');
  const { isSubscribed } = useContext(SubscribedContext);
  const [showModals, setShowModals] = useState({
   OneToOneModal: false,
   TwoToTwoModal: false,
   ThreeToThreeModal: false,
   FourToFourModal: false,
   FiveToFiveModal: false,
   SavingsModal: false,
   CreditSixModal: false,
   CreditTwelveModal: false,
   EmploymentTier1Modal: false,
   EmploymentTier2Modal: false,
   EmploymentTier3Modal: false,
   ThreeLastManModal: false,
   FourLastManModal: false,
   FiveLastManModal: false,
   SixLastManModal: false,
   SevenLastManModal: false,
   SafeRemoteSaleModal: false,
   ProgressModal: false,
   // Add more modal flags here if needed
 });

 const handleToggle = (section) => {
   setActiveSection(activeSection === section ? null : section);
 };

 const handleModalClick = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: true,
   });
 };

 const handleCloseModal = (modalName) => {
   setShowModals({
     ...showModals,
     [modalName]: false,
   });
 };

 const modalComponents = [
   {
     name: 'OneToOneModal',
     component: OneToOneModal,
   },
   {
     name: 'TwoToTwoModal',
     component: TwoToTwoModal,
   },
   {
     name: 'ThreeToThreeModal',
     component: ThreeToThreeModal,
   },
   {
     name: 'FourToFourModal',
     component: FourToFourModal,
   },
   {
     name: 'FiveToFiveModal',
     component: FiveToFiveModal,
   },
   {
     name: 'SavingsModal',
     component: SavingsModal,
   },
   {
     name: 'CreditSixModal',
     component: CreditSixModal,
   },
   {
     name: 'CreditTwelveModal',
     component: CreditTwelveModal,
   },
   {
     name: 'EmploymentTier1Modal',
     component: EmploymentTier1Modal,
   },
   {
     name: 'EmploymentTier2Modal',
     component: EmploymentTier2Modal,
   },
   {
     name: 'EmploymentTier3Modal',
     component: EmploymentTier3Modal,
   },
   {
     name: 'ThreeLastManModal',
     component: ThreeLastManModal,
   },
   {
     name: 'FourLastManModal',
     component: FourLastManModal,
   },
   {
     name: 'FiveLastManModal',
     component: FiveLastManModal,
   },
   {
     name: 'SixLastManModal',
     component: SixLastManModal,
   },
   {
     name: 'SevenLastManModal',
     component: SevenLastManModal,
   },
   {
     name: 'SafeRemoteSaleModal',
     component: SafeRemoteSaleModal,
   },
   {
     name: 'ProgressModal',
     component: ProgressModal,
   },
   // Add more modal components here
 ];

  const handleOneToOneClick = () => {
    setShowModals((prevShowModals) => ({
       ...prevShowModals,
       OneToOneModal: true, // Show the OneToOneModal
     }));
  };

  const handleTwoToTwoClick = () => {
    setShowModals((prevShowModals) => ({
       ...prevShowModals,
       TwoToTwoModal: true, // Show the OneToOneModal
     }));
   };

  const handleThreeToThreeClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ThreeToThreeModal: true, // Show the oneVOne modal
    }));
  };

  const handleFourToFourClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      FourToFourModal: true, // Show the oneVOne modal
    }));
  };

  const handleFiveToFiveClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      FiveToFiveModal: true, // Show the oneVOne modal
    }));
  };

  const handleTier1Click = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      EmploymentTier1Modal: true, // Show the oneVOne modal
    }));
  };

  const handleTier2Click = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      EmploymentTier2Modal: true, // Show the oneVOne modal
    }));
  };

  const handleTier3Click = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      EmploymentTier3Modal: true, // Show the oneVOne modal
    }));  };

  const handleSecureRemotePurchase = () => {
    console.log("Coin Toss (50/50) button clicked");
  };

  const handleSavingsClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      SavingsModal: true, // Show the oneVOne modal
    }));
  };

  const handleCreditWithFriendsClick6 = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      CreditSixModal: true, // Show the oneVOne modal
    }));
  };

  const handleCreditWithFriendsClick12 = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      CreditTwelveModal: true, // Show the oneVOne modal
    }));
  };

  const handleThreeLastManClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ThreeLastManModal: true, // Show the oneVOne modal
    }));
  };

  const handleFourLastManClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      FourLastManModal: true, // Show the oneVOne modal
    }));
  };

  const handleFiveLastManClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      FiveLastManModal: true, // Show the oneVOne modal
    }));
  };

  const handleSixLastManClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      SixLastManModal: true, // Show the oneVOne modal
    }));
  };

  const handleSevenLastManClick = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      SevenLastManModal: true, // Show the oneVOne modal
    }));
  };

  const handleSafeRemoteSale = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ProgressModal: true, // Show the oneVOne modal
    }));
  };

  const handleDocStorage = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ProgressModal: true, // Show the oneVOne modal
    }));
  };

  const handleUsersDB = () => {
    setShowModals((prevShowModals) => ({
      ...prevShowModals,
      ProgressModal: true, // Show the oneVOne modal
    }));
  };

  return (
    <div>
    {modalComponents.map((modal) => (
      <modal.component
        key={modal.name}
        show={showModals[modal.name]}
        onClose={() => handleCloseModal(modal.name)}
      />
    ))}
    <Container>
      <Col style={outlinedColumnStyle}>
        <div className="navigation-container">
          <Link to="/user">
            <button className="back-button" style={{ marginRight: '10px' }}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
          </Link>
          <Link to="/user">
            <button className="profile-button">
              <FaUser style={{ fontSize: '24px'}}/> Profile
            </button>
          </Link>
        </div>
      </Col>
    </Container>
    <Container>
      <Col style={outlinedColumnStyle}>
      <ul className="toggle-list">
        <li>
          <p
            className={`toggle-header ${activeSection === 'banking' ? 'active' : ''}`}
            onClick={() => handleToggle('banking')}
          >
            Finance
          </p>
          {activeSection === 'banking' && (
            <ul className="toggle-content">
              {/* Credit with Friends (6) */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">ROSCA - 6 Participants
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleCreditWithFriendsClick6}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Credit with Friends (12) */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">ROSCA - 12 Participants
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC, or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={isSubscribed ? handleCreditWithFriendsClick12 : handleCreditWithFriendsClick6}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Savings */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Savings
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleSavingsClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>
            </ul>
          )}
        </li>

        {/* Peer */}
        <li>
          <p
            className={`toggle-header ${activeSection === 'peer' ? 'active' : ''}`}
            onClick={() => handleToggle('peer')}
          >
            Peer To Peer
          </p>
          {activeSection === 'peer' && (
            <ul className="toggle-content">
              {/* One Versus One */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">One to One
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleOneToOneClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Two Versus Two */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Two to Two
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleTwoToTwoClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Three Versus Three */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Three to Three
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleThreeToThreeClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Three Last Man*/}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Three - Last Man
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleThreeLastManClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Four Versus Four */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Four to Four
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleFourToFourClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Four Last Man*/}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Four - Last Man
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleFourLastManClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Five Versus Five */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Five to Five
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleFiveToFiveClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Five Last Man*/}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Five - Last Man
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleFiveLastManClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Six Last Man*/}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Six - Last Man
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleSixLastManClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Seven Last Man*/}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Seven - Last Man
                    <br />
                    Token: {isSubscribed ? 'WBTC, EVOW, AVAX, MATIC or USDC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleSevenLastManClick}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

            </ul>
          )}
        </li>

        {/* Hiring */}
        <li>
          <p
            className={`toggle-header ${activeSection === 'hiring' ? 'active' : ''}`}
            onClick={() => handleToggle('hiring')}
          >
            Employment
          </p>
          {activeSection === 'hiring' && (
            <ul className="toggle-content">
              {/* Tier 1 */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Tier 1
                    <br />
                    Token: {isSubscribed ? 'EVOW, AVAX, MATIC, USDC or WBTC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleTier1Click}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Tier 2 */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Tier 2
                    <br />
                    Token: {isSubscribed ? 'EVOW, AVAX, MATIC, USDC or WBTC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleTier2Click}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>

              {/* Tier 3 */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Tier 3
                    <br />
                    Token: {isSubscribed ? 'EVOW, MATIC, AVAX, USDC or WBTC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleTier3Click}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>
            </ul>
          )}
        </li>

        <li>
          <p
            className={`toggle-header ${activeSection === 'storage' ? 'active' : ''}`}
            onClick={() => handleToggle('storage')}
          >
            Record Storage
          </p>
          {activeSection === 'storage' && isSubscribed=== true && (
            <ul className="toggle-content">
              {/* Secure Remote Purchase */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Users Database
                    <br />
                    Token: MATIC
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleUsersDB}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col>
                    <p className="lead">Document Storage
                    <br />
                    Token: MATIC
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleDocStorage}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>
            </ul>
          )}
        </li>

        {/* Other */}
        <li>
          <p
            className={`toggle-header ${activeSection === 'other' ? 'active' : ''}`}
            onClick={() => handleToggle('other')}
          >
            Other
          </p>
          {activeSection === 'other' && (
            <ul className="toggle-content">
              {/* Secure Remote Purchase */}
              <li>
                <Row>
                  <Col>
                    <p className="lead">Secure Remote Sale
                    <br />
                    Token: {isSubscribed ? 'AVAX, MATIC, USDC, EVOW or WBTC' : 'EVOW'}
                    </p>
                  </Col>
                  <Col xs={12} sm={2} className="text-center text-sm-right">
                    <button className="btn btn-primary btn-sm" onClick={handleSafeRemoteSale}>
                      Get Started
                    </button>
                  </Col>
                </Row>
              </li>
            </ul>
          )}
        </li>
      </ul>
      </Col>
    </Container>
    </div>
  );
};

export default Subscribed;
