import React, { useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import Web3 from 'web3';
import { standardERC20ABI } from './abiConfig';
import { threeMonthPrice, sixMonthPrice, oneYearPrice, siteTokenMATIC, masterControllerABI, masterControllerAddress } from './abiConfig'
import { platformSubscribe, polygonMainUrl } from './urlConfig';
import { chainPrompt } from './chainPrompt';

const SubscriptionModal = ( { show, onClose } ) => {
  const [step, setStep] = useState(1);
  const [successText, setSuccessText] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState('oneYear');
  const walletAddress = localStorage.getItem('walletAddress');
  const providerType = localStorage.getItem('provider');
  const localToken = localStorage.getItem('token');

  const handleClose = () => {
    // Reset the step back to 1 when the modal is closed
    setStep(1);
    onClose(); // Call the onClose callback
  };

  const handleSubscriptionChange = (event) => {
    setSelectedSubscription(event.target.value);
  };

  const handleSubscribe = async () => {
    setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nWaiting on EVOW token approval from user...`);

    const switchChain = await chainPrompt("Polygon", providerType);

    let provider;

    if (providerType === 'coinbase') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      } catch(e){
        provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
      }
    }

    if (providerType === 'metamask') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      } catch(e){
        provider = window.ethereum.isMetaMask ? window.ethereum : null;
      }
    }

    if (providerType === 'walletConnect') {

      try{
        provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
      } catch(e){
        provider = window.ethereum.isWalletConnect ? window.ethereum : null;
      }
    }

    const accounts = await provider.request({ method: 'eth_requestAccounts' });
    const siteAddress = accounts[0]

    if (siteAddress !== walletAddress){
      throw new Error('Wallet addresses do not match');
    }

    setStep(2)
    const web3 = new Web3(polygonMainUrl);
    const tokenContract = new web3.eth.Contract(standardERC20ABI, siteTokenMATIC);
    const masterInstance = new web3.eth.Contract(masterControllerABI, masterControllerAddress);
    let message;
    try {

      // Retrieve the _amount input from inputValues
      let _amount = oneYearPrice;
      let span = 0;

      if (selectedSubscription === 'threeMonth'){
        _amount = threeMonthPrice;
        span = 3
      }
      else if (selectedSubscription === 'sixMonth'){
        _amount = sixMonthPrice;
        span = 6
      }
      else if (selectedSubscription === 'oneYear'){
        _amount = oneYearPrice;
        span = 12
      }

      const weiValue = web3.utils.toWei(_amount.toString()); // Equivalent to 'ether'

      const sendData = tokenContract.methods.approve(masterControllerAddress, weiValue).encodeABI();

      const approvalTxObj = {
        from: walletAddress,
        to: tokenContract.options.address,
        data: sendData,
      };

      let gasEstimate = await web3.eth.estimateGas(approvalTxObj);
      let gasPrice = await web3.eth.getGasPrice();

      let gasFee = gasEstimate * 2

      // Now, you can construct and send your transaction with the calculated gas values
      const newApprovalTransaction = {
        ...approvalTxObj,
        gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
        gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
      };

      setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nBuilding transaction 1/2 - Approval`);

      //Send to user via eth_sendTransaction request with parameters
      const approvalTxHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [approvalTxObj]
      });

      setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nBuilding transaction 1/2 - Approval\n\nWaiting on approval receipt`);

      // Poll every 2 seconds for the transaction receipt
      const checkInterval = 3000; // 2 seconds
      let receipt = null;
      while (receipt === null) {
        await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
          // Check for the receipt
        receipt = await web3.eth.getTransactionReceipt(approvalTxHash);
      }

      //setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly.\n\nEVOW Token Approval Tx: ${approvalTxHash} \n\nUser approval for subscription contract...`);

      setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nBuilding transaction 1/2 - Approval\n\nWaiting on approval receipt\n\nBuilding transaction 2/2 - Transfer`);

      const masterSendData = masterInstance.methods.Subscribe(weiValue).encodeABI();

      const masterTxObject = {
        from: walletAddress, // Set the sender's address
        to: masterControllerAddress, // Contract address
        data: masterSendData,
      };

      gasEstimate = await web3.eth.estimateGas(masterTxObject);
      gasFee = gasEstimate * 2

      const newMasterTransaction = {
        ...masterTxObject,
        gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
        gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
      };

      // Send transaction
      //Send to user via eth_sendTransaction request with parameters
      const masterTxHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [masterTxObject]
      });

      setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nBuilding transaction 1/2 - Approval\nWaiting on approval receipt\nBuilding transaction 2/2 - Transfer\nEVOW Token Approval Tx: ${approvalTxHash} \n\nSubscription Approval Tx: ${masterTxHash} \n\nContacting Server...`);

      const requestBody = JSON.stringify({ address: walletAddress, months: span});

      const response = await fetch(platformSubscribe, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localToken}`,
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });

      // Parse the JSON response
      const responseData = await response.json();

      message = responseData.messaage
      setSuccessText(`Please do NOT leave the page. \n\nBlockchain gas estimates and transaction building. You will see a transaction pop up shortly. \n\nBuilding transaction 1/2 - Approval\nWaiting on approval receipt\nBuilding transaction 2/2 - Transfer\nEVOW Token Approval Tx: ${approvalTxHash} \n\nSubscription Approval Tx: ${masterTxHash} \n\nContacting Server...\n\nSuccess. Thank you. When you log off and log back in, you will see the changes. Please note the tx hash for any issues.`);
  } catch (error) {
      // Handle error occurred during wallet connection or API request
      // Implement your own error handling logic here
      setSuccessText(`Error: ${error}`)
  }
};

  return(
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Subscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {step === 1  &&(
      <div className="custom-modal-body">
        <Row>
          <Col>
            <p>Subscriptions are paid in EVOW token on Polygon PoS blockchain. Please see our tokenomics section for how funds are used/distributed.</p>
            <p>
              <label>
                <input
                  type="radio"
                  name="subscription"
                  value="threeMonth"
                  checked={selectedSubscription === "threeMonth"}
                  onChange={handleSubscriptionChange}
                />
                Three Month Subscription: 175 EVOW
              </label>

              <br />

              <label>
                <input
                  type="radio"
                  name="subscription"
                  value="sixMonth"
                  checked={selectedSubscription === "sixMonth"}
                  onChange={handleSubscriptionChange}
                />
                Six Month Subscription: 275 EVOW
              </label>

              <br />

              <label>
                <input
                  type="radio"
                  name="subscription"
                  value="oneYear"
                  checked={selectedSubscription === "oneYear"}
                  onChange={handleSubscriptionChange}
                />
                One Year Subscription: 500 EVOW
              </label>
            </p>
            <p>Note: Pricing is subject to change.</p>
          </Col>
        </Row>
        </div>
      )}

      {step === 2 &&(
        <Row>
          <Col>
            <p style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
              {successText}
            </p>
          </Col>
        </Row>
      )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        { step ===1 && (
        <Button variant="primary" onClick={handleSubscribe}>
          Subscribe
        </Button>
      )}
      </Modal.Footer>
    </Modal>
);

};

export default SubscriptionModal;
