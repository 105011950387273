import {
  mevABI,
  mevBytecode,
  oneVoneABI,
  fiveVfiveABI,
  savingsMultiABI,
  savingsEvowABI,
  creditFriendsAvaxSixABI,
  creditFriendsUscSixABI,
  creditFriendsEvowSixABI,
  creditFriendsSwthSixABI,
  creditFriendsWbtcSixABI,
  creditFriendsAvaxTwelveABI,
  creditFriendsUscTwelveABI,
  creditFriendsEvowTwelveABI,
  creditFriendsSwthTwelveABI,
  creditFriendsWbtcTwelveABI,
  empUsc1ABI,
  empEvow1ABI,
  empSwth1ABI,
  empWbtc1ABI,
  empUsc2ABI,
  empEvow2ABI,
  empSwth2ABI,
  empWbtc2ABI,
  empUsc3ABI,
  empEvow3ABI,
  empSwth3ABI,
  empWbtc3ABI,
  pp1EvowABI,
  pp1SwthABI,
  pp1UscABI,
  pp1WbtcABI,
  pp2EvowABI,
  pp2SwthABI,
  pp2UscABI,
  pp2WbtcABI,
  pp3EvowABI,
  pp3SwthABI,
  pp3UscABI,
  pp3WbtcABI,
  pp4EvowABI,
  pp4SwthABI,
  pp4UscABI,
  pp4WbtcABI,
  pp5EvowABI,
  pp5SwthABI,
  pp5UscABI,
  pp5WbtcABI,
  LSM3AvaxABI,
  LSM3EvowABI,
  LSM3WbtcABI,
  LSM3UscABI,
  LSM3SwthABI,
  LSM4AvaxABI,
  LSM4EvowABI,
  LSM4WbtcABI,
  LSM4UscABI,
  LSM4SwthABI,
  LSM5AvaxABI,
  LSM5EvowABI,
  LSM5WbtcABI,
  LSM5UscABI,
  LSM5SwthABI,
  LSM6AvaxABI,
  LSM6EvowABI,
  LSM6WbtcABI,
  LSM6UscABI,
  LSM6SwthABI,
  LSM7AvaxABI,
  LSM7EvowABI,
  LSM7WbtcABI,
  LSM7UscABI,
  LSM7SwthABI,
  pp1AvaxABI,
  pp1EvowABI_Matic,
  pp1MaticABI,
  pp1UsdcABI,
  pp1WbtcABI_Matic,
  pp2AvaxABI,
  pp2EvowABI_Matic,
  pp2MaticABI,
  pp2UsdcABI,
  pp2WbtcABI_Matic,
  pp3AvaxABI,
  pp3EvowABI_Matic,
  pp3MaticABI,
  pp3UsdcABI,
  pp3WbtcABI_Matic,
  pp4AvaxABI,
  pp4EvowABI_Matic,
  pp4MaticABI,
  pp4UsdcABI,
  pp4WbtcABI_Matic,
  pp5AvaxABI,
  pp5EvowABI_Matic,
  pp5MaticABI,
  pp5UsdcABI,
  pp5WbtcABI_Matic,
  LSM3EvowABI_Matic,
  LSM3MaticABI,
  LSM3UsdcABI,
  LSM3WbtcABI_Matic,
  LSM4EvowABI_Matic,
  LSM4MaticABI,
  LSM4UsdcABI,
  LSM4WbtcABI_Matic,
  LSM5EvowABI_Matic,
  LSM5MaticABI,
  LSM5UsdcABI,
  LSM5WbtcABI_Matic,
  LSM6EvowABI_Matic,
  LSM6MaticABI,
  LSM6UsdcABI,
  LSM6WbtcABI_Matic,
  LSM7EvowABI_Matic,
  LSM7MaticABI,
  LSM7UsdcABI,
  LSM7WbtcABI_Matic,
  creditFriendsMATICUsdcSixABI,
  creditFriendsMATICEvowSixABI,
  creditFriendsMATICSixABI,
  creditFriendsMATICWbtcSixABI,
  creditFriendsMATICUsdcTwelveABI,
  creditFriendsMATICEvowTwelveABI,
  creditFriendsMATICTwelveABI,
  creditFriendsMATICWbtcTwelveABI,
  empEvow1ABIMatic,
  empUSDC1ABI,
  empMATIC1ABI,
  empWbtc1ABIMatic,
  empEvow2ABIMatic,
  empUSDC2ABI,
  empMATIC2ABI,
  empWbtc2ABIMatic,
  empEvow3ABIMatic,
  empUSDC3ABI,
  empMATIC3ABI,
  empWbtc3ABIMatic,
  savingsMultiABIMatic,
  savingsEvowABIMatic,
  empAvax1ABI,
  empAvax2ABI,
  empAvax3ABI

} from './abiConfig';

const abiMapping = {
  oneVoneABI: oneVoneABI,
  fiveVfiveABI: fiveVfiveABI,
  savingsMultiABI: savingsMultiABI,
  savingsEvowABI: savingsEvowABI,
  creditFriendsUscSixABI: creditFriendsUscSixABI,
  creditFriendsEvowSixABI: creditFriendsEvowSixABI,
  creditFriendsSwthSixABI: creditFriendsSwthSixABI,
  creditFriendsWbtcSixABI: creditFriendsWbtcSixABI,
  creditFriendsUscTwelveABI: creditFriendsUscTwelveABI,
  creditFriendsEvowTwelveABI: creditFriendsEvowTwelveABI,
  creditFriendsSwthTwelveABI: creditFriendsSwthTwelveABI,
  creditFriendsWbtcTwelveABI: creditFriendsWbtcTwelveABI,
  empUsc1ABI: empUsc1ABI,
  empEvow1ABI: empEvow1ABI,
  empSwth1ABI: empSwth1ABI,
  empWbtc1ABI: empWbtc1ABI,
  empUsc2ABI: empUsc2ABI,
  empEvow2ABI: empEvow2ABI,
  empSwth2ABI: empSwth2ABI,
  empWbtc2ABI: empWbtc2ABI,
  empUsc3ABI: empUsc3ABI,
  empEvow3ABI: empEvow3ABI,
  empSwth3ABI: empSwth3ABI,
  empWbtc3ABI: empWbtc3ABI,
  pp1AvaxABI: pp1AvaxABI,
  pp1EvowABI: pp1EvowABI,
  pp1UscABI: pp1UscABI,
  pp1SwthABI: pp1SwthABI,
  pp1WbtcABI: pp1WbtcABI,
  pp2AvaxABI: pp2AvaxABI,
  pp2EvowABI: pp2EvowABI,
  pp2UscABI: pp2UscABI,
  pp2SwthABI: pp2SwthABI,
  pp2WbtcABI: pp2WbtcABI,
  pp3AvaxABI: pp3AvaxABI,
  pp3EvowABI: pp3EvowABI,
  pp3UscABI: pp3UscABI,
  pp3SwthABI: pp3SwthABI,
  pp3WbtcABI: pp3WbtcABI,
  pp4AvaxABI: pp4AvaxABI,
  pp4EvowABI: pp4EvowABI,
  pp4UscABI: pp4UscABI,
  pp4SwthABI: pp4SwthABI,
  pp4WbtcABI: pp4WbtcABI,
  pp5AvaxABI: pp5AvaxABI,
  pp5EvowABI: pp5EvowABI,
  pp5UscABI: pp5UscABI,
  pp5SwthABI: pp5SwthABI,
  pp5WbtcABI: pp5WbtcABI,
  LSM3EvowABI: LSM3EvowABI,
  LSM3UscABI: LSM3UscABI,
  LSM3WbtcABI: LSM3WbtcABI,
  LSM3SwthABI: LSM3SwthABI,
  LSM4EvowABI: LSM4EvowABI,
  LSM4UscABI: LSM4UscABI,
  LSM4WbtcABI: LSM4WbtcABI,
  LSM4SwthABI: LSM4SwthABI,
  LSM5EvowABI: LSM5EvowABI,
  LSM5UscABI: LSM5UscABI,
  LSM5WbtcABI: LSM5WbtcABI,
  LSM5SwthABI: LSM5SwthABI,
  LSM6EvowABI: LSM6EvowABI,
  LSM6UscABI: LSM6UscABI,
  LSM6WbtcABI: LSM6WbtcABI,
  LSM6SwthABI: LSM6SwthABI,
  LSM7EvowABI: LSM7EvowABI,
  LSM7UscABI: LSM7UscABI,
  LSM7WbtcABI: LSM7WbtcABI,
  LSM7SwthABI: LSM7SwthABI,
  pp1EvowABI_Matic: pp1EvowABI_Matic,
  pp1MaticABI: pp1MaticABI,
  pp1UsdcABI: pp1UsdcABI,
  pp1WbtcABI_Matic: pp1WbtcABI_Matic,
  pp2EvowABI_Matic: pp2EvowABI_Matic,
  pp2MaticABI: pp2MaticABI,
  pp2UsdcABI: pp2UsdcABI,
  pp2WbtcABI_Matic: pp2WbtcABI_Matic,
  pp3EvowABI_Matic: pp3EvowABI_Matic,
  pp3MaticABI: pp3MaticABI,
  pp3UsdcABI: pp3UsdcABI,
  pp3WbtcABI_Matic: pp3WbtcABI_Matic,
  pp4EvowABI_Matic: pp4EvowABI_Matic,
  pp4MaticABI: pp4MaticABI,
  pp4UsdcABI: pp4UsdcABI,
  pp4WbtcABI_Matic: pp4WbtcABI_Matic,
  pp5EvowABI_Matic: pp5EvowABI_Matic,
  pp5MaticABI: pp5MaticABI,
  pp5UsdcABI: pp5UsdcABI,
  pp5WbtcABI_Matic: pp5WbtcABI_Matic,
  LSM3AvaxABI: LSM3AvaxABI,
  LSM3EvowABI_Matic: LSM3EvowABI_Matic,
  LSM3MaticABI: LSM3MaticABI,
  LSM3UsdcABI: LSM3UsdcABI,
  LSM3WbtcABI_Matic: LSM3WbtcABI_Matic,
  LSM4AvaxABI: LSM4AvaxABI,
  LSM4EvowABI_Matic: LSM4EvowABI_Matic,
  LSM4MaticABI: LSM4MaticABI,
  LSM4UsdcABI: LSM4UsdcABI,
  LSM4WbtcABI_Matic: LSM4WbtcABI_Matic,
  LSM5AvaxABI: LSM5AvaxABI,
  LSM5EvowABI_Matic: LSM5EvowABI_Matic,
  LSM5MaticABI: LSM5MaticABI,
  LSM5UsdcABI: LSM5UsdcABI,
  LSM5WbtcABI_Matic: LSM5WbtcABI_Matic,
  LSM6AvaxABI: LSM6AvaxABI,
  LSM6EvowABI_Matic: LSM6EvowABI_Matic,
  LSM6MaticABI: LSM6MaticABI,
  LSM6UsdcABI: LSM6UsdcABI,
  LSM6WbtcABI_Matic: LSM6WbtcABI,
  LSM7AvaxABI: LSM7AvaxABI,
  LSM7EvowABI_Matic: LSM7EvowABI,
  LSM7MaticABI: LSM7MaticABI,
  LSM7UsdcABI: LSM7UsdcABI,
  LSM7WbtcABI_Matic: LSM7WbtcABI_Matic,
  creditFriendsAvaxSixABI: creditFriendsAvaxSixABI,
  creditFriendsMATICUsdcSixABI: creditFriendsMATICUsdcSixABI,
  creditFriendsMATICEvowSixABI: creditFriendsMATICEvowSixABI,
  creditFriendsMATICSixABI: creditFriendsMATICSixABI,
  creditFriendsMATICWbtcSixABI: creditFriendsMATICWbtcSixABI,
  creditFriendsAvaxTwelveABI: creditFriendsAvaxTwelveABI,
  creditFriendsMATICUsdcTwelveABI: creditFriendsMATICUsdcTwelveABI,
  creditFriendsMATICEvowTwelveABI: creditFriendsMATICEvowTwelveABI,
  creditFriendsMATICTwelveABI: creditFriendsMATICTwelveABI,
  creditFriendsMATICWbtcTwelveABI: creditFriendsMATICWbtcTwelveABI,
  empEvow1ABIMatic: empEvow1ABIMatic,
  empUSDC1ABI: empUSDC1ABI,
  empMATIC1ABI: empMATIC1ABI,
  empWbtc1ABIMatic: empWbtc1ABI,
  empEvow2ABIMatic: empEvow2ABI,
  empUSDC2ABI: empUSDC2ABI,
  empMATIC2ABI: empMATIC2ABI,
  empWbtc2ABIMatic: empWbtc2ABIMatic,
  empEvow3ABIMatic: empEvow3ABIMatic,
  empUSDC3ABI: empUSDC3ABI,
  empMATIC3ABI: empMATIC3ABI,
  empWbtc3ABIMatic: empWbtc3ABIMatic,
  savingsMultiABIMatic: savingsMultiABIMatic,
  savingsEvowABIMatic: savingsEvowABIMatic,
  empAvax1ABI: empAvax1ABI,
  empAvax2ABI: empAvax2ABI,
  empAvax3ABI: empAvax3ABI,
  mevABI: mevABI,
  mevBytecode: mevBytecode
  // Add other ABIs to the mapping
};

export { abiMapping };
