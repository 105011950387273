const learnROSCA = 'Rotating Savings and Credit Association\'s or money pools most often involve a group of family members, coworkers, or close friends who agree to pool their money equally through monthly (or regular) contributions to a fund. The amount agreed upon might be small, say $50 per month, or large, like $200 every two weeks, for a fixed period of time. \n\nThe fund manager then distributes the pot, or lump sum, to a different member of the ROSCA every month until the pool is depleted. If 12 people put $1,000 in, 12 people get $1,000 back, with zero interest paid or earned.'

const learnROSCAContract = 'Each user contributes the designated amount of tokens to the contract for each period. Six users contribute six individual periods or twelve users contribute twelve individual periods. A contribution period is designated independently by the peer group (Ex: day, week, month, year). After the last peer has contributed for the period, the first peer entered in the list (that has not already received a payout) will receive the pot of contributions for the period. \n\nEach peer will receive a payout from the contract for a single period.\nEach peer must contribute the designated amount each period.\n\nNote: This is a personal contract meant to be implemented with friends, family and close peers. It allows for a loan/credit/saving opportunity for those closest to us.';

const learnSavings = 'Users that have trouble saving tokens can lock them up in their own savings contract. A savings contract will accept two addresses that can deposit to the contract. Users can also decide upon a lockup period for the tokens; which is input as an amount of blocks before the contract will release the funds. Unsubscribed members can deploy contracts for EVOW. Subscribed members also have access to WBTC, USDC, MATIC, AVAX and EVOW.';

const learnPeer2Peer = 'Peer to Peer financial agreements are meant for two sets of peer groups to equally deposit into the contract. After all deposits have been received, the contract owner can input a score to decide which peer group receives the collected funds. An intervention mechanism is input to asssit in any disputes. Each lead peer member (first address in the list) can dispute the score within approximately fifteen minutes (450 blocks at approximately 2 seconds per block processing time) of score submission. If the score is not disputed, the owner can distribute the funds after the allotted dispute period has ended. If an intervention is required, the intermediary will receive a 10% fee from the pot. The intermediary can utilize the Intervention function to give the breakout percentages for each team (Ex: 45,45).';

const learnEmployment = 'Tiered hiring contracts are meant to assist in contracted employment. The contract owner deploys the contract by choosing the contract address, amount to be paid and the percentage breakdown for each tier (Tier one would be 100%). After each tier has been completed, funds are released to the contractor. An intermediary mechanism has also been input to assist in any disputes.';

const learnUsersStorage = 'The Users Database contract is designed to assist in creating a blockchain database.';

const learnRemotePurchase = 'Safe remote purchase contracts are funded by the seller at 2x the purchase price; which is treated as a safety deposit. Once a buyer deposits total funds for purchase, the item is in a non-purchase state until the seller registers shipment. Shipment notification triggers a percentrage release of funds to seller. An amount of blocks is set at contract initiation that decides the wait time for disputing the transaction. Once this time has expired, the seller is given access to the remaining funds. ';

const learnFees = 'User do not pay to post or otherwise utilize the platform other than blockchain associated fees. \n\nUsers can pay a subscription fee in EVOW (See tokenomics) to access more robust smart contracts. A subscription will also allow the user unlimited friends.';

const learnMediatorFees = 'Mediator addresses are input by the contract owner at the time of initiation. Contract participants should research the contract details prior to interacting with it. Certain contracts (Ex: Safe Remote Purchase) may be better suited to allow a independent third party to address issues. We strongly suggest researching and using a third party for such cases. We will be looking to provide more information in the near future. \n\nIf a contract utilizes the services of a mediator, 10% of the contract value will be transferred to the mediator for their time and services.';

const learnDelete = 'Yes. You can delete a contract from our platform. Deleting a contract from the eVow.io platform does not delete the contract from the blockchain. When a user deploys a smart contract on blockchain, the contract is permanent. After a contract has been fully utilized, it will revert to an inert state; which will not allow for any more interaction. \n\nA user can choose to delete a contract from our platform for any number of reasons.'

const learnCCIP = "A burn and mint CCIP (Cross Chain Interoperability Protocol) bridge allows the user to burn an EVOW token from one blockchain and mint one on another chain. Subscribed users burn EVOW tokens on one chain and mint them for usage on another. The feature is available to subscribed members. Our master controller smart contract is based in Polygon, so all subscriptions must be paid in Polygon EVOW token. Because we're using a CCIP burn and mint, the total amount of EVOW will never be inflated. This was completed for greater transparency and value to our token holders."

module.exports = {
  learnROSCA,
  learnROSCAContract,
  learnSavings,
  learnPeer2Peer,
  learnEmployment,
  learnUsersStorage,
  learnFees,
  learnMediatorFees,
  learnDelete,
  learnCCIP,
};
