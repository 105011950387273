import React, { useEffect, useState} from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import { useNavigate } from 'react-router';
import { userModalURL, userUpdateURL} from './urlConfig';

const UserUpdateModal = ( { show, onClose } ) => {
  const [informedText, setInformedText] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState({twitter: "", telegram: "", playstation: "", xbox: ""});
  const [loading, setLoading] = useState(true); // Add loading state

  const handleTelegramChange = (event) => {
    const updatedUser = { ...user, telegram: event.target.value };
    setUser(updatedUser);
  };

  const handleTwitterChange = (event) => {
    const newTwitterValue = event.target.value;
    const updatedUser = { ...user, twitter: newTwitterValue };
    setUser(updatedUser);
  };

  const handleXboxChange = (event) => {
    const newXboxValue = event.target.value;
    const updatedUser = { ...user, xbox: newXboxValue };
    setUser(updatedUser);
  };

  const handlePlaystationChange = (event) => {
    const newPlaystationValue = event.target.value;
    const updatedUser = { ...user, playstation: newPlaystationValue };
    setUser(updatedUser);
  };

  const gatherData = async () => {
    const localToken = localStorage.getItem('token');

    const response = await fetch(userModalURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localToken }),
    });

    if (response.ok) {
      // Handle successful response from the API
      // Implement your own logic here
      const res = await response.json();
      setUser(res);
      setLoading(false);
    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
    }
  };

  const submitData = async () => {
    const localToken = localStorage.getItem('token');

    const response = await fetch(userUpdateURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
                            telegram: user.telegram,
                            twitter: user.twitter,
                            xbox: user.xbox,
                            playstation: user.playstation
       }),
    });

    if (response.ok) {
      setInformedText("Success. You will need to log off and back in to see the changes.")
    } else if (response.status === 404) {
      // Redirect to User Registration URL
      console.log('ERROR 404');
    }
    else{
      console.log("ERROR ELSE");
    }
  };


  const handleSubmit = async () => {
    try {
      submitData();
    } catch (error) {
      // Handle error occurred during wallet connection or API request
      // Implement your own error handling logic here
      console.log(error);
    }
  };

  useEffect(() => {

    // Update the state with the extracted data
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      // Token exists, set it in the state
      gatherData();
    }
  }, []);

  if (loading) {
    return <br />; // Show loading message or spinner
  }


  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Profile Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        <Row>
          <Col>
            <table>
              <tbody>
                <tr>
                  <td>Telegram:</td>
                  <td>
                    <input
                      type="text"
                      name="telegramText"
                      value={user.telegram}
                      onChange={handleTelegramChange}
                      className="custom-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Twitter:</td>
                  <td>
                    <input
                      type="text"
                      name="twitterText"
                      value={user.twitter}
                      onChange={handleTwitterChange}
                      className="custom-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Xbox:</td>
                  <td>
                    <input
                      type="text"
                      name="xboxText"
                      value={user.xbox}
                      onChange={handleXboxChange}
                      className="custom-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Playstation:</td>
                  <td>
                    <input
                      type="text"
                      name="playstationText"
                      value={user.playstation}
                      onChange={handlePlaystationChange}
                      className="custom-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <p>

              You may need to log back in for the changes to take effect.

            </p>
          </Col>
        </Row>
      </div>
      </Modal.Body>
      <Modal.Footer>
        {informedText}
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
);

};

export default UserUpdateModal;
