import React, { useState} from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import './App.css'; // Import CSS file for custom styles
import { useNavigate } from 'react-router';
import Web3 from 'web3';
import forge from 'node-forge';
import { messagingAddress, messagingABI, messagingBytecode } from './abiConfig';
import { carbon_main_url, carbon_test_url, polygonMainUrl, polygonTestUrl, searchRSA  } from './urlConfig';

const PostMessageModal = ( { show, onClose } ) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1);
  const [isRsa, setIsRsa] = useState('False');
  const [informedText, setInformedText] = useState('');
  const [rsa, setRsa] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [sendAddress, setSendAddress] = useState('');
  const walletAddress = localStorage.getItem("walletAddress");
  const localToken = localStorage.getItem('token');

  const requestKeys = async() => {

    //Server call to store contract, notify participants and add to interactions
    const response = await fetch(searchRSA, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "searchAddress": sendAddress}),
    });

    const data = await response.json();

    if (data.isRSA=== "True" || data.isRSA === true){
      setIsRsa(data.isRSA);
      setRsa(data.rsa);
    }

  };

  const handleSearchStep = async() => {
    await requestKeys();
    setStep(2)
  };

  const postMessage = async() => {

    setInformedText("Build Transaction 1/2");

    const web3 = new Web3(polygonTestUrl);
    let publicKey;
    let encryptedData;
    let hexEncrypted;

      try {

        const transferObject = {message: message};

        // Create a new object with cleaned data
        const data = JSON.stringify(transferObject);

        if (isRsa === "True" || isRsa === true){
          // Convert PEM format to public key
          publicKey = forge.pki.publicKeyFromPem(rsa);

          // Encrypt the data using the public key
          encryptedData = publicKey.encrypt(data, 'RSA-OAEP', {
            md: forge.md.sha256.create()
          });

          hexEncrypted = '0x' + forge.util.bytesToHex(encryptedData);
        }

        if (isRsa === "False" || isRsa === false){
          hexEncrypted= web3.utils.asciiToHex(message);
        }

        // Contract instance
        const contract = new web3.eth.Contract(messagingABI, messagingAddress);

        //Get account nonce
        let nonce = await web3.eth.getTransactionCount(walletAddress);

        // Build Transaction Parameters
        const txParameters = {
          from: walletAddress,
          to: sendAddress,
          data: hexEncrypted,
          nonce: web3.utils.toHex(nonce),
        };

        //Gas Calculations
        let gasEstimate = await web3.eth.estimateGas(txParameters);
        let gasPrice = await web3.eth.getGasPrice();
        let gasFee = gasEstimate * 2

        //Completed tx object
        const txObject = {
          ...txParameters,
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)),
        };

        setInformedText("User Signature 1/2");

        //Send to user via eth_sendTransaction request with parameters
        const transactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [txObject]
        });

        setInformedText("Awaiting Receipt 1/2");

        // Poll every 3 seconds for the transaction receipt
        const checkInterval = 3000; // 3 seconds
        let receipt = null;
        while (receipt === null) {
          await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 3 seconds
            // Check for the receipt
          receipt = await web3.eth.getTransactionReceipt(transactionHash);
        }

        setInformedText("Build Transaction 2/2");

        //Get account nonce
        nonce = await web3.eth.getTransactionCount(walletAddress);

        const logData = contract.methods.LogMessage(sendAddress, transactionHash).encodeABI();

        // Build Transaction Parameters
        const logTxParameters = {
          from: walletAddress,
          to: messagingAddress,
          data: logData,
        };

        //Gas Calculations
        gasEstimate = await web3.eth.estimateGas(logTxParameters);
        gasPrice = await web3.eth.getGasPrice();
        gasFee = gasEstimate * 2

        //Completed tx object
        const logTxObject = {
          ...logTxParameters,
          gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
          gas: web3.utils.toHex(web3.utils.toBN(gasFee)),
        };

        setInformedText("User Signature 2/2");

        //Send to user via eth_sendTransaction request with parameters
        const logTransactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [logTxObject]
        });

        setInformedText("Awaiting Receipt 2/2");

        // Poll every 3 seconds for the transaction receipt
        let logReceipt = null;
        while (logReceipt === null) {
          await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 3 seconds
            // Check for the receipt
          logReceipt = await web3.eth.getTransactionReceipt(logTransactionHash);
        }

        setConfirmation(logTransactionHash);
        setStep(3)

      } catch (error) {
          console.error('Error retrieving events:', error);
          throw error;
      }
  };

  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton style={{color: 'black'}}>
        <Modal.Title style={{color: 'black'}}>Send Messagge</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="custom-modal-body">
        {step === 1  && (

          <Row>
            <Col>
              <p>A search will be performed for a public RSA key of the intended recepient. If they do not possess a public key, the message will be unencrypted; which means it will be viewable to everyone. </p>
                <Form.Control type="text" value={sendAddress} onChange={(e) => setSendAddress(e.target.value)} />
                <br/>
                <Button variant="primary" onClick={handleSearchStep}>Search</Button>
            </Col>
          </Row>
        )}

        {step === 2  && (

          <Row>
            <Col>
            <p>
              {sendAddress} Public RSA Status: <span style={{ color: isRsa === 'True' ? 'green' : 'red' }}>{isRsa}</span>
            </p>              <br/>
              <p>If you wish to proceed, please enter your message:</p>
                <div>
                  <label htmlFor="messageInput">Message (Max: 500 characters):</label>
                  <textarea
                    id="messageInput"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    maxLength={500}
                    rows={5}
                    cols={50}
                    required
                  />
                </div>
                <Button variant="primary" onClick={postMessage}>Send</Button>
                <br/>
                {informedText}
            </Col>
          </Row>
        )}

        {step === 3  && (

          <Row>
            <Col>
              <p style={{ wordWrap: 'break-word' }}>Transaction Notification Hash: {confirmation}</p>
              <p>Your message has been sent and logged.</p>
            </Col>
          </Row>
        )}
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
);

};

export default PostMessageModal;
