export async function chainPrompt(chain, selectedWallet) {
  const walletInfo = {
    chainId: '',
    chainName: '',
    rpcUrls: [],
    blockExplorerUrls: [],
  };

  if (chain === 'Avalanche') {
    walletInfo.chainId = '0xA86A';
    walletInfo.chainName = 'Avalanche C-Chain Main Network';
    walletInfo.rpcUrls = ['https://api.avax.network/ext/bc/C/rpc'];
    walletInfo.blockExplorerUrls = ['https://snowtrace.io/'];
  } else if (chain === 'Polygon') {
    // Add prompts or logic for Polygon Mumbai testnet setup
    // For now, use default values, replace with actual values later
    walletInfo.chainId = '0x89'; // Polygon 
    walletInfo.chainName = 'Polygon Main Network';
    walletInfo.rpcUrls = ['https://polygon-rpc.com/'];
    walletInfo.blockExplorerUrls = ['https://polygonscan.com/'];
  }

  let promptUserToInstall = false;
  let provider;

  if (selectedWallet === 'coinbase') {
    try{
      provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
    } catch(e){
      provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
    }
  }

  if (selectedWallet === 'metamask') {
    try{
      provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
    } catch(e){
      provider = window.ethereum.isMetaMask ? window.ethereum : null;
    }
  }

  if (selectedWallet === 'walletConnect') {

    try{
      provider = window.ethereum.providers.find((provider) => provider.isWalletConnect);
    } catch(e){
      provider = window.ethereum.isWalletConnect ? window.ethereum : null;
    }
  }

  const chainId = await provider.request({ method: 'eth_chainId' });

  if (parseInt(chainId, 16) !== parseInt(walletInfo.chainId, 16)) {
    try {
      // Attempt to switch to the desired chain
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: walletInfo.chainId }],
      });
    } catch (error) {
      // If switching fails, set the flag to prompt the user
      promptUserToInstall = true;
    }
  }

  // Prompt the user outside the catch block based on the flag
  if (promptUserToInstall) {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [walletInfo],
    });
  }

  const accounts = await provider.request({ method: 'eth_requestAccounts' });
  const walletAddress = accounts[0];

  return walletAddress;
}
