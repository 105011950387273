
// importContractData.js
const contractData = require('./contracts.json');

//0x263e = Mainnet
const chainId = '0x263f';

//SWTH Carbon Protocol Pricing
const threeMonthPrice = 175;
const sixMonthPrice = 275;
const oneYearPrice = 500;

//Site contract & Master controller contract
const siteToken = '0xa00eE52c57d74b5bffD0A11d0c5C88Be98cf19B2';
const siteTokenMATIC = '0x37ee2103ab96E66af0E587D18B803C4B58E8cE0e';
const masterControllerAddress = '0x5B09c50b86cD0EEb4c613f2806E5BbcDa1ca796A';
const masterControllerFixAddress = '0x06197723cC1c2CB474000bf2453E4593E6559bD7';
//const mevAddress = '0x142F2eC8e814a445996399A6EAe93FAA14a9043e';
const mevAddress = '0xaAF21F2b9548BfF914d14db66232C41c4d5d7653';

//Messaging Deployment
const messagingAddress = '0x50a95501CFbD5c45902CC737b21A39D2ad00Ee30';
const messagingBlock = '45039107';

//ERC20 Token Addresses
//Addresses from Avalanche Protocol Chain
//WBTC = 8 Decimals
const wbtcTokenAddress = '0x50b7545627a5162F82A992c33b87aDc75187B218';

//USDC = 6 Decimals
const usdcTokenAddress = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';
const uscTokenAddress = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';

//MATIC Addresses
//MAINNET BELOW
const wbtcTokenAddressMATIC = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';
const usdcTokenAddressMATIC = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359';
//MATIC WBTC = 8 decimals
//const wbtcTokenAddressMATIC = '0x62B5b2FEdF89F446a99a6005eF6d94FceCfd5EE9';
//const usdcTokenAddressMATIC = '0xe5137ac2953ca1a8a85d5adb80dc1d96acb14f9e';
//const usdcTokenAddressMATIC = '0x63c8f86FA98c75099E483B9191c931D8ff7910fC';

// Access different parts of the contract data
const savingsMultiABIMatic = contractData.savingsMultiABIMatic;
const savingsMultiBytecodeMatic = contractData.savingsMultiBytecodeMatic;

const standardERC20ABI = contractData.siteABI;
const standardERC20Bytecode = contractData.siteBytecode;

const masterControllerABI = contractData.masterControllerABI;
const masterControllerBytecode = contractData.masterControllerBytecode;

const masterControllerFixABI = contractData.masterControllerFixABI;
const masterControllerFixBytecode = contractData.masterControllerFixBytecode;

const mevABI = contractData.mevABI;
const mevBytecode = contractData.mevBytecode;

const creditFriendsAvaxSixABI = contractData.creditFriendsAvaxSixABI;
const creditFriendsAvaxSixBytecode = contractData.creditFriendsAvaxSixBytecode;

const creditFriendsUscSixABI = contractData.creditFriendsUscSixABI;
const creditFriendsUscSixBytecode = contractData.creditFriendsUscSixBytecode;

const creditFriendsEvowSixABI = contractData.creditFriendsEvowSixABI;
const creditFriendsEvowSixBytecode = contractData.creditFriendsEvowSixBytecode;

const creditFriendsSwthSixABI = contractData.creditFriendsSwthSixABI;
const creditFriendsSwthSixBytecode = contractData.creditFriendsSwthSixBytecode;

const creditFriendsWbtcSixABI = contractData.creditFriendsWbtcSixABI;
const creditFriendsWbtcSixBytecode = contractData.creditFriendsWbtcSixBytecode;

const creditFriendsAvaxTwelveABI = contractData.creditFriendsAvaxTwelveABI;
const creditFriendsAvaxTwelveBytecode = contractData.creditFriendsAvaxTwelveBytecode;

const creditFriendsUscTwelveABI = contractData.creditFriendsUscTwelveABI;
const creditFriendsUscTwelveBytecode = contractData.creditFriendsUscTwelveBytecode;

const creditFriendsEvowTwelveABI = contractData.creditFriendsEvowTwelveABI;
const creditFriendsEvowTwelveBytecode = contractData.creditFriendsEvowTwelveBytecode;

const creditFriendsSwthTwelveABI = contractData.creditFriendsSwthTwelveABI;
const creditFriendsSwthTwelveBytecode = contractData.creditFriendsSwthTwelveBytecode;

const creditFriendsWbtcTwelveABI = contractData.creditFriendsWbtcTwelveABI;
const creditFriendsWbtcTwelveBytecode = contractData.creditFriendsWbtcTwelveBytecode;

const empEvow1ABI = contractData.empEvow1ABI;
const empEvow1Bytecode = contractData.empEvow1Bytecode;

const empSwth1ABI = contractData.empSwth1ABI;
const empSwth1Bytecode = contractData.empSwth1Bytecode;

const empUsc1ABI = contractData.empUsc1ABI;
const empUsc1Bytecode = contractData.empUsc1Bytecode;

const empWbtc1ABI = contractData.empWbtc1ABI;
const empWbtc1Bytecode = contractData.empWbtc1Bytecode;

const empEvow2ABI = contractData.empEvow2ABI;
const empEvow2Bytecode = contractData.empEvow2Bytecode;

const empSwth2ABI = contractData.empSwth2ABI;
const empSwth2Bytecode = contractData.empSwth2Bytecode;

const empUsc2ABI = contractData.empUsc2ABI;
const empUsc2Bytecode = contractData.empUsc2Bytecode;

const empWbtc2ABI = contractData.empWbtc2ABI;
const empWbtc2Bytecode = contractData.empWbtc2Bytecode;

const empEvow3ABI = contractData.empEvow3ABI;
const empEvow3Bytecode = contractData.empEvow3Bytecode;

const empSwth3ABI = contractData.empSwth3ABI;
const empSwth3Bytecode = contractData.empSwth3Bytecode;

const empUsc3ABI = contractData.empUsc3ABI;
const empUsc3Bytecode = contractData.empUsc3Bytecode;

const empWbtc3ABI = contractData.empWbtc3ABI;
const empWbtc3Bytecode = contractData.empWbtc3Bytecode;

const pp1AvaxABI = contractData.pp1AvaxABI;
const pp1AvaxBytecode = contractData.pp1AvaxBytecode;

const pp1EvowABI = contractData.pp1EvowABI;
const pp1EvowBytecode = contractData.pp1EvowBytecode;

const pp1WbtcABI = contractData.pp1WbtcABI;
const pp1WbtcBytecode = contractData.pp1WbtcBytecode;

const pp1UscABI = contractData.pp1UscABI;
const pp1UscBytecode = contractData.pp1UscBytecode;

const pp1SwthABI = contractData.pp1SwthABI;
const pp1SwthBytecode = contractData.pp1SwthBytecode;

const pp2AvaxABI = contractData.pp2AvaxABI;
const pp2AvaxBytecode = contractData.pp2AvaxBytecode;

const pp2EvowABI = contractData.pp2EvowABI;
const pp2EvowBytecode = contractData.pp2EvowBytecode;

const pp2WbtcABI = contractData.pp2WbtcABI;
const pp2WbtcBytecode = contractData.pp2WbtcBytecode;

const pp2UscABI = contractData.pp2UscABI;
const pp2UscBytecode = contractData.pp2UscBytecode;

const pp2SwthABI = contractData.pp2SwthABI;
const pp2SwthBytecode = contractData.pp2SwthBytecode;

const pp3AvaxABI = contractData.pp3AvaxABI;
const pp3AvaxBytecode = contractData.pp3AvaxBytecode;

const pp3EvowABI = contractData.pp3EvowABI;
const pp3EvowBytecode = contractData.pp3EvowBytecode;

const pp3WbtcABI = contractData.pp3WbtcABI;
const pp3WbtcBytecode = contractData.pp3WbtcBytecode;

const pp3UscABI = contractData.pp3UscABI;
const pp3UscBytecode = contractData.pp3UscBytecode;

const pp3SwthABI = contractData.pp3SwthABI;
const pp3SwthBytecode = contractData.pp3SwthBytecode;

const pp4AvaxABI = contractData.pp4AvaxABI;
const pp4AvaxBytecode = contractData.pp4AvaxBytecode;

const pp4EvowABI = contractData.pp4EvowABI;
const pp4EvowBytecode = contractData.pp4EvowBytecode;

const pp4WbtcABI = contractData.pp4WbtcABI;
const pp4WbtcBytecode = contractData.pp4WbtcBytecode;

const pp4UscABI = contractData.pp4UscABI;
const pp4UscBytecode = contractData.pp4UscBytecode;

const pp4SwthABI = contractData.pp4SwthABI;
const pp4SwthBytecode = contractData.pp4SwthBytecode;

const pp5AvaxABI = contractData.pp5AvaxABI;
const pp5AvaxBytecode = contractData.pp5AvaxBytecode;

const pp5EvowABI = contractData.pp5EvowABI;
const pp5EvowBytecode = contractData.pp5EvowBytecode;

const pp5WbtcABI = contractData.pp5WbtcABI;
const pp5WbtcBytecode = contractData.pp5WbtcBytecode;

const pp5UscABI = contractData.pp5UscABI;
const pp5UscBytecode = contractData.pp5UscBytecode;

const pp5SwthABI = contractData.pp5SwthABI;
const pp5SwthBytecode = contractData.pp5SwthBytecode;

const LSM3EvowABI = contractData.LSM3EvowABI;
const LSM3EvowBytecode = contractData.LSM3EvowBytecode;

const LSM3WbtcABI = contractData.LSM3WbtcABI;
const LSM3WbtcBytecode = contractData.LSM3WbtcBytecode;

const LSM3UscABI = contractData.LSM3UscABI;
const LSM3UscBytecode = contractData.LSM3UscBytecode;

const LSM3SwthABI = contractData.LSM3SwthABI;
const LSM3SwthBytecode = contractData.LSM3SwthBytecode;

const LSM4EvowABI = contractData.LSM4EvowABI;
const LSM4EvowBytecode = contractData.LSM4EvowBytecode;

const LSM4WbtcABI = contractData.LSM4WbtcABI;
const LSM4WbtcBytecode = contractData.LSM4WbtcBytecode;

const LSM4UscABI = contractData.LSM4UscABI;
const LSM4UscBytecode = contractData.LSM4UscBytecode;

const LSM4SwthABI = contractData.LSM4SwthABI;
const LSM4SwthBytecode = contractData.LSM4SwthBytecode;

const LSM5EvowABI = contractData.LSM5EvowABI;
const LSM5EvowBytecode = contractData.LSM5EvowBytecode;

const LSM5WbtcABI = contractData.LSM5WbtcABI;
const LSM5WbtcBytecode = contractData.LSM5WbtcBytecode;

const LSM5UscABI = contractData.LSM5UscABI;
const LSM5UscBytecode = contractData.LSM5UscBytecode;

const LSM5SwthABI = contractData.LSM5SwthABI;
const LSM5SwthBytecode = contractData.LSM5SwthBytecode;

const LSM6EvowABI = contractData.LSM6EvowABI;
const LSM6EvowBytecode = contractData.LSM6EvowBytecode;

const LSM6WbtcABI = contractData.LSM6WbtcABI;
const LSM6WbtcBytecode = contractData.LSM6WbtcBytecode;

const LSM6UscABI = contractData.LSM6UscABI;
const LSM6UscBytecode = contractData.LSM6UscBytecode;

const LSM6SwthABI = contractData.LSM6SwthABI;
const LSM6SwthBytecode = contractData.LSM6SwthBytecode;

const LSM7EvowABI = contractData.LSM7EvowABI;
const LSM7EvowBytecode = contractData.LSM7EvowBytecode;

const LSM7WbtcABI = contractData.LSM7WbtcABI;
const LSM7WbtcBytecode = contractData.LSM7WbtcBytecode;

const LSM7UscABI = contractData.LSM7UscABI;
const LSM7UscBytecode = contractData.LSM7UscBytecode;

const LSM7SwthABI = contractData.LSM7SwthABI;
const LSM7SwthBytecode = contractData.LSM7SwthBytecode;

const pp1EvowABI_Matic = contractData.pp1EvowABI_Matic;
const pp1EvowBytecode_Matic = contractData.pp1EvowBytecode_Matic;

const pp1MaticABI = contractData.pp1MaticABI;
const pp1MaticBytecode = contractData.pp1MaticBytecode;

const pp1UsdcABI = contractData.pp1UsdcABI;
const pp1UsdcBytecode = contractData.pp1UsdcBytecode;

const pp1WbtcABI_Matic = contractData.pp1WbtcABI_Matic;
const pp1WbtcBytecode_Matic = contractData.pp1WbtcBytecode_Matic;

const pp2EvowABI_Matic = contractData.pp2EvowABI_Matic;
const pp2EvowBytecode_Matic = contractData.pp2EvowBytecode_Matic;

const pp2MaticABI = contractData.pp2MaticABI;
const pp2MaticBytecode = contractData.pp2MaticBytecode;

const pp2UsdcABI = contractData.pp2UsdcABI;
const pp2UsdcBytecode = contractData.pp2UsdcBytecode;

const pp2WbtcABI_Matic = contractData.pp2WbtcABI_Matic;
const pp2WbtcBytecode_Matic = contractData.pp2WbtcBytecode_Matic;

const pp3EvowABI_Matic = contractData.pp3EvowABI_Matic;
const pp3EvowBytecode_Matic = contractData.pp3EvowBytecode_Matic;

const pp3MaticABI = contractData.pp3MaticABI;
const pp3MaticBytecode = contractData.pp3MaticBytecode;

const pp3UsdcABI = contractData.pp3UsdcABI;
const pp3UsdcBytecode = contractData.pp3UsdcBytecode;

const pp3WbtcABI_Matic = contractData.pp3WbtcABI_Matic;
const pp3WbtcBytecode_Matic = contractData.pp3WbtcBytecode_Matic;

const pp4EvowABI_Matic = contractData.pp4EvowABI_Matic;
const pp4EvowBytecode_Matic = contractData.pp4EvowBytecode_Matic;

const pp4MaticABI = contractData.pp4MaticABI;
const pp4MaticBytecode = contractData.pp4MaticBytecode;

const pp4UsdcABI = contractData.pp4UsdcABI;
const pp4UsdcBytecode = contractData.pp4UsdcBytecode;

const pp4WbtcABI_Matic = contractData.pp4WbtcABI_Matic;
const pp4WbtcBytecode_Matic = contractData.pp4WbtcBytecode_Matic;

const pp5EvowABI_Matic = contractData.pp5EvowABI_Matic;
const pp5EvowBytecode_Matic = contractData.pp5EvowBytecode_Matic;

const pp5MaticABI = contractData.pp5MaticABI;
const pp5MaticBytecode = contractData.pp5MaticBytecode;

const pp5UsdcABI = contractData.pp5UsdcABI;
const pp5UsdcBytecode = contractData.pp5UsdcBytecode;

const pp5WbtcABI_Matic = contractData.pp5WbtcABI_Matic;
const pp5WbtcBytecode_Matic = contractData.pp5WbtcBytecode_Matic;

const LSM3AvaxABI = contractData.LSM3AvaxABI;
const LSM3AvaxBytecode = contractData.LSM3AvaxBytecode;

const LSM3EvowABI_Matic = contractData.LSM3EvowABI_Matic;
const LSM3EvowBytecode_Matic = contractData.LSM3EvowBytecode_Matic;

const LSM3MaticABI = contractData.LSM3MaticABI;
const LSM3MaticBytecode = contractData.LSM3MaticBytecode;

const LSM3UsdcABI = contractData.LSM3UsdcABI;
const LSM3UsdcBytecode = contractData.LSM3UsdcBytecode;

const LSM3WbtcABI_Matic = contractData.LSM3WbtcABI_Matic;
const LSM3WbtcBytecode_Matic = contractData.LSM3WbtcBytecode_Matic;

const LSM4AvaxABI = contractData.LSM4AvaxABI;
const LSM4AvaxBytecode = contractData.LSM4AvaxBytecode;

const LSM4EvowABI_Matic = contractData.LSM4EvowABI_Matic;
const LSM4EvowBytecode_Matic = contractData.LSM4EvowBytecode_Matic;

const LSM4MaticABI = contractData.LSM4MaticABI;
const LSM4MaticBytecode = contractData.LSM4MaticBytecode;

const LSM4UsdcABI = contractData.LSM4UsdcABI;
const LSM4UsdcBytecode = contractData.LSM4UsdcBytecode;

const LSM4WbtcABI_Matic = contractData.LSM4WbtcABI_Matic;
const LSM4WbtcBytecode_Matic = contractData.LSM4WbtcBytecode_Matic;

const LSM5AvaxABI = contractData.LSM5AvaxABI;
const LSM5AvaxBytecode = contractData.LSM5AvaxBytecode;

const LSM5EvowABI_Matic = contractData.LSM5EvowABI_Matic;
const LSM5EvowBytecode_Matic = contractData.LSM5EvowBytecode_Matic;

const LSM5MaticABI = contractData.LSM5MaticABI;
const LSM5MaticBytecode = contractData.LSM5MaticBytecode;

const LSM5UsdcABI = contractData.LSM5UsdcABI;
const LSM5UsdcBytecode = contractData.LSM5UsdcBytecode;

const LSM5WbtcABI_Matic = contractData.LSM5WbtcABI_Matic;
const LSM5WbtcBytecode_Matic = contractData.LSM5WbtcBytecode_Matic;

const LSM6AvaxABI = contractData.LSM6AvaxABI;
const LSM6AvaxBytecode = contractData.LSM6AvaxBytecode;

const LSM6EvowABI_Matic = contractData.LSM6EvowABI_Matic;
const LSM6EvowBytecode_Matic = contractData.LSM6EvowBytecode_Matic;

const LSM6MaticABI = contractData.LSM6MaticABI;
const LSM6MaticBytecode = contractData.LSM6MaticBytecode;

const LSM6UsdcABI = contractData.LSM6UsdcABI;
const LSM6UsdcBytecode = contractData.LSM6UsdcBytecode;

const LSM6WbtcABI_Matic = contractData.LSM6WbtcABI_Matic;
const LSM6WbtcBytecode_Matic = contractData.LSM6WbtcBytecode_Matic;

const LSM7AvaxABI = contractData.LSM7AvaxABI;
const LSM7AvaxBytecode = contractData.LSM7AvaxBytecode;

const LSM7EvowABI_Matic = contractData.LSM7EvowABI_Matic;
const LSM7EvowBytecode_Matic = contractData.LSM7EvowBytecode_Matic;

const LSM7MaticABI = contractData.LSM7MaticABI;
const LSM7MaticBytecode = contractData.LSM7MaticBytecode;

const LSM7UsdcABI = contractData.LSM7UsdcABI;
const LSM7UsdcBytecode = contractData.LSM7UsdcBytecode;

const LSM7WbtcABI_Matic = contractData.LSM7WbtcABI_Matic;
const LSM7WbtcBytecode_Matic = contractData.LSM7WbtcBytecode_Matic;

const creditFriendsMATICUsdcSixABI = contractData.creditFriendsMATICUsdcSixABI;
const creditFriendsMATICUsdcSixBytecode = contractData.creditFriendsMATICUsdcSixBytecode;

const creditFriendsMATICEvowSixABI = contractData.creditFriendsMATICEvowSixABI;
const creditFriendsMATICEvowSixBytecode = contractData.creditFriendsMATICEvowSixBytecode;

const creditFriendsMATICSixABI = contractData.creditFriendsMATICSixABI;
const creditFriendsMATICSixBytecode = contractData.creditFriendsMATICESixBytecode;

const creditFriendsMATICWbtcSixABI = contractData.creditFriendsMATICWbtcSixABI;
const creditFriendsMATICWbtcSixBytecode = contractData.creditFriendsMATICWbtcSixBytecode;

const creditFriendsMATICUsdcTwelveABI = contractData.creditFriendsMATICUsdcTwelveABI;
const creditFriendsMATICUsdcTwelveBytecode = contractData.creditFriendsMATICUsdcTwelveBytecode;

const creditFriendsMATICEvowTwelveABI = contractData.creditFriendsMATICEvowTwelveABI;
const creditFriendsMATICEvowTwelveBytecode = contractData.creditFriendsMATICEvowTwelveBytecode;

const creditFriendsMATICTwelveABI = contractData.creditFriendsMATICTwelveABI;
const creditFriendsMATICTwelveBytecode = contractData.creditFriendsMATICTwelveBytecode;

const creditFriendsMATICWbtcTwelveABI = contractData.creditFriendsMATICWbtcTwelveABI;
const creditFriendsMATICWbtcTwelveBytecode = contractData.creditFriendsMATICWbtcTwelveBytecode;


const savingsMultiABI = contractData.savingsMultiABI;
const savingsMultiBytecode = contractData.savingsMultiBytecode;

const empEvow1ABIMatic = contractData.empEvow1ABIMatic;
const empEvow1BytecodeMatic = contractData.empEvow1BytecodeMatic;

const empUSDC1ABI = contractData.empUSDC1ABI;
const empUSDC1Bytecode = contractData.empUSDC1Bytecode;

const empMATIC1ABI = contractData.empMATIC1ABI;
const empMATIC1Bytecode = contractData.empMATIC1Bytecode;

const empWbtc1ABIMatic = contractData.empWbtc1ABIMatic;
const empWbtc1ByteCodeMatic = contractData.empWbtc1ByteCodeMatic;

const empEvow2ABIMatic = contractData.empEvow2ABIMatic;
const empEvow2BytecodeMatic = contractData.empEvow2BytecodeMatic;

const empUSDC2ABI = contractData.empUSDC2ABI;
const empUSDC2Bytecode = contractData.empUSDC2Bytecode;

const empMATIC2ABI = contractData.empMATIC2ABI;
const empMATIC2Bytecode = contractData.empMATIC2Bytecode;

const empWbtc2ABIMatic = contractData.empWbtc2ABIMatic;
const empWbtc2ByteCodeMatic = contractData.empWbtc2ByteCodeMatic;

const empEvow3ABIMatic = contractData.empEvow3ABIMatic;
const empEvow3BytecodeMatic = contractData.empEvow3BytecodeMatic;

const empUSDC3ABI = contractData.empUSDC3ABI;
const empUSDC3Bytecode = contractData.empUSDC3Bytecode;

const empMATIC3ABI = contractData.empMATIC3ABI;
const empMATIC3Bytecode = contractData.empMATIC3Bytecode;

const empWbtc3ABIMatic = contractData.empWbtc3ABIMatic;
const empWbtc3ByteCodeMatic = contractData.empWbtc3ByteCodeMatic;

const empAvax1ABI = contractData.empAvax1ABI;
const empAvax1Bytecode = contractData.empAvax1Bytecode;
const empAvax2ABI = contractData.empAvax2ABI;
const empAvax2Bytecode = contractData.empAvax2Bytecode;
const empAvax3ABI = contractData.empAvax3ABI;
const empAvax3Bytecode = contractData.empAvax3Bytecode;

const savingsEvowABIMatic = contractData.savingsEvowABIMatic;
const savingsEvowBytecodeMatic = contractData.savingsEvowBytecodeMatic;
const siteABI = contractData.siteABI;
const siteBytecode = contractData.siteBytecode;
const messagingABI = contractData.messagingABI;
const messagingBytecode = contractData.messagingBytecode;


module.exports = {
  chainId,
  savingsMultiABI,
  savingsMultiBytecode,
  standardERC20ABI,
  threeMonthPrice,
  sixMonthPrice,
  oneYearPrice,
  uscTokenAddress,
  wbtcTokenAddress,
  siteToken,
  siteTokenMATIC,
  usdcTokenAddressMATIC,
  wbtcTokenAddressMATIC,
  masterControllerABI,
  masterControllerBytecode,
  masterControllerAddress,
  masterControllerFixABI,
  masterControllerFixBytecode,
  masterControllerFixAddress,
  mevABI,
  mevBytecode,
  mevAddress,
  creditFriendsAvaxSixABI,
  creditFriendsUscSixABI,
  creditFriendsEvowSixABI,
  creditFriendsSwthSixABI,
  creditFriendsWbtcSixABI,
  creditFriendsAvaxSixBytecode,
  creditFriendsUscSixBytecode,
  creditFriendsEvowSixBytecode,
  creditFriendsSwthSixBytecode,
  creditFriendsWbtcSixBytecode,
  creditFriendsAvaxTwelveABI,
  creditFriendsUscTwelveABI,
  creditFriendsEvowTwelveABI,
  creditFriendsSwthTwelveABI,
  creditFriendsWbtcTwelveABI,
  creditFriendsAvaxTwelveBytecode,
  creditFriendsUscTwelveBytecode,
  creditFriendsEvowTwelveBytecode,
  creditFriendsSwthTwelveBytecode,
  creditFriendsWbtcTwelveBytecode,
  empEvow1ABI,
  empEvow1Bytecode,
  empSwth1ABI,
  empSwth1Bytecode,
  empUsc1ABI,
  empUsc1Bytecode,
  empWbtc1ABI,
  empWbtc1Bytecode,
  empEvow2ABI,
  empEvow2Bytecode,
  empSwth2ABI,
  empSwth2Bytecode,
  empUsc2ABI,
  empUsc2Bytecode,
  empWbtc2ABI,
  empWbtc2Bytecode,
  empEvow3ABI,
  empEvow3Bytecode,
  empSwth3ABI,
  empSwth3Bytecode,
  empUsc3ABI,
  empUsc3Bytecode,
  empWbtc3ABI,
  empWbtc3Bytecode,
  pp1AvaxABI,
  pp1AvaxBytecode,
  pp1EvowABI,
  pp1EvowBytecode,
  pp1WbtcABI,
  pp1WbtcBytecode,
  pp1UscABI,
  pp1UscBytecode,
  pp1SwthABI,
  pp1SwthBytecode,
  pp2AvaxABI,
  pp2AvaxBytecode,
  pp2EvowABI,
  pp2EvowBytecode,
  pp2WbtcABI,
  pp2WbtcBytecode,
  pp2UscABI,
  pp2UscBytecode,
  pp2SwthABI,
  pp2SwthBytecode,
  pp3AvaxABI,
  pp3AvaxBytecode,
  pp3EvowABI,
  pp3EvowBytecode,
  pp3WbtcABI,
  pp3WbtcBytecode,
  pp3UscABI,
  pp3UscBytecode,
  pp3SwthABI,
  pp3SwthBytecode,
  pp4AvaxABI,
  pp4AvaxBytecode,
  pp4EvowABI,
  pp4EvowBytecode,
  pp4WbtcABI,
  pp4WbtcBytecode,
  pp4UscABI,
  pp4UscBytecode,
  pp4SwthABI,
  pp4SwthBytecode,
  pp5AvaxABI,
  pp5AvaxBytecode,
  pp5EvowABI,
  pp5EvowBytecode,
  pp5WbtcABI,
  pp5WbtcBytecode,
  pp5UscABI,
  pp5UscBytecode,
  pp5SwthABI,
  pp5SwthBytecode,
  LSM3EvowABI,
  LSM3EvowBytecode,
  LSM3WbtcABI,
  LSM3WbtcBytecode,
  LSM3UscABI,
  LSM3UscBytecode,
  LSM3SwthABI,
  LSM3SwthBytecode,
  LSM4EvowABI,
  LSM4EvowBytecode,
  LSM4WbtcABI,
  LSM4WbtcBytecode,
  LSM4UscABI,
  LSM4UscBytecode,
  LSM4SwthABI,
  LSM4SwthBytecode,
  LSM5EvowABI,
  LSM5EvowBytecode,
  LSM5WbtcABI,
  LSM5WbtcBytecode,
  LSM5UscABI,
  LSM5UscBytecode,
  LSM5SwthABI,
  LSM5SwthBytecode,
  LSM6EvowABI,
  LSM6EvowBytecode,
  LSM6WbtcABI,
  LSM6WbtcBytecode,
  LSM6UscABI,
  LSM6UscBytecode,
  LSM6SwthABI,
  LSM6SwthBytecode,
  LSM7EvowABI,
  LSM7EvowBytecode,
  LSM7WbtcABI,
  LSM7WbtcBytecode,
  LSM7UscABI,
  LSM7UscBytecode,
  LSM7SwthABI,
  LSM7SwthBytecode,
  pp1EvowABI_Matic,
  pp1EvowBytecode_Matic,
  pp1MaticABI,
  pp1MaticBytecode,
  pp1UsdcABI,
  pp1UsdcBytecode,
  pp1WbtcABI_Matic,
  pp1WbtcBytecode_Matic,
  pp2EvowABI_Matic,
  pp2EvowBytecode_Matic,
  pp2MaticABI,
  pp2MaticBytecode,
  pp2UsdcABI,
  pp2UsdcBytecode,
  pp2WbtcABI_Matic,
  pp2WbtcBytecode_Matic,
  pp3EvowABI_Matic,
  pp3EvowBytecode_Matic,
  pp3MaticABI,
  pp3MaticBytecode,
  pp3UsdcABI,
  pp3UsdcBytecode,
  pp3WbtcABI_Matic,
  pp3WbtcBytecode_Matic,
  pp4EvowABI_Matic,
  pp4EvowBytecode_Matic,
  pp4MaticABI,
  pp4MaticBytecode,
  pp4UsdcABI,
  pp4UsdcBytecode,
  pp4WbtcABI_Matic,
  pp4WbtcBytecode_Matic,
  pp5EvowABI_Matic,
  pp5EvowBytecode_Matic,
  pp5MaticABI,
  pp5MaticBytecode,
  pp5UsdcABI,
  pp5UsdcBytecode,
  pp5WbtcABI_Matic,
  pp5WbtcBytecode_Matic,
  LSM3AvaxABI,
  LSM3AvaxBytecode,
  LSM3EvowABI_Matic,
  LSM3EvowBytecode_Matic,
  LSM3MaticABI,
  LSM3MaticBytecode,
  LSM3UsdcABI,
  LSM3UsdcBytecode,
  LSM3WbtcABI_Matic,
  LSM3WbtcBytecode_Matic,
  LSM4AvaxABI,
  LSM4AvaxBytecode,
  LSM4EvowABI_Matic,
  LSM4EvowBytecode_Matic,
  LSM4MaticABI,
  LSM4MaticBytecode,
  LSM4UsdcABI,
  LSM4UsdcBytecode,
  LSM4WbtcABI_Matic,
  LSM4WbtcBytecode_Matic,
  LSM5AvaxABI,
  LSM5AvaxBytecode,
  LSM5EvowABI_Matic,
  LSM5EvowBytecode_Matic,
  LSM5MaticABI,
  LSM5MaticBytecode,
  LSM5UsdcABI,
  LSM5UsdcBytecode,
  LSM5WbtcABI_Matic,
  LSM5WbtcBytecode_Matic,
  LSM6AvaxABI,
  LSM6AvaxBytecode,
  LSM6EvowABI_Matic,
  LSM6EvowBytecode_Matic,
  LSM6MaticABI,
  LSM6MaticBytecode,
  LSM6UsdcABI,
  LSM6UsdcBytecode,
  LSM6WbtcABI_Matic,
  LSM6WbtcBytecode_Matic,
  LSM7AvaxABI,
  LSM7AvaxBytecode,
  LSM7EvowABI_Matic,
  LSM7EvowBytecode_Matic,
  LSM7MaticABI,
  LSM7MaticBytecode,
  LSM7UsdcABI,
  LSM7UsdcBytecode,
  LSM7WbtcABI_Matic,
  LSM7WbtcBytecode_Matic,
  creditFriendsMATICUsdcSixABI,
  creditFriendsMATICUsdcSixBytecode,
  creditFriendsMATICEvowSixABI,
  creditFriendsMATICEvowSixBytecode,
  creditFriendsMATICSixABI,
  creditFriendsMATICSixBytecode,
  creditFriendsMATICWbtcSixABI,
  creditFriendsMATICWbtcSixBytecode,
  creditFriendsMATICUsdcTwelveABI,
  creditFriendsMATICUsdcTwelveBytecode,
  creditFriendsMATICEvowTwelveABI,
  creditFriendsMATICEvowTwelveBytecode,
  creditFriendsMATICTwelveABI,
  creditFriendsMATICTwelveBytecode,
  creditFriendsMATICWbtcTwelveABI,
  creditFriendsMATICWbtcTwelveBytecode,
  empEvow1ABIMatic,
  empEvow1BytecodeMatic,
  empUSDC1ABI,
  empUSDC1Bytecode,
  empMATIC1ABI,
  empMATIC1Bytecode,
  empWbtc1ABIMatic,
  empWbtc1ByteCodeMatic,
  empEvow2ABIMatic,
  empEvow2BytecodeMatic,
  empUSDC2ABI,
  empUSDC2Bytecode,
  empMATIC2ABI,
  empMATIC2Bytecode,
  empWbtc2ABIMatic,
  empWbtc2ByteCodeMatic,
  empEvow3ABIMatic,
  empEvow3BytecodeMatic,
  empUSDC3ABI,
  empUSDC3Bytecode,
  empMATIC3ABI,
  empMATIC3Bytecode,
  empWbtc3ABIMatic,
  empWbtc3ByteCodeMatic,
  empAvax1ABI,
  empAvax1Bytecode,
  empAvax2ABI,
  empAvax2Bytecode,
  empAvax3ABI,
  empAvax3Bytecode,
  savingsMultiABIMatic,
  savingsMultiBytecodeMatic,
  savingsEvowABIMatic,
  savingsEvowBytecodeMatic,
  siteABI,
  siteBytecode,
  messagingABI,
  messagingBytecode,
  messagingAddress,
  messagingBlock
};
