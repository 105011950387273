import React, { useState, useContext } from 'react';
import { Container, Col } from "react-bootstrap";
import { searchURL } from './urlConfig';
import { AuthContext } from './authProvider';
import { SubscribedContext } from './subscribedProvider';
import { FaArrowLeft, FaHome, FaUser } from 'react-icons/fa'; // Import the desired icons from react-icons
import { BiSolidLogOutCircle } from 'react-icons/bi';
import { Link , useNavigate} from 'react-router-dom'; // Import Link from react-router-dom for navigation

const SearchUser = () => {
  const [searchText, setSearchText] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const { unsubscribe } = useContext(SubscribedContext);
  const { logout } = useContext(AuthContext);

  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    padding: '20px', // Add padding for spacing
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLogOff = () => {
    logout();
    unsubscribe();
    navigate('/');
  }

  const handleSearch = () => {
    // Create a JSON request body
    const requestBody = JSON.stringify({ search: searchText });

    // Send the request to your API endpoint
    fetch(searchURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // If user data is received, update the state
          setUser(data);
        } else {
          // Handle the case when no user data is found
          setUser(null);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setUser(null);
      });
  };

  return (
    <div>
      <Container>
        { isLoggedIn ? (
        <Col style={outlinedColumnStyle}>
          <div className="navigation-container">
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
            <Link to="/user">
              <button className="profile-button" style={{ marginRight: '10px' }} >
                <FaUser style={{ fontSize: '24px'}}/> Profile
              </button>
            </Link>
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleLogOff}>
              <BiSolidLogOutCircle  style={{ fontSize: '24px'}} /> Logout
            </button>
          </div>
        </Col>
      ) : (
        <Col style={outlinedColumnStyle}>
          <div className="navigation-container">
            <button className="back-button" style={{ marginRight: '10px' }} onClick={handleGoBack}>
              <FaArrowLeft  style={{ fontSize: '24px'}} /> Back
            </button>
            <Link to="/">
              <button className="profile-button">
                <FaHome style={{ fontSize: '24px'}}/> Home
              </button>
            </Link>
          </div>
        </Col>
      )}
      </Container>
      <Container>
        <Col style={outlinedColumnStyle}>
          <h2>Search User</h2>
          <input
            type="text"
            placeholder="Enter the wallet address"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
          />
          <button onClick={handleSearch}>Search</button>
        </Col>
      </Container>
      <Container>
      <Col style={outlinedColumnStyle}>
        {user ? (
          <div>
            <h3>User Info</h3>
            <p>Address: {user.address}</p>
            <p>Twitter: {user.twitter}</p>
            <p>Telegram: {user.telegram}</p>
            <p>Playstation: {user.playstation}</p>
            <p>Xbox: {user.xbox}</p>
          </div>
        ) : (
          <p>User will appear if found in database.</p>
        )}
      </Col>
      </Container>
    </div>
  );
};

export default SearchUser;
