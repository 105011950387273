import React, { useState } from 'react';
import { Button, Container, Col, Row, Form } from 'react-bootstrap';
import { abiMapping } from './abiMapping';
import { contractMapping } from './contractMapping';
import { standardERC20ABI, uscTokenAddress, wbtcTokenAddress, siteToken, usdcTokenAddressMATIC, wbtcTokenAddressMATIC, siteTokenMATIC } from './abiConfig';
import {avalancheMainRpcUrl, polygonMainUrl, deleteContract} from './urlConfig';
import LoadingAlert from './loadingAlert';
import Web3 from 'web3';
import { chainPrompt } from './chainPrompt';

const ContractFunctions = ({ addr, abi, type, chain }) => {
  const [inputValues, setInputValues] = useState({});
  const [viewData, setViewData] = useState({});
  const walletAddress = localStorage.getItem('walletAddress');
  const localToken = localStorage.getItem('token');
  const providerType = localStorage.getItem('provider')
  const [selectedFunction, setSelectedFunction] = useState(null);

  const outlinedColumnStyle = {
    border: '2px solid #000000', // Adjust border style as needed
    borderRadius: '10px', // Add border radius for rounded corners
    margin: '6px', // Add margin on all sides
    backgroundColor: '#FEAE67',
    overflowWrap: 'break-word'
  };

  const handleInputChange = (funcName, paramName, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [funcName]: {
        ...prevInputValues[funcName],
        [paramName]: value,
      },
    }));
  };

  const handleFunctionCall = async (func) => {

    let tokenContract;
    let web3;
    let provider;

    if (providerType === 'coinbase') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      } catch(e){
        provider = window.ethereum.isCoinbaseWallet ? window.ethereum : null;
      }
    }

    if (providerType === 'metamask') {
      try{
        provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      } catch(e){
        provider = window.ethereum.isMetaMask ? window.ethereum : null;
      }
    }

    const switchChain = await chainPrompt(chain, providerType);

    if (chain === "Avalanche"){
      web3 = new Web3(avalancheMainRpcUrl);
    }
    else if (chain === "Polygon"){
      web3 = new Web3(polygonMainUrl);
    }

    if (func.stateMutability === 'view' || func.stateMutability === 'pure') {
      setViewData({ ...viewData, [func.name]: "Checking..." });

      try {

        //Web3 Instance
        const contractInstance = new web3.eth.Contract(abi, addr);

        // Prepare the arguments for the function call
        let argValues = [];
        if (func.inputs && func.inputs.length > 0) {
          argValues = func.inputs.map(input => inputValues[func.name][input.name] || '');
        }

        // Call the function
        const result = await contractInstance.methods[func.name](...argValues).call();

        const displayResult = result === true ? "True" : result === false ? "False" : result;

        // Update view data
        setViewData({ ...viewData, [func.name]: displayResult });

      } catch (error) {
      setViewData({ ...viewData, [func.name]: error.message });
      }
    }

    else if (func.stateMutability === 'nonpayable'){

      const contractInstance = new web3.eth.Contract(abi, addr);

      try{

        if (
          type === "savingsMultiABI" ||
          type === 'savingsEvowABI' ||
          type === "savingsMultiABIMatic" ||
          type === 'savingsEvowABIMatic' ||
          type === 'creditFriendsWbtcSixABI' ||
          type === 'creditFriendsUscSixABI' ||
          type === 'creditFriendsEvowSixABI' ||
          type === 'creditFriendsSwthSixABI' ||
          type === 'creditFriendsAvaxSixABI' ||
          type === 'creditFriendsWbtcTwelveABI' ||
          type === 'creditFriendsUscTwelveABI' ||
          type === 'creditFriendsEvowTwelveABI' ||
          type === 'creditFriendsSwthTwelveABI' ||
          type === 'creditFriendsAvaxTwelveABI' ||
          type === 'creditFriendsMATICUsdcSixABI' ||
          type === 'creditFriendsMATICEvowSixABI' ||
          type === 'creditFriendsMATICSixABI' ||
          type === 'creditFriendsMATICWbtcSixABI' ||
          type === 'creditFriendsMATICUsdcTwelveABI' ||
          type === 'creditFriendsMATICEvowTwelveABI' ||
          type === 'creditFriendsMATICTwelveABI' ||
          type === 'creditFriendsMATICWbtcTwelveABI' ||
          type === 'empUsc1ABI' ||
          type === 'empUsc2ABI' ||
          type === 'empUsc3ABI' ||
          type === 'empSwth1ABI' ||
          type === 'empSwth2ABI' ||
          type === 'empSwth3ABI' ||
          type === 'empAvax1ABI' ||
          type === 'empAvax2ABI' ||
          type === 'empAvax3ABI' ||
          type === 'empWbtc1ABI' ||
          type === 'empWbtc2ABI' ||
          type === 'empWbtc3ABI' ||
          type === 'empEvow1ABI' ||
          type === 'empEvow2ABI' ||
          type === 'empEvow3BI' ||
          type === 'pp1AvaxABI' ||
          type === 'pp1EvowABI' ||
          type === 'pp1UscABI' ||
          type === 'pp1WbtcABI' ||
          type === 'pp1SwthABI' ||
          type === 'pp2AvaxABI' ||
          type === 'pp2EvowABI' ||
          type === 'pp2UscABI' ||
          type === 'pp2WbtcABI' ||
          type === 'pp2SwthABI' ||
          type === 'pp3AvaxABI' ||
          type === 'pp3EvowABI' ||
          type === 'pp3UscABI' ||
          type === 'pp3WbtcABI' ||
          type === 'pp3SwthABI' ||
          type === 'pp4AvaxABI' ||
          type === 'pp4EvowABI' ||
          type === 'pp4UscABI' ||
          type === 'pp4WbtcABI' ||
          type === 'pp4SwthABI' ||
          type === 'pp5AvaxABI' ||
          type === 'pp5EvowABI' ||
          type === 'pp5UscABI' ||
          type === 'pp5WbtcABI' ||
          type === 'pp5SwthABI' ||
          type === 'LSM3AvaxABI' ||
          type === 'LSM3UscABI' ||
          type === 'LSM3EvowABI' ||
          type === 'LSM3SwthABI' ||
          type === 'LSM3WbtcABI' ||
          type === 'LSM4AvaxABI' ||
          type === 'LSM4UscABI' ||
          type === 'LSM4EvowABI' ||
          type === 'LSM4SwthABI' ||
          type === 'LSM4WbtcABI' ||
          type === 'LSM5AvaxABI' ||
          type === 'LSM5UscABI' ||
          type === 'LSM5EvowABI' ||
          type === 'LSM5SwthABI' ||
          type === 'LSM5WbtcABI' ||
          type === 'LSM6AvaxABI' ||
          type === 'LSM6UscABI' ||
          type === 'LSM6EvowABI' ||
          type === 'LSM6SwthABI' ||
          type === 'LSM6WbtcABI' ||
          type === 'LSM7AvaxABI' ||
          type === 'LSM7UscABI' ||
          type === 'LSM7EvowABI' ||
          type === 'LSM7SwthABI' ||
          type === 'LSM7WbtcABI' ||
          type === 'pp1EvowABI_Matic' ||
          type === 'pp1MaticABI' ||
          type === 'pp1UsdcABI' ||
          type === 'pp1WbtcABI_Matic' ||
          type === 'pp2EvowABI_Matic' ||
          type === 'pp2MaticABI' ||
          type === 'pp2UsdcABI' ||
          type === 'pp2WbtcABI_Matic' ||
          type === 'pp3EvowABI_Matic' ||
          type === 'pp3MaticABI' ||
          type === 'pp3UsdcABI' ||
          type === 'pp3WbtcABI_Matic' ||
          type === 'pp4EvowABI_Matic' ||
          type === 'pp4MaticABI' ||
          type === 'pp4UsdcABI' ||
          type === 'pp4WbtcABI_Matic' ||
          type === 'pp5EvowABI_Matic' ||
          type === 'pp5MaticABI' ||
          type === 'pp5UsdcABI' ||
          type === 'pp5WbtcABI_Matic' ||
          type === 'LSM3EvowABI_Matic' ||
          type === 'LSM3MaticABI' ||
          type === 'LSM3UsdcABI' ||
          type === 'LSM3WbtcABI_Matic' ||
          type === 'LSM4EvowABI_Matic' ||
          type === 'LSM4MaticABI' ||
          type === 'LSM4UsdcABI' ||
          type === 'LSM4WbtcABI_Matic' ||
          type === 'LSM5EvowABI_Matic' ||
          type === 'LSM5MaticABI' ||
          type === 'LSM5UsdcABI' ||
          type === 'LSM5WbtcABI_Matic' ||
          type === 'LSM6EvowABI_Matic' ||
          type === 'LSM6MaticABI' ||
          type === 'LSM6UsdcABI' ||
          type === 'LSM6WbtcABI_Matic' ||
          type === 'LSM7EvowABI_Matic' ||
          type === 'LSM7MaticABI' ||
          type === 'LSM7UsdcABI' ||
          type === 'LSM7WbtcABI_Matic' ||
          type === 'empEvow1ABIMatic' ||
          type === 'empUSDC1ABI' ||
          type === 'empMATIC1ABI' ||
          type === 'empWbtc1ABIMatic' ||
          type === 'empEvow2ABIMatic' ||
          type === 'empUSDC2ABI' ||
          type === 'empMATIC2ABI' ||
          type === 'empWbtc2ABIMatic' ||
          type === 'empEvow3ABIMatic' ||
          type === 'empUSDC3ABI' ||
          type === 'empMATIC3ABI' ||
          type === 'empWbtc3ABIMatic'
        ){

          //IF FUNCTION FOR DEPOSIT FUNCTIONS THROUGHOUT ALL CONTRACTS
          //All imported contracts will follow a very specific naming pattern
          if (
              func.name === 'DepositUSC' ||
              func.name === 'DepositEVOW' ||
              func.name === 'DepositWBTC' ||
              func.name === 'DepositUSDC'){
            setViewData({ ...viewData, [func.name]: "Transaction 1/2" });

            if (chain === 'Avalanche'){
              if (func.name === "DepositUSDC"){
                tokenContract = new web3.eth.Contract(standardERC20ABI, uscTokenAddress);
              }
              else if (func.name === 'DepositWBTC'){
                tokenContract = new web3.eth.Contract(standardERC20ABI, wbtcTokenAddress);
              }
              else if (func.name === 'DepositEVOW'){
                tokenContract = new web3.eth.Contract(standardERC20ABI, siteToken);
              }
            }
            else if (chain === 'Polygon'){
              if (func.name === "DepositUSDC"){
                tokenContract = new web3.eth.Contract(standardERC20ABI, usdcTokenAddressMATIC);
              }
              else if (func.name === 'DepositWBTC'){
                tokenContract = new web3.eth.Contract(standardERC20ABI, wbtcTokenAddressMATIC);
              }
              else if (func.name === 'DepositEVOW'){
                tokenContract = new web3.eth.Contract(standardERC20ABI, siteTokenMATIC);
              }
            }

            //Retrieve the _amount input from inputValues
            const _amount = inputValues[func.name]['_amount'];

            const approveData = tokenContract.methods.approve(addr, _amount).encodeABI();

            const approveTransactionObject = {
              from: walletAddress,
              to: tokenContract.options.address,
              data: approveData,
            };

            let gasEstimate = await web3.eth.estimateGas(approveTransactionObject);
            let gasPrice = await web3.eth.getGasPrice();
            let gasFee = gasEstimate * 4

            // Now, you can construct and send your transaction with the calculated gas values
            const newApprovalTransaction = {
              ...approveTransactionObject,
              gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
              gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
            };

            setViewData({ ...viewData, [func.name]: "Transaction Approval 1/2" });

            //Send to user via eth_sendTransaction request with parameters
            const approveTransactionHash = await provider.request({
              method: 'eth_sendTransaction',
              params: [newApprovalTransaction]
            });

            setViewData({ ...viewData, [func.name]: "Transaction Receipt 1/2" });

            // Poll every 2 seconds for the transaction receipt
            const checkInterval = 3000; // 2 seconds
            let receipt = null;
            while (receipt === null) {
              await new Promise((resolve) => setTimeout(resolve, checkInterval)); // Wait for 2 seconds
                // Check for the receipt
              receipt = await web3.eth.getTransactionReceipt(approveTransactionHash);
            }

            setViewData({ ...viewData, [func.name]: "Transaction 2/2" });

            const sendData = contractInstance.methods[func.name](_amount).encodeABI();

            const sendTransactionObject = {
              from: walletAddress, // Set the sender's address
              to: addr, // Contract address
              data: sendData,
            };

            gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
            gasFee = gasEstimate * 3

            // Now, you can construct and send your transaction with the calculated gas values
            const newSendTransactionObj = {
              ...sendTransactionObject,
              gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
              gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
            };

            setViewData({ ...viewData, [func.name]: "Transaction Transfer 2/2" });

            // Send transaction
            //Send to user via eth_sendTransaction request with parameters
            const tx = await provider.request({
              method: 'eth_sendTransaction',
              params: [newSendTransactionObj]
            });

            // Call the function
            let result = `Tx: ${tx}`;

            // Update view data
            setViewData({ ...viewData, [func.name]: result });
          }

          else if (
                  func.name === 'WithdrawUSC' ||
                  func.name === 'WithdrawEVOW' ||
                  func.name === 'WithdrawWBTC' ||
                  func.name === 'WithdrawSWTH' ||
                  func.name === 'WithdrawUSDC' ||
                  func.name === 'WithdrawMATIC'
                ){

            setViewData({ ...viewData, [func.name]: "Transaction 1/1" });

            const contractInstance = new web3.eth.Contract(abi, addr);

            // Retrieve the _amount input from inputValues
            const _amount = inputValues[func.name]['_amount'];

            const sendData = contractInstance.methods[func.name](_amount.toString()).encodeABI();

            const sendTransactionObject = {
              from: walletAddress, // Set the sender's address
              to: addr, // Contract address
              data: sendData,
            };

            let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
            let gasPrice = await web3.eth.getGasPrice();

            let gasFee = gasEstimate * 2

            // Now, you can construct and send your transaction with the calculated gas values
            const newSendTransactionObj = {
              ...sendTransactionObject,
              gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
              gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
            };

            setViewData({ ...viewData, [func.name]: "Transaction Transfer 1/1" });

            // Send transaction
            //Send to user via eth_sendTransaction request with parameters
            const tx = await provider.request({
              method: 'eth_sendTransaction',
              params: [newSendTransactionObj]
            });

            // Call the function
            let result = `Tx: ${tx}`;

            // Update view data
            setViewData({ ...viewData, [func.name]: result });
          }

          else if (func.name === 'SubmitScore'){

            setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

            const contractInstance = new web3.eth.Contract(abi, addr);

            // Retrieve the _amount input from inputValues
            const _scores = inputValues[func.name]['_scores'];

            const _scoresList = _scores.split(",").map(Number);

            const _scoreSubmissionArray = new Array(2).fill(0);
            _scoreSubmissionArray[0] = _scoresList[0];
            _scoreSubmissionArray[1] = _scoresList[1];

            const sendData = contractInstance.methods[func.name](_scoresList).encodeABI();

            const sendTransactionObject = {
              from: walletAddress, // Set the sender's address
              to: addr, // Contract address
              data: sendData,
            };

            let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
            let gasPrice = await web3.eth.getGasPrice();
            let gasFee = gasEstimate * 2

            // Now, you can construct and send your transaction with the calculated gas values
            const newSendTransactionObj = {
              ...sendTransactionObject,
              gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
              gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
            };

            // Send transaction
            //Send to user via eth_sendTransaction request with parameters
            const tx = await provider.request({
              method: 'eth_sendTransaction',
              params: [newSendTransactionObj]
            });

            // Call the function
            let result = `Tx: ${tx}`;

            // Update view data
            setViewData({ ...viewData, [func.name]: result });
        }

        else if (func.name === 'SubmitWinner'){

          setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

          const contractInstance = new web3.eth.Contract(abi, addr);

          // Retrieve the _amount input from inputValues
          const _address = inputValues[func.name]['_address'];

          const sendData = contractInstance.methods[func.name](_address).encodeABI();

          const sendTransactionObject = {
            from: walletAddress, // Set the sender's address
            to: addr, // Contract address
            data: sendData,
          };

          let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
          let gasPrice = await web3.eth.getGasPrice();
          let gasFee = gasEstimate * 2

          // Now, you can construct and send your transaction with the calculated gas values
          const newSendTransactionObj = {
            ...sendTransactionObject,
            gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
            gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
          };

          // Send transaction
          //Send to user via eth_sendTransaction request with parameters
          const tx = await provider.request({
            method: 'eth_sendTransaction',
            params: [newSendTransactionObj]
          });

          // Call the function
          let result = `Tx: ${tx}`;

          // Update view data
          setViewData({ ...viewData, [func.name]: result });
      }

        else if (
                func.name === 'DisputeScore' ||
                func.name === 'Payout' ||
                func.name === 'InitiatePayout' ||
                func.name === 'InitiatePeriodPayout' ||
                func.name === 'DestroyContract' ||
                func.name === 'StageOneCompleteContractor' ||
                func.name === 'StageOneCompleteEmployer' ||
                func.name === 'StageTwoCompleteContractor' ||
                func.name === 'StageTwoCompleteEmployer'
              ){

          setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

          const contractInstance = new web3.eth.Contract(abi, addr);

          const sendData = contractInstance.methods[func.name]().encodeABI();

          const sendTransactionObject = {
            from: walletAddress, // Set the sender's address
            to: addr, // Contract address
            data: sendData,
          };

          let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
          let gasPrice = await web3.eth.getGasPrice();
          let gasFee = gasEstimate * 2

          // Now, you can construct and send your transaction with the calculated gas values
          const newSendTransactionObj = {
            ...sendTransactionObject,
            gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
            gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
          };

          // Send transaction
          //Send to user via eth_sendTransaction request with parameters
          const tx = await provider.request({
            method: 'eth_sendTransaction',
            params: [newSendTransactionObj]
          });

          // Call the function
          let result = `Tx: ${tx}`;

          // Update view data
          setViewData({ ...viewData, [func.name]: result });
        }

        else if (func.name === 'Intervention' && (
                                                  type === '1PPAvaxABI' ||
                                                  type === '1PPEvowABI' ||
                                                  type === '1PPSwthABI' ||
                                                  type === '1PPUscABI' ||
                                                  type === '2PPAvaxABI' ||
                                                  type === '2PPWbtcABI' ||
                                                  type === '2PPEvowABI' ||
                                                  type === '2PPSwthABI' ||
                                                  type === '2PPUscABI' ||
                                                  type === '2PPWbtcABI' ||
                                                  type === '3PPAvaxABI' ||
                                                  type === '3PPEvowABI' ||
                                                  type === '3PPSwthABI' ||
                                                  type === '3PPUscABI' ||
                                                  type === '3PPWbtcABI' ||
                                                  type === '4PPAvaxABI' ||
                                                  type === '4PPEvowABI' ||
                                                  type === '4PPSwthABI' ||
                                                  type === '4PPUscABI' ||
                                                  type === '4PPWbtcABI' ||
                                                  type === '5PPAvaxABI' ||
                                                  type === '5PPEvowABI' ||
                                                  type === '5PPSwthABI' ||
                                                  type === '5PPUscABI' ||
                                                  type === '5PPWbtcABI' ||
                                                  type === 'pp1EvowABI_Matic' ||
                                                  type === 'pp1MaticABI' ||
                                                  type === 'pp1UsdcABI' ||
                                                  type === 'pp1WbtcABI_Matic' ||
                                                  type === 'pp2EvowABI_Matic' ||
                                                  type === 'pp2MaticABI' ||
                                                  type === 'pp2UsdcABI' ||
                                                  type === 'pp2WbtcABI_Matic' ||
                                                  type === 'pp3EvowABI_Matic' ||
                                                  type === 'pp3MaticABI' ||
                                                  type === 'pp3UsdcABI' ||
                                                  type === 'pp3WbtcABI_Matic' ||
                                                  type === 'pp4EvowABI_Matic' ||
                                                  type === 'pp4MaticABI' ||
                                                  type === 'pp4UsdcABI' ||
                                                  type === 'pp4WbtcABI_Matic' ||
                                                  type === 'pp5EvowABI_Matic' ||
                                                  type === 'pp5MaticABI' ||
                                                  type === 'pp5UsdcABI' ||
                                                  type === 'pp5WbtcABI_Matic')){

          setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

          const contractInstance = new web3.eth.Contract(abi, addr);

          // Retrieve the _amount input from inputValues
          const _gp1 = inputValues[func.name]['_group1_percent'];
          const _gp2 = inputValues[func.name]['_group2_percent'];

          const sendData = contractInstance.methods[func.name](_gp1.toString(), _gp2.toString()).encodeABI();

          const sendTransactionObject = {
            from: walletAddress, // Set the sender's address
            to: addr, // Contract address
            data: sendData,
          };

          let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
          let gasPrice = await web3.eth.getGasPrice();
          let gasFee = gasEstimate * 2

          // Now, you can construct and send your transaction with the calculated gas values
          const newSendTransactionObj = {
            ...sendTransactionObject,
            gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
            gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
          };

          // Send transaction
          //Send to user via eth_sendTransaction request with parameters
          const tx = await provider.request({
            method: 'eth_sendTransaction',
            params: [newSendTransactionObj]
          });

          // Call the function
          let result = `Tx: ${tx}`;

          // Update view data
          setViewData({ ...viewData, [func.name]: result });
        }

        else if (func.name === 'Intervention' && (
                                                  type === 'LSM3AvaxABI' ||
                                                  type === 'LSM3EvowABI' ||
                                                  type === 'LSM3SwthABI' ||
                                                  type === 'LSM3UscABI' ||
                                                  type === 'LSM3WbtcABI' ||
                                                  type === 'LSM4AvaxABI' ||
                                                  type === 'LSM4EvowABI' ||
                                                  type === 'LSM4SwthABI' ||
                                                  type === 'LSM4UscABI' ||
                                                  type === 'LSM4WbtcABI' ||
                                                  type === 'LSM5AvaxABI' ||
                                                  type === 'LSM5EvowABI' ||
                                                  type === 'LSM5SwthABI' ||
                                                  type === 'LSM5UscABI' ||
                                                  type === 'LSM5WbtcABI' ||
                                                  type === 'LSM6AvaxABI' ||
                                                  type === 'LSM6EvowABI' ||
                                                  type === 'LSM6SwthABI' ||
                                                  type === 'LSM6UscABI' ||
                                                  type === 'LSM6WbtcABI' ||
                                                  type === 'LSM7AvaxABI' ||
                                                  type === 'LSM7EvowABI' ||
                                                  type === 'LSM7SwthABI' ||
                                                  type === 'LSM7UscABI' ||
                                                  type === 'LSM7WbtcABI' ||
                                                  type === 'LSM3EvowABI_Matic' ||
                                                  type === 'LSM3MaticABI' ||
                                                  type === 'LSM3UsdcABI' ||
                                                  type === 'LSM3WbtcABI_Matic' ||
                                                  type === 'LSM4EvowABI_Matic' ||
                                                  type === 'LSM4MaticABI' ||
                                                  type === 'LSM4UsdcABI' ||
                                                  type === 'LSM4WbtcABI_Matic' ||
                                                  type === 'LSM5EvowABI_Matic' ||
                                                  type === 'LSM5MaticABI' ||
                                                  type === 'LSM5UsdcABI' ||
                                                  type === 'LSM5WbtcABI_Matic' ||
                                                  type === 'LSM6EvowABI_Matic' ||
                                                  type === 'LSM6MaticABI' ||
                                                  type === 'LSM6UsdcABI' ||
                                                  type === 'LSM6WbtcABI_Matic' ||
                                                  type === 'LSM7EvowABI_Matic' ||
                                                  type === 'LSM7MaticABI' ||
                                                  type === 'LSM7UsdcABI' ||
                                                  type === 'LSM7WbtcABI_Matic'
                                                )){

          setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

          const contractInstance = new web3.eth.Contract(abi, addr);

          // Retrieve the _amount input from inputValues
          const _percentages = inputValues[func.name]['_groupPercentages'];

          const values = _percentages.split(',').map(Number);

          const sendData = contractInstance.methods[func.name](values).encodeABI();

          const sendTransactionObject = {
            from: walletAddress, // Set the sender's address
            to: addr, // Contract address
            data: sendData,
          };

          let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
          let gasPrice = await web3.eth.getGasPrice();
          let gasFee = gasEstimate * 2

          // Now, you can construct and send your transaction with the calculated gas values
          const newSendTransactionObj = {
            ...sendTransactionObject,
            gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
            gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
          };

          // Send transaction
          //Send to user via eth_sendTransaction request with parameters
          const tx = await provider.request({
            method: 'eth_sendTransaction',
            params: [newSendTransactionObj]
          });

          // Call the function
          let result = `Tx: ${tx}`;

          // Update view data
          setViewData({ ...viewData, [func.name]: result });
        }

        else if (func.name === 'Intervention' && (
                                          type === 'creditFriendsWbtcSixABI' ||
                                          type === 'creditFriendsUscSixABI' ||
                                          type === 'creditFriendsEvowSixABI' ||
                                          type === 'creditFriendsSwthSixABI' ||
                                          type === 'creditFriendsAvaxSixABI' ||
                                          type === 'creditFriendsWbtcTwelveABI' ||
                                          type === 'creditFriendsUscTwelveABI' ||
                                          type === 'creditFriendsEvowTwelveABI' ||
                                          type === 'creditFriendsSwthTwelveABI' ||
                                          type === 'creditFriendsAvaxTwelveABI' ||
                                          type === 'creditFriendsMATICUsdcSixABI' ||
                                          type === 'creditFriendsMATICEvowSixABI' ||
                                          type === 'creditFriendsMATICSixABI' ||
                                          type === 'creditFriendsMATICWbtcSixABI' ||
                                          type === 'creditFriendsMATICUsdcTwelveABI' ||
                                          type === 'creditFriendsMATICEvowTwelveABI' ||
                                          type === 'creditFriendsMATICTwelveABI' ||
                                          type === 'creditFriendsMATICWbtcTwelveABI'
                                          )){

          setViewData({ ...viewData, [func.name]: "Building Transaction 1/1" });

          const contractInstance = new web3.eth.Contract(abi, addr);

          // Retrieve the _amount input from inputValues
          const recepient = inputValues[func.name]['_address'];

          const sendData = contractInstance.methods[func.name](recepient).encodeABI();

          const sendTransactionObject = {
            from: walletAddress, // Set the sender's address
            to: addr, // Contract address
            data: sendData,
          };

          let gasEstimate = await web3.eth.estimateGas(sendTransactionObject);
          let gasPrice = await web3.eth.getGasPrice();
          let gasFee = gasEstimate * 2

          // Now, you can construct and send your transaction with the calculated gas values
          const newSendTransactionObj = {
            ...sendTransactionObject,
            gas: web3.utils.toHex(web3.utils.toBN(gasFee)), // Use the estimated gas cost
            gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)), // Use the current gas price
          };

          // Send transaction
          //Send to user via eth_sendTransaction request with parameters
          const tx = await provider.request({
            method: 'eth_sendTransaction',
            params: [newSendTransactionObj]
          });

          // Call the function
          let result = `Tx: ${tx}`;

          // Update view data
          setViewData({ ...viewData, [func.name]: result });
        }
      }
    }
    catch(error){
      setViewData({ ...viewData, [func.name]: error.message });
    }

  }

    else if (func.stateMutability === 'payable'){
      setViewData({ ...viewData, [func.name]: "Building Transaction" });

      try{
        if (
          type === "savingsMultiABI" ||
          type === 'savingsEvowABI' ||
          type === "savingsMultiABIMatic" ||
          type === 'savingsEvowABIMatic' ||
          type === 'empUsc1ABI' ||
          type === 'empUsc2ABI' ||
          type === 'empUsc3ABI' ||
          type === 'empSwth1ABI' ||
          type === 'empSwth2ABI' ||
          type === 'empSwth3ABI' ||
          type === 'empAvax1ABI' ||
          type === 'empAvax2ABI' ||
          type === 'empAvax3ABI' ||
          type === 'empWbtc1ABI' ||
          type === 'empWbtc2ABI' ||
          type === 'empWbtc3ABI' ||
          type === 'empEvow1ABI' ||
          type === 'empEvow2ABI' ||
          type === 'empEvow3BI' ||
          type === 'pp1AvaxABI' ||
          type === 'pp1EvowABI' ||
          type === 'pp1UscABI' ||
          type === 'pp1WbtcABI' ||
          type === 'pp1SwthABI' ||
          type === 'pp2AvaxABI' ||
          type === 'pp2EvowABI' ||
          type === 'pp2UscABI' ||
          type === 'pp2WbtcABI' ||
          type === 'pp2SwthABI' ||
          type === 'pp3AvaxABI' ||
          type === 'pp3EvowABI' ||
          type === 'pp3UscABI' ||
          type === 'pp3WbtcABI' ||
          type === 'pp3SwthABI' ||
          type === 'pp4AvaxABI' ||
          type === 'pp4EvowABI' ||
          type === 'pp4UscABI' ||
          type === 'pp4WbtcABI' ||
          type === 'pp4SwthABI' ||
          type === 'pp5AvaxABI' ||
          type === 'pp5EvowABI' ||
          type === 'pp5UscABI' ||
          type === 'pp5WbtcABI' ||
          type === 'pp5SwthABI' ||
          type === 'LSM3AvaxABI' ||
          type === 'LSM3UscABI' ||
          type === 'LSM3EvowABI' ||
          type === 'LSM3SwthABI' ||
          type === 'LSM3WbtcABI' ||
          type === 'LSM4AvaxABI' ||
          type === 'LSM4UscABI' ||
          type === 'LSM4EvowABI' ||
          type === 'LSM4SwthABI' ||
          type === 'LSM4WbtcABI' ||
          type === 'LSM5AvaxABI' ||
          type === 'LSM5UscABI' ||
          type === 'LSM5EvowABI' ||
          type === 'LSM5SwthABI' ||
          type === 'LSM5WbtcABI' ||
          type === 'LSM6AvaxABI' ||
          type === 'LSM6UscABI' ||
          type === 'LSM6EvowABI' ||
          type === 'LSM6SwthABI' ||
          type === 'LSM6WbtcABI' ||
          type === 'LSM7AvaxABI' ||
          type === 'LSM7UscABI' ||
          type === 'LSM7EvowABI' ||
          type === 'LSM7SwthABI' ||
          type === 'LSM7WbtcABI' ||
          type === 'pp1EvowABI_Matic' ||
          type === 'pp1MaticABI' ||
          type === 'pp1UsdcABI' ||
          type === 'pp1WbtcABI_Matic' ||
          type === 'pp2EvowABI_Matic' ||
          type === 'pp2MaticABI' ||
          type === 'pp2UsdcABI' ||
          type === 'pp2WbtcABI_Matic' ||
          type === 'pp3EvowABI_Matic' ||
          type === 'pp3MaticABI' ||
          type === 'pp3UsdcABI' ||
          type === 'pp3WbtcABI_Matic' ||
          type === 'pp4EvowABI_Matic' ||
          type === 'pp4MaticABI' ||
          type === 'pp4UsdcABI' ||
          type === 'pp4WbtcABI_Matic' ||
          type === 'pp5EvowABI_Matic' ||
          type === 'pp5MaticABI' ||
          type === 'pp5UsdcABI' ||
          type === 'pp5WbtcABI_Matic' ||
          type === 'LSM3EvowABI_Matic' ||
          type === 'LSM3MaticABI' ||
          type === 'LSM3UsdcABI' ||
          type === 'LSM3WbtcABI_Matic' ||
          type === 'LSM4EvowABI_Matic' ||
          type === 'LSM4MaticABI' ||
          type === 'LSM4UsdcABI' ||
          type === 'LSM4WbtcABI_Matic' ||
          type === 'LSM5EvowABI_Matic' ||
          type === 'LSM5MaticABI' ||
          type === 'LSM5UsdcABI' ||
          type === 'LSM5WbtcABI_Matic' ||
          type === 'LSM6EvowABI_Matic' ||
          type === 'LSM6MaticABI' ||
          type === 'LSM6UsdcABI' ||
          type === 'LSM6WbtcABI_Matic' ||
          type === 'LSM7EvowABI_Matic' ||
          type === 'LSM7MaticABI' ||
          type === 'LSM7UsdcABI' ||
          type === 'LSM7WbtcABI_Matic' ||
          type === 'empEvow1ABIMatic' ||
          type === 'empUSDC1ABI' ||
          type === 'empMATIC1ABI' ||
          type === 'empWbtc1ABIMatic' ||
          type === 'empEvow2ABIMatic' ||
          type === 'empUSDC2ABI' ||
          type === 'empMATIC2ABI' ||
          type === 'empWbtc2ABIMatic' ||
          type === 'empEvow3ABIMatic' ||
          type === 'empUSDC3ABI' ||
          type === 'empMATIC3ABI' ||
          type === 'empWbtc3ABIMatic' ||
          type === 'creditFriendsWbtcSixABI' ||
          type === 'creditFriendsUscSixABI' ||
          type === 'creditFriendsEvowSixABI' ||
          type === 'creditFriendsSwthSixABI' ||
          type === 'creditFriendsAvaxSixABI' ||
          type === 'creditFriendsWbtcTwelveABI' ||
          type === 'creditFriendsUscTwelveABI' ||
          type === 'creditFriendsEvowTwelveABI' ||
          type === 'creditFriendsSwthTwelveABI' ||
          type === 'creditFriendsAvaxTwelveABI' ||
          type === 'creditFriendsMATICUsdcSixABI' ||
          type === 'creditFriendsMATICEvowSixABI' ||
          type === 'creditFriendsMATICSixABI' ||
          type === 'creditFriendsMATICWbtcSixABI' ||
          type === 'creditFriendsMATICUsdcTwelveABI' ||
          type === 'creditFriendsMATICEvowTwelveABI' ||
          type === 'creditFriendsMATICTwelveABI' ||
          type === 'creditFriendsMATICWbtcTwelveABI'
        ){

          if (func.name === 'DepositSWTH' ||
              func.name === 'DepositMATIC' ||
              func.name === 'DepositAVAX' ){

            const contractInstance = new web3.eth.Contract(abi, addr);

            // Retrieve the _amount input from inputValues
            const _amount = inputValues[func.name]['_amount'];

            // Create a transaction object
            const transactionObject = {
              from: walletAddress,
              to: addr,
              data: contractInstance.methods[func.name](_amount).encodeABI(),
              value: web3.utils.toBN(_amount),
            };

            const gasEstimate = await web3.eth.estimateGas(transactionObject);
            const gasPrice = await web3.eth.getGasPrice();

            const newTransactionObj = {
              ...transactionObject,
              gas: web3.utils.toHex(web3.utils.toBN(gasEstimate)), // Use the estimated gas cost
              gasPrice: web3.utils.toHex(web3.utils.toBN(gasPrice)),
            };

            //Send to user via eth_sendTransaction request with parameters
            const tx = await provider.request({
              method: 'eth_sendTransaction',
              params: [newTransactionObj]
            });

            //Set Result to transaction hash
            let result = `Tx: ${tx}`;

            // Update view data
            setViewData({ ...viewData, [func.name]: result });
          }
        }
      }
      catch(error){
        setViewData({ ...viewData, [func.name]: error.message });
      }
    }
  };

  return (
    <div>
      <Row>

      <Col>
        <div xs={12} className="function-list">
          {abi
            .filter(
              (func) =>
                (func.stateMutability === 'view' ||
                  func.stateMutability === 'external' ||
                  func.stateMutability === 'payable' ||
                  func.stateMutability === 'nonpayable') &&
                func.name !== '__init__' &&
                func.type !== 'constructor'
            )
            .map((func, index) => (
              <div key={index} className="function-container">
                <div className="d-flex align-items-center mb-2">
                  <Button
                    variant={
                      func.stateMutability === 'view'
                        ? 'info'
                        : func.stateMutability === 'payable'
                        ? 'success'
                        : 'primary'
                    }
                    style={{ marginRight: '10px' }}
                    onClick={() => handleFunctionCall(func)}
                  >
                    {func.name}
                  </Button>
                  <div className="input-container">
                    {func.inputs.map((input, i) => (
                      <span key={i} className="parameter-container">
                        <input
                          classname="function-input"
                          type="text"
                          placeholder={input.name}
                          value={inputValues[func.name] && inputValues[func.name][input.name] || ''}
                          onChange={(e) => handleInputChange(func.name, input.name, e.target.value)}
                        />
                      </span>
                    ))}
                    {viewData[func.name] && (
                      <div className="view-data">
                        {func.name} Result: {viewData[func.name]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Col>

      <Col style={outlinedColumnStyle}>
        <h3 className="display-4" style={{textAlign: 'center'}}>How To</h3>
        <p className="lead">Enter data as big numbers (without decimals). USDC uses six decimals. WBTC uses eight decimals. All remaining tokens use 18 decimal placement.</p>
        <br />
        <p className="lead">Use the "IndividualDeposit" button to assist with contract deposit input. Please feel free to copy the number and input into the "Deposit" input field. Your wallet notification will show the visually adjusted amount!</p>
        <br/>
        USDC
        <br/>
        1.00 = 1000000
        <br/>
        0.01 = 10000
        <br/>
        <br/>
        WBTC
        <br/>
        1.00 = 100000000
        <br/>
        0.01 = 1000000
        <br/>
        <br/>
        AVAX, EVOW, MATIC
        <br/>
        1.00 = 1000000000000000000
        <br/>
        0.01 = 10000000000000000
      </Col>
      </Row>
    </div>
  );
};

const ContractsComponent = ({ contracts, contractInstance }) => {
  const [activeContract, setActiveContract] = useState(null);
  const [deletedText, setDeletedText] = useState(false);

  const handleToggle = (contract) => {
    if (activeContract === contract) {
      setActiveContract(null);
    } else {
      setDeletedText('');
      setActiveContract(contract);
    }
  };

  const onDeleteClick = async (contractNo) =>{

    setDeletedText("Contacting server...")

    const localToken = localStorage.getItem('token');
    const response = await fetch(deleteContract, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "contractNo": contractNo }),
    });

    if (response.ok) {
      setDeletedText("Success. When you log back in, you will no longer see the contract. See Q&A for more information on contract deletion.")
      // Request was successful
    } else {
      setDeletedText("An error occurred during the deletion. The contract was not deleted successfully. Please contact us via Telegram for more assistance.")
      // Request failed
    }
  }

  return (
    <div>
      <Container>
        <ul className="toggle-list">
          {contracts.map((contract, index) => (
            <li key={index} className={`contract-item ${contract.userType}`}>
              <p
                className={`toggle-header ${
                  activeContract === contract ? 'active' : ''
                }`}
                onClick={() => handleToggle(contract)}
              >
                {contract.no} - {contractMapping[contract.type]} - {contract.userType} - {contract.chain}
              </p>
              {activeContract === contract && (
                <div className={`contract-content ${activeContract === contract ? 'active' : ''}`}>
                  <ContractFunctions
                    addr = {contract.no}
                    abi={abiMapping[contract.type]}
                    type={contract.type}
                    chain={contract.chain}
                  />
                  <Button style = {{ display: "flex", maxWidth: "100%" }} onClick= {() => onDeleteClick(contract.no)} variant="primary">Delete Contract</Button>
                  <p>{deletedText}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default ContractsComponent;
