
const contractMapping = {
  oneVoneABI: 'P2P (1)',
  fiveVfiveABI: 'P2P (5)',
  savingsMultiABI: 'Savings - Multiple',
  savingsEvowABI: 'Savings - EVOW',
  creditFriendsWbtcSixABI: 'ROSCA 6 - WBTC',
  creditFriendsUscSixABI: 'ROSCA 6 - USD',
  creditFriendsEvowSixABI: 'ROSCA 6 - EVOW',
  creditFriendsSwthSixABI: 'ROSCA 6 - SWTH',
  creditFriendsAvaxSixABI: 'ROSCA 6 - AVAX',
  creditFriendsWbtcTwelveABI: 'ROSCA 12 - WBTC',
  creditFriendsUscTwelveABI: 'ROSCA 12 - USD',
  creditFriendsEvowTwelveABI: 'ROSCA 12 - EVOW',
  creditFriendsSwthTwelveABI: 'ROSCA 12 - SWTH',
  creditFriendsAvaxTwelveABI: 'ROSCA 12 - AVAX',
  empAvax1ABI: 'Employment - Tier 1 - AVAX',
  empUsc1ABI: 'Employment - Tier 1 - USC',
  empSwth1ABI: 'Employment - Tier 1 - SWTH',
  empWbtc1ABI: 'Employment - Tier 1 - WBTC',
  empEvow1ABI: 'Employment - Tier 1 - EVOW',
  empAvax2ABI: 'Employment - Tier 2 - AVAX',
  empUsc2ABI: 'Employment - Tier 2 - USC',
  empSwth2ABI: 'Employment - Tier 2 - SWTH',
  empWbtc2ABI: 'Employment - Tier 2 - WBTC',
  empEvow2ABI: 'Employment - Tier 2 - EVOW',
  empAvax3ABI: 'Employment - Tier 3 - AVAX',
  empUsc3ABI: 'Employment - Tier 3 - USC',
  empSwth3ABI: 'Employment - Tier 3 - SWTH',
  empWbtc3ABI: 'Employment - Tier 3 - WBTC',
  empEvow3ABI: 'Employment - Tier 3 - EVOW',
  empEvow1ABIMatic: 'Employment - Tier 1 - EVOW',
  empUSDC1ABI: 'Employment - Tier 1 - USDC',
  empMATIC1ABI: 'Employment - Tier 1 - MATIC',
  empWbtc1ABIMatic: 'Employment - Tier 1 - WBTC',
  empEvow2ABIMatic: 'Employment - Tier 2 - EVOW',
  empUSDC2ABI:'Employment - Tier 2 - USDC',
  empMATIC2ABI: 'Employment - Tier 2 - MATIC',
  empWbtc2ABIMatic: 'Employment - Tier 2 - EVOW',
  empEvow3ABIMatic: 'Employment - Tier 3 - EVOW',
  empUSDC3ABI: 'Employment - Tier 3 - EVOW',
  empMATIC3ABI:'Employment - Tier 3 - EVOW',
  empWbtc3ABIMatic:'Employment - Tier 3 - EVOW',
  savingsMultiABIMatic:'Employment - Tier 3 - EVOW',
  savingsEvowABIMatic: 'Employment - Tier 3 - EVOW',
  empAvax1ABI:'Employment - Tier 3 - EVOW',
  empAvax2ABI:'Employment - Tier 3 - EVOW',
  empAvax3ABI: 'Employment - Tier 3 - EVOW',
  pp1AvaxABI: 'Peer - 1 to 1 - AVAX',
  pp1EvowABI: 'Peer - 1 to 1 - EVOW',
  pp1UscABI: 'Peer - 1 to 1 - USC',
  pp1SwthABI: 'Peer - 1 to 1 - SWTH',
  pp1WbtcABI: 'Peer - 1 to 1 - WBTC',
  pp2AvaxABI: 'Peer - 2 to 2 - AVAX',
  pp2EvowABI: 'Peer - 2 to 2 - EVOW',
  pp2SwthABI: 'Peer - 2 to 2 - SWTH',
  pp2UscABI: 'Peer - 2 to 2 - USC',
  pp2WbtcABI: 'Peer - 2 to 2 - WBTC',
  pp3AvaxABI: 'Peer - 3 to 3 - AVAX',
  pp3EvowABI: 'Peer - 3 to 3 - EVOW',
  pp3UscABI: 'Peer - 3 to 3 - USC',
  pp3SwthABI: 'Peer - 3 to 3 - SWTH',
  pp3WbtcABI: 'Peer - 3 to 3 - WBTC',
  pp4AvaxABI: 'Peer - 4 to 4 - AVAX',
  pp4EvowABI: 'Peer - 4 to 4 - EVOW',
  pp4SwthABI: 'Peer - 4 to 4 - SWTH',
  pp4UscABI: 'Peer - 4 to 4 - USC',
  pp4WbtcABI: 'Peer - 4 to 4 - WBTC',
  pp5AvaxABI: 'Peer - 5 to 5 - AVAX',
  pp5EvowABI: 'Peer - 5 to 5 - EVOW',
  pp5SwthABI: 'Peer - 5 to 5 - SWTH',
  pp5UscABI: 'Peer - 5 to 5 - USC',
  pp5WbtcABI: 'Peer - 5 to 5 - WBTC',
  LSM3AvaxABI: 'Peer - 3 Last Man - AVAX',
  LSM3EvowABI: 'Peer - 3 Last Man - EVOW',
  LSM3UscABI: 'Peer - 3 Last Man -  USC',
  LSM3WbtcABI: 'Peer - 3 Last Man - WBTC',
  LSM3SwthABI: 'Peer - 3 Last Man - SWTH',
  LSM4AvaxABI: 'Peer - 4 Last Man - AVAX',
  LSM4EvowABI: 'Peer - 4 Last Man - EVOW',
  LSM4UscABI: 'Peer - 4 Last Man -  USC',
  LSM4WbtcABI: 'Peer - 4 Last Man - WBTC',
  LSM4SwthABI: 'Peer - 4 Last Man - SWTH',
  LSM5AvaxABI: 'Peer - 5 Last Man - AVAX',
  LSM5EvowABI: 'Peer - 5 Last Man - EVOW',
  LSM5UscABI: 'Peer - 5 Last Man -  USC',
  LSM5WbtcABI: 'Peer - 5 Last Man - WBTC',
  LSM5SwthABI: 'Peer - 5 Last Man - SWTH',
  LSM6AvaxABI: 'Peer - 6 Last Man - AVAX',
  LSM6EvowABI: 'Peer - 6 Last Man - EVOW',
  LSM6UscABI: 'Peer - 6 Last Man -  USC',
  LSM6WbtcABI: 'Peer - 6 Last Man - WBTC',
  LSM6SwthABI: 'Peer - 6 Last Man - SWTH',
  LSM7AvaxABI: 'Peer - 7 Last Man - AVAX',
  LSM7EvowABI: 'Peer - 7 Last Man - EVOW',
  LSM7UscABI: 'Peer - 7 Last Man -  USC',
  LSM7WbtcABI: 'Peer - 7 Last Man - WBTC',
  LSM7SwthABI: 'Peer - 7 Last Man - SWTH',
  pp1EvowABI_Matic: 'Peer - 1 to 1 - EVOW',
  pp1MaticABI: 'Peer - 1 to 1 - MATIC',
  pp1UsdcABI: 'Peer - 1 to 1 - USDC',
  pp1WbtcABI_Matic: 'Peer - 1 to 1 - WBTC',
  pp2EvowABI_Matic: 'Peer - 2 to 2 - EVOW',
  pp2MaticABI: 'Peer - 2 to 2 - MATIC',
  pp2UsdcABI: 'Peer - 2 to 2 - USDC',
  pp2WbtcABI_Matic: 'Peer - 2 to 2 - WBTC',
  pp3EvowABI_Matic: 'Peer - 3 to 3 - EVOW',
  pp3MaticABI: 'Peer - 3 to 3 - MATIC',
  pp3UsdcABI: 'Peer - 3 to 3 - USDC',
  pp3WbtcABI_Matic: 'Peer - 3 to 3 - WBTC',
  pp4EvowABI_Matic: 'Peer - 4 to 4 - EVOW',
  pp4MaticABI: 'Peer - 4 to 4 - MATIC',
  pp4UsdcABI: 'Peer - 4 to 4 - USDC',
  pp4WbtcABI_Matic: 'Peer - 4 to 4 - WBTC',
  pp5EvowABI_Matic: 'Peer - 5 to 5 - EVOW',
  pp5MaticABI: 'Peer - 5 to 5 - MATIC',
  pp5UsdcABI: 'Peer - 5 to 5 - USDC',
  pp5WbtcABI_Matic: 'Peer - 5 to 5 - WBTC',
  LSM3EvowABI_Matic: 'Peer - 3 Last Man - EVOW',
  LSM3MaticABI: 'Peer - 3 Last Man - MATIC',
  LSM3UsdcABI: 'Peer - 3 Last Man - USDC',
  LSM3WbtcABI_Matic: 'Peer - 3 Last Man - WBTC',
  LSM4EvowABI_Matic: 'Peer - 4 Last Man - EVOW',
  LSM4MaticABI: 'Peer - 4 Last Man - MATIC',
  LSM4UsdcABI: 'Peer - 4 Last Man - USDC',
  LSM4WbtcABI_Matic: 'Peer - 4 Last Man - WBTC',
  LSM5EvowABI_Matic: 'Peer - 5 Last Man - EVOW',
  LSM5MaticABI: 'Peer - 5 Last Man - MATIC',
  LSM5UsdcABI: 'Peer - 5 Last Man - USDC',
  LSM5WbtcABI_Matic: 'Peer - 5 Last Man - WBTC',
  LSM6EvowABI_Matic: 'Peer - 6 Last Man - EVOW',
  LSM6MaticABI: 'Peer - 6 Last Man - MATIC',
  LSM6UsdcABI: 'Peer - 6 Last Man - USDC',
  LSM6WbtcABI_Matic: 'Peer - 6 Last Man - WBTC',
  LSM7EvowABI_Matic: 'Peer - 7 Last Man - EVOW',
  LSM7MaticABI: 'Peer - 7 Last Man - MATIC',
  LSM7UsdcABI: 'Peer - 7 Last Man - USDC',
  LSM7WbtcABI_Matic: 'Peer - 7 Last Man - WBTC',
  creditFriendsMATICUsdcSixABI: 'ROSCA - USDC',
  creditFriendsMATICEvowSixABI: 'ROSCA - EVOW',
  creditFriendsMATICSixABI: 'ROSCA - MATIC',
  creditFriendsMATICWbtcSixABI: 'ROSCA - WBTC',
  creditFriendsMATICUsdcTwelveABI: 'ROSCA - USDC',
  creditFriendsMATICEvowTwelveABI: 'ROSCA - EVOW',
  creditFriendsMATICTwelveABI: 'ROSCA - MATIC',
  creditFriendsMATICWbtcTwelveABI: 'ROSCA - WBTC',
  // Add other ABIs to the mapping
};

export { contractMapping };
