import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FiTwitter} from "react-icons/fi";
import {SiGithub} from "react-icons/si";
import {TbBrandTelegram} from "react-icons/tb";
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#FC7803", color: "#000000", paddingTop: "40px", paddingBottom: "40px", display: "flex", flexDirection: "column" }}>
      <Container>
        <Row>
          <Col>
            <h5>About Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/about" >Vision</a>
              </li>
              <li>
                <a href="/tokenomics">Tokenomics</a>
              </li>
              <li>
                <a href="/terms">Terms of Service</a>
              </li>
              <li>
                <a href="/whitepaper">Whitepaper</a>
              </li>
            </ul>
          </Col>
          <Col>
            <h5>Register/Login</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/login">Login</a>
              </li>
            </ul>
          </Col>
          <Col>
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/contact">Customer Service</a>
              </li>
              <li>
                <a href="/how">Q & A</a>
              </li>
            </ul>
          </Col>
          <Col>
            <h5>Social Media</h5>
            <ul className="list-unstyled">
              <li>
                <a href="https://twitter.com/eVowIO">
                  <i className="fab fa-twitter"></i> <FiTwitter/>
                </a>
              </li>
              <li>
                <a href="https://github.com/eVowIO">
                  <i className="fab fa-github"></i> <SiGithub/>
                </a>
              </li>
              <li>
                <a href="https://t.me/OfficialEvowDiscussion">
                  <i className="fab fa-telegram"></i> <TbBrandTelegram/>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div className="text-center">
          <h6 style={{ color: "black" }}>
            eVow: Smart Contract Deployment Made Easy
          </h6>
          <h6 style={{ color: "black" }}>
            &copy; 2024 evow.io - All rights reserved.
          </h6>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
